import {
  Button,
  Dialog,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@mui/material";
import React from "react";
import DownloadIcon from "@mui/icons-material/Download";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import RegistrationInvoice from "./pdfgenerator/registration-invoice";
import Invoice from "./pdfgenerator/gen-invoice";
import { InvoiceData } from "./pdfgenerator/invoice-data";

/* const invoiceData = {
    id: "5df3180a09ea16dc4b95f910",
    invoice_no: "201906-28",
    balance: "$2,283.74",
    company: "MANTRIX",
    email: "susanafuentes@mantrix.com",
    phone: "+1 (872) 588-3809",
    address: "922 Campus Road, Drytown, Wisconsin, 1986",
    trans_date: "2019-09-12",
    due_date: "2019-10-12",
    items: [
        {
            item_num: 1,
            desc: "ad sunt culpa occaecat qui",
            qty: 5,
            rate: 405.89,
        },
        {
            item_num: 2,
            desc: "cillum quis sunt qui aute",
            qty: 5,
            rate: 373.11,
        },
        {
            item_num: 3,
            desc: "ea commodo labore culpa irure",
            qty: 5,
            rate: 458.61,
        },
        {
            item_num: 4,
            desc: "nisi consequat et adipisicing dolor",
            qty: 10,
            rate: 725.24,
        },
        {
            item_num: 5,
            desc: "proident cillum anim elit esse",
            qty: 4,
            rate: 141.02,
        },
    ],
}; */

class InvoiceModal extends React.Component<
  {
    open: boolean;
    onClose: () => void;
    invoiceData: InvoiceData;
  },
  { invoiceData: InvoiceData }
> {
  constructor(props: any) {
    super(props);
    this.state = { invoiceData: this.props.invoiceData };
  }


  render() {

    return (
      <Dialog open={this.props.open} onClose={this.props.onClose}>
        <>
          <PDFViewer style={{ height: 600, width: 425 }}>
            <Invoice invoice={this.props.invoiceData} />
          </PDFViewer>
          <PDFDownloadLink
            document={<Invoice invoice={this.props.invoiceData} />}
            fileName="registration-invoice.pdf"
            style={{ maxWidth: 172, display: "flex", alignSelf: "center" }}
          >
            {({ blob, url, loading, error }) =>
              loading ? (
                "Loading document..."
              ) : (
                <Button
                  startIcon={<DownloadIcon />}
                  variant="contained"
                  color="success"
                  size="small"
                >
                  Download Invoice
                </Button>
              )
            }
          </PDFDownloadLink>
        </>
      </Dialog>
    );
  }
}

export default InvoiceModal;
