import React, {Fragment} from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';
import { InvoiceItem } from '../invoice-data';

const borderColor = '#90e5fc'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: '#bff0fd',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        fontStyle: 'bold',
    },
    payment: {
        width: '20%',
        textAlign: 'left',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
    },
    amount: {
        width: '35%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'right',
        paddingRight: 8,
    },
    type: {
        width: '25%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'right',
        paddingRight: 8,
    },
  });


const ReceiptTableRow = ({payments}:{payments: any}) => {
    
    const rows = payments?.map( (payment: any, index: any) => {
        
        return(
            <View style={styles.row} key={"payment.item_num.toString()"}>
                <Text style={styles.payment}>{(Number(index) + 1)}</Text>
                <Text style={styles.payment}>{(new Date(payment?.createdAt).toISOString().slice(0, 10))}</Text>
                <Text style={styles.type}>{(payment?.type || "Cash")}</Text>
                <Text style={styles.amount}>K{payment?.amount}</Text>
            </View>
        )}
    )
    return (<Fragment>{rows}</Fragment> )
};
  
export default ReceiptTableRow