import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { InvoiceItem } from "../invoice-data";

const borderColor = "#90e5fc";
const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    borderBottomColor: "#bff0fd",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    fontStyle: "bold",
  },
  description: {
    width: "65%",
    textAlign: "left",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
  },
  qty: {
    width: "15%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "right",
    paddingRight: 8,
  },
  discount: {
    width: "65%",
    textAlign: 'left',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
  },
  amount: {
    width: "35%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "right",
    paddingRight: 8,
  },
});

const InvoiceTableRow = ({ items }: { items: InvoiceItem[] }) => {
  const rows = items?.map((item) => (
    <>
      <View style={styles.row} key={item.item_num.toString()}>
        <Text style={styles.description}>{item.desc}</Text>
        <Text style={styles.amount}>
          K{item.rate}
        </Text>
      </View>
      {(item.discount && parseInt(item.discount) != 0 )? (
        <>
          <View style={styles.row} key={item.item_num.toString()}>
            <Text style={styles.discount}>{(item.discount || 0)+"% Discount"}</Text>
            <Text style={styles.amount}>{"- K"+((item.rate * (parseInt(item.discount) / 100)).toFixed(2))+" "}</Text>
          </View>
        </>
      ) : (
        <></>
      )}
    </>
  ));
  return <Fragment>{rows}</Fragment>;
};

export default InvoiceTableRow;
