

export const ROLES = {
  client: "Client",
  admin: "Admin",
  schoolAdmin: "SchoolAdmin",
  studentparent: "StudentParent",
  generateContract: "GenerateContract",
  noAuth: "NoAuth",
};

export const SCOPES: string[] = {
  canCreate: "can-create",
  canEdit: "can-edit",
  canDelete: "can-delete",
  canView: "can-view",
  canGenerateContract: "can-generate-contract",
  canapprovepayroll: "can-approve-payroll",
  canrunlivepayroll: "can-run-live-payroll",
  canrunmockpayroll: "can-run-mock-payroll",
  cancreateonetimededuction: "can-create-onetime-deduction",
  cancreaterecurringdeduction: "can-create-recurring-deduction",
  cancreateonetimeallowance: "can-create-onetime-allowance",
  cancreaterecurringallowance: "can-create-recurring-allowance",
};

export const ENHANCEMENTS = {
  canapprovepayroll: [SCOPES.canapprovepayroll],
  canrunlivepayroll: [SCOPES.canrunlivepayroll],
  canrunmockpayroll: [SCOPES.canrunmockpayroll],
  cancreateonetimededuction: [SCOPES.cancreateonetimededuction],
  cancreaterecurringdeduction: [SCOPES.cancreaterecurringdeduction],
  cancreateonetimeallowance: [SCOPES.cancreateonetimeallowance],
  cancreaterecurringallowance: [SCOPES.cancreaterecurringallowance],
};

export const PERMISSIONS = {
  [ROLES.client]: [
    SCOPES.canView,
    SCOPES.canEdit,
    SCOPES.canCreate,
    SCOPES.canViewRevenue,
  ],
  [ROLES.admin]: [
    SCOPES.canView,
    SCOPES.canEdit,
    SCOPES.canCreate,
    SCOPES.canDelete,
    SCOPES.canGenerateContract,
    SCOPES.canViewRevenue,
  ],
  [ROLES.schoolAdmin]: [
    SCOPES.canView,
    SCOPES.canEdit,
    SCOPES.canCreate,
    SCOPES.canDelete,
    SCOPES.canGenerateContract,
  ],
  [ROLES.studentparent]: [
    SCOPES.canView,
  ],
  [ROLES.generateContract]: [
    SCOPES.canView,
    SCOPES.canEdit,
    SCOPES.canCreate,
    SCOPES.canGenerateContract,
  ],
  [ROLES.noAuth]: [
    SCOPES.canChangePassword,
    SCOPES.canCreate
  ]
};
