import { Student } from "./data-models";


export const gradeSortOrder = ['Grade 12','Grade 11', 'Grade 10','Grade 9','Grade 8','Grade 7','Grade 6','Grade 5','Grade 4','Grade 3','Grade 2','Grade 1','Baby class','Baby Class','Reception', 'Baby Class'];   // Declare a array that defines the order of the elements to be sorted.

export function studentSorter(a: Student, b: Student){
    return gradeSortOrder.indexOf(a.grade) - gradeSortOrder.indexOf(b.grade);
}

export function generateStudentID(index: number){
    return "101-22-"+index.toString().padStart(4, '0');
}
    
