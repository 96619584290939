import { Button } from "@mui/material";
import React from "react";
import { NoteAdd, PictureAsPdf } from "@mui/icons-material";

class EditContract extends React.Component<
    { typeOfContract: string; onContractUpdate: (e: Event) => void; contract: string | undefined },
    { contractUrl: string | undefined, fileName: string | undefined }
> {
    constructor(props: any) {
        super(props);
        this.state = { contractUrl: this.props.contract, fileName: undefined };
    }



    addDocument(e) {
        this.setState({
            ...this.state,
            fileName: e.target.files[0].name
        });
        this.props.onContractUpdate(e);
    }

    getContractDate() {
        let urlArray = this.state.contractUrl?.split('_')
        return urlArray[1] || ""
    }

    createUI() {
        const hasUrlAlready = this.state.contractUrl && this.state.contractUrl !== "";
        const buttonText = hasUrlAlready ? "Upload Signed Contract" : "Add document";
        console.log(this.props.contract)

        return <React.Fragment>
            {hasUrlAlready &&
                <a href={this.state.contractUrl}><PictureAsPdf/>{this.props.typeOfContract === "performance"? "View document ":`View contract uploaded on ${this.getContractDate()}`}</a>
            }

            <Button startIcon={<NoteAdd />}
                style={{ marginBottom: 12, marginLeft: 12, maxHeight: 42 }}
                variant="contained"
                color="success"
                size="small"
            >
                <label htmlFor="file-input">
                    {buttonText}
                </label>
            </Button>

            <input
                type="file"
                accept=".pdf"
                name="file-upload"
                id="file-input"
                onChange={this.addDocument.bind(this)}
                style={{display: "none"}}
            />
            {!!this.state.fileName && <span style={{marginLeft: 12}}>{this.state.fileName}</span>}
        </React.Fragment>;
    }

    render() {
        return (
            <>
                {this.createUI()}
            </>
        );
    }
}


export default EditContract;
