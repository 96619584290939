import { styled, useTheme } from "@mui/material/styles";
import { Avatar, Box, Grid, Typography } from "@mui/material";

import Chart from "react-apexcharts";

// project imports
import MainCard from "./ui-component/cards/MainCard";
import SkeletonEarningCard from "./ui-component/cards/Skeleton/EarningCard";

import { getAllEmployees } from "../../util/api-calls";

import { useEffect, useState } from "react";

// assets
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

import BadgeIcon from "@mui/icons-material/Badge";

const CardWrapper = styled(MainCard)(({ theme }) => ({
  color: "#fff",
  overflow: "hidden",
  position: "relative",
  backgroundColor: "#5E35B1",
  borderRadius: "10px",
  marginLeft: "10px",
  marginTop: "8px",
  "&>div": {
    position: "relative",
    zIndex: 5,
  },
  "&:after": {
    content: '""',
    position: "absolute",
    width: 210,
    height: 210,
    backgroundColor: "#4526a0",
    borderRadius: "50%",
    top: -85,
    right: -95,
    [theme.breakpoints.down("sm")]: {
      top: -105,
      right: -140,
    },
  },
  "&:before": {
    content: '""',
    position: "absolute",
    width: 210,
    height: 210,
    backgroundColor: "#4A2A9A",
    borderRadius: "50%",
    top: -125,
    right: -15,
    opacity: 0.5,
    [theme.breakpoints.down("sm")]: {
      top: -155,
      right: -70,
    },
  },
}));

// ===========================|| DASHBOARD DEFAULT - EARNING CARD ||=========================== //

function EmployeeCard(updateDashboard, employees) {
  const [employeeNumber, setEmployeeNumbers] = useState(0);
  const [chart, setChartData] = useState([]);
  const [loading, setLoading] = useState(true);

  const getMostRecentStatus = (statuses) =>{
    return statuses?.sort((a,b) => new Date(b.statusdate).getTime() - new Date(a.statusdate).getTime())[0];
  }


  useEffect(() => {
    setLoading(true);
    // getAllEmployees().then((employees) => {
      if(employees.length > 0){
      let activeEmployees = [];
      let activeEmployeeCount = 0;

      for (var i = 0; i < employees.length; i++) {
        if (
          employees[i]?.status?.length > 0 &&
          getMostRecentStatus(employees[i]?.status)?.status === "Active" ||
          getMostRecentStatus(employees[i]?.status)?.status === "Probation"
        ) {
          activeEmployees.push(employees[i]);
          activeEmployeeCount++;
        }
      }

      setEmployeeNumbers(activeEmployeeCount);

      let date0 = addMonths(new Date(), -11);
      let date1 = addMonths(new Date(), -10);
      let date2 = addMonths(new Date(), -9);
      let date3 = addMonths(new Date(), -8);
      let date4 = addMonths(new Date(), -7);
      let date5 = addMonths(new Date(), -6);
      let date6 = addMonths(new Date(), -5);
      let date7 = addMonths(new Date(), -4);
      let date8 = addMonths(new Date(), -3);
      let date9 = addMonths(new Date(), -2);
      let date10 = addMonths(new Date(), -1);
      let date11 = addMonths(new Date(), 0);

      let chart = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

      for (var i = 0; i < activeEmployees.length; i++) {
        var createdEmployeeDate = activeEmployees[i].createdAt.slice(0, 10);
        if (dateFormat(date0) > createdEmployeeDate) {
          chart[0] += 1;
          chart[1] += 1;
          chart[2] += 1;
          chart[3] += 1;
          chart[4] += 1;
          chart[5] += 1;
          chart[6] += 1;
          chart[7] += 1;
          chart[8] += 1;
          chart[9] += 1;
          chart[10] += 1;
        } else if (dateFormat(date1) > createdEmployeeDate) {
          chart[1] += 1;
          chart[2] += 1;
          chart[3] += 1;
          chart[4] += 1;
          chart[5] += 1;
          chart[6] += 1;
          chart[7] += 1;
          chart[8] += 1;
          chart[9] += 1;
          chart[10] += 1;
        } else if (dateFormat(date2) > createdEmployeeDate) {
          chart[2] += 1;
          chart[3] += 1;
          chart[4] += 1;
          chart[5] += 1;
          chart[6] += 1;
          chart[7] += 1;
          chart[8] += 1;
          chart[9] += 1;
          chart[10] += 1;
        } else if (dateFormat(date3) > createdEmployeeDate) {
          chart[3] += 1;
          chart[4] += 1;
          chart[5] += 1;
          chart[6] += 1;
          chart[7] += 1;
          chart[8] += 1;
          chart[9] += 1;
          chart[10] += 1;
        } else if (dateFormat(date4) > createdEmployeeDate) {
          chart[4] += 1;
          chart[5] += 1;
          chart[6] += 1;
          chart[7] += 1;
          chart[8] += 1;
          chart[9] += 1;
          chart[10] += 1;
        } else if (dateFormat(date5) > createdEmployeeDate) {
          chart[5] += 1;
          chart[6] += 1;
          chart[7] += 1;
          chart[8] += 1;
          chart[9] += 1;
          chart[10] += 1;
        } else if (dateFormat(date6) > createdEmployeeDate) {
          chart[6] += 1;
          chart[7] += 1;
          chart[8] += 1;
          chart[9] += 1;
          chart[10] += 1;
        } else if (dateFormat(date7) > createdEmployeeDate) {
          chart[7] += 1;
          chart[8] += 1;
          chart[9] += 1;
          chart[10] += 1;
        } else if (dateFormat(date8) > createdEmployeeDate) {
          chart[8] += 1;
          chart[9] += 1;
          chart[10] += 1;
        } else if (dateFormat(date9) > createdEmployeeDate) {
          chart[9] += 1;
          chart[10] += 1;
        } else if (dateFormat(date10) > createdEmployeeDate) {
          chart[10] += 1;
        }
      }
      chart[11] = activeEmployees.length;
      setChartData(chart);
      setLoading(false);
    // });
  }
  }, [updateDashboard, employees]);

  const getDaysInMonth = (year, month) => new Date(year, month, 0).getDate();

  const addMonths = (input, months) => {
    const date = new Date(input);
    date.setDate(1);
    date.setMonth(date.getMonth() + months);
    date.setDate(
      Math.min(
        input.getDate(),
        getDaysInMonth(date.getFullYear(), date.getMonth() + 1)
      )
    );
    return date;
  };

  const dateFormat = (date) => {
    return (
      date.getFullYear() +
      "-" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + (date.getDate() + 1)).slice(-2)
    );
  };

  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const chartData = {
    type: "line",
    height: 90,
    options: {
      chart: {
        sparkline: {
          enabled: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#fff"],
      fill: {
        type: "solid",
        opacity: 1,
      },
      stroke: {
        curve: "smooth",
        width: 3,
      },
      yaxis: {
        min: 0,
        max: (employeeNumber * 1.1),
      },
      tooltip: {
        theme: "dark",
        fixed: {
          enabled: false,
        },
        x: {
          show: false,
        },
        y: {
          title: "Total Order",
        },
        marker: {
          show: false,
        },
      },
    },
    series: [
      {
        name: "",
        data: chart,
      },
    ],
  };

  const cardClick = () => {
    window.location.href = "/employees";
  };

  return (
    <>
      {loading ? (
        <SkeletonEarningCard />
      ) : (
        <>
          <CardWrapper border={false} content={false}>
            <Box sx={{ p: 2.25 }}>
              <Grid container direction="column">
                <Grid item>
                  <Grid container justifyContent="space-between">
                    <Grid item>
                      <Avatar
                        variant="rounded"
                        sx={{
                          ...theme.typography.commonAvatar,
                          ...theme.typography.largeAvatar,
                          backgroundColor: "#4526a0",
                          color: "#fff",
                          mt: 1,
                        }}
                      >
                        <BadgeIcon />
                      </Avatar>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item sx={{ mb: 6 }}>
                  <Grid container alignItems="center">
                    <Grid item xs={5}>
                      <Grid container alignItems="center">
                        <Grid item>
                          <Typography
                            sx={{
                              fontSize: "2.125rem",
                              fontWeight: 500,
                              mr: 1,
                              mt: 1.75,
                              mb: 0.75,
                            }}
                          >
                            {employeeNumber}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <span onClick={cardClick}>
                            <Avatar
                              sx={{
                                ...theme.typography.smallAvatar,
                                cursor: "pointer",
                                backgroundColor: "#B39DDB",
                                color: theme.palette.secondary.dark,
                              }}
                            >
                              <ArrowUpwardIcon
                                fontSize="inherit"
                                sx={{ transform: "rotate3d(1, 1, 1, 45deg)" }}
                              />
                            </Avatar>
                          </span>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            sx={{
                              fontSize: "1rem",
                              fontWeight: 500,
                              color: theme.palette.primary[200],
                            }}
                          >
                            Employees
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={6}>
                      <Chart {...chartData} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </CardWrapper>
        </>
      )}
    </>
  );
};

export default EmployeeCard;
