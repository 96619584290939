import { Button, Divider, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import React from "react";
import { Child } from "../../util/data-models";
import DeleteIcon from "@mui/icons-material/Delete";
import AddReactionIcon from "@mui/icons-material/AddReaction";
import { v4 as uuidv4 } from "uuid";

class EditChildren extends React.Component<
  { onChildrenUpdate: (children: Child[]) => void; children: Child[] },
  { children: Child[] }
> {
  constructor(props: any) {
    super(props);
    this.state = { children: this.props.children };
  }

  today() {
    let date = new Date();
    return (
      date.getFullYear() +
      "-" +
      ("0" + (new Date().getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + (new Date().getDate())).slice(-2)
    ).toString();
  }

  createUI() {
    return this.state?.children?.map((child: Child, i) => (
      <React.Fragment key={child.id}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="stretch"
          spacing={2}
        >
          <Grid container item xs={11} spacing={2}>
            <Grid item xs={4}>
              <TextField
                label="Child First Name"
                InputLabelProps={{ shrink: true }}
                fullWidth
                type="text"
                name={"childFirstName"}
                value={child.childFirstName || ""}
                onChange={this.handleChange.bind(this, i)}
              />
            </Grid>

            <Grid item xs={4}>
              <TextField
                label="Child Last Name"
                InputLabelProps={{ shrink: true }}
                fullWidth
                type="text"
                name={"childLastName"}
                value={child.childLastName || ""}
                onChange={this.handleChange.bind(this, i)}
              />
            </Grid>

            <Grid item xs={4}>
              <TextField
                label="Child Date of Birth"
                fullWidth
                type="date"
                name={"childDob"}
                value={child.childDob || ""}
                onChange={this.handleChange.bind(this, i)}
                inputProps={{
                  min: "1920-01-24",
                  max: this.today(),
                }}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xs={4}>
              <FormControl
                fullWidth
                style={{
                  display: "flex",
                  minWidth: 172,
                  flex: 1,
                  marginRight: 24,
                }}
              >
                <InputLabel 
                shrink={true}
                id="gender-label">Gender</InputLabel>
                <Select
                  name="childGender"
                  labelId="gender-label"
                  id="childGender"
                  value={child.childGender}
                  onChange={this.handleChange.bind(this, i)}
                  label="Child Gender"
                >
                  <MenuItem value={"Male"}>Male</MenuItem>
                  <MenuItem value={"Female"}>Female</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Child Student Number"
                InputLabelProps={{ shrink: true }}
                fullWidth
                type="text"
                name={"childStudentNum"}
                value={child.childStudentNum || ""}
                onChange={this.handleChange.bind(this, i)}
              />
            </Grid>
            <Grid item xs={4}></Grid>
          </Grid>
          <Grid
            container
            item
            direction="column"
            xs={1}
            justifyContent="center"
          >
            <IconButton
              onClick={this.removeClick.bind(this, i)}
              style={{ display: "flex" }}
              aria-label="delete"
              color="warning"
            >
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Divider style={{ marginTop: 12, marginBottom: 24 }}></Divider>
      </React.Fragment>
    ));
  }

  handleChange(i: number, event: React.ChangeEvent) {
    let children = [...this.state.children];
    const target = event.target as HTMLInputElement;
    children[i][event.target.name] = target.value;

    this.setState({ children });

    this.props.onChildrenUpdate(children);
  }

  addClick() {
    this.setState((prevState) => ({
      children: [
        ...prevState.children,
        {
          id: uuidv4(),
          childFirstName: "",
          childLastName: "",
          childGender: "",
          childDob: "",
          childStudentNum: "",
        },
      ],
    }));
  }

  removeClick(i: number) {
    let children = [...this.state.children];
    children.splice(i, 1);
    this.setState({ children });
    this.props.onChildrenUpdate(children);
  }

  render() {
    return (
      <>
        {this.createUI()}
        <Button
          startIcon={<AddReactionIcon />}
          style={{ marginBottom: 12 }}
          variant="contained"
          color="success"
          size="small"
          onClick={this.addClick.bind(this)}
        >
          Add Child
        </Button>
      </>
    );
  }
}

export default EditChildren;
