import {
  Button,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React, { ChangeEvent } from "react";
import { Enrolment, EnrolmentPayment } from "../../util/data-models";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCardIcon from "@mui/icons-material/AddCard";
import { v4 as uuidv4 } from "uuid";
import { uploadToS3Bucket, getS3URL } from "../../util/s3-utils";
import EnrolmentPaymentDialog from "./enrolmentpaymentmodal";
import { PictureAsPdf } from "@mui/icons-material";
import Emoji from "../ui-elements/emoji";

class EditEnrolmentPayments extends React.Component<
  {
    onPaymentsUpdate: (
      payments: EnrolmentPayment[],
      enrolment_id: string
    ) => void;
    enrolment: Enrolment;
  },
  {
    payments: EnrolmentPayment[];
    editModalPaymentId: any;
    addingNewPayment: boolean;
    currentPaymentId: any; // Null when no modal is open, otherwise holds the ID of the editing/adding payment
    isNewPayment: any;
  }
> {
  constructor(props) {
    super(props);
    this.state = {
      payments: this.props.enrolment?.payments ?? [],
      editModalPaymentId: null,  // Used for editing existing payments
      addingNewPayment: false,
      currentPaymentId: null, // Null when no modal is open, otherwise holds the ID of the editing/adding payment
      isNewPayment: false,   // Flag to control visibility of modal for adding new payments
    };

    this.addClick = this.addClick.bind(this);
    this.removeClick = this.removeClick.bind(this);
    this.openEditModal = this.openEditModal.bind(this);
    this.closeModals = this.closeModals.bind(this);
    this.handleProofOfPaymentUpload = this.handleProofOfPaymentUpload.bind(this);
    this.updatePaymentProof = this.updatePaymentProof.bind(this);
    this.hasUrlAlready = this.hasUrlAlready.bind(this);
  }

  addClick() {
    const newPayment = {
      id: uuidv4(),
      status: "Pending",
      amount: 0,
      discount: "",
      type: "",
      paymentproof: "",
      createdAt: new Date(),
    };

    // Set the flag to open the modal for adding a new payment
    this.setState(prevState => ({
      payments: [...prevState.payments, newPayment],
      addingNewPayment: true,  // Open the modal for the new payment
    }));
  }

  removeClick(paymentId) {
    const updatedPayments = this.state.payments.filter(p => p.id !== paymentId);
    this.setState({
      payments: updatedPayments,
      editModalPaymentId: null  // Close any open modals when a payment is removed
    });
    this.props.onPaymentsUpdate(updatedPayments, this.props.enrolment.id);
  }

  openEditModal = (paymentId) => {
    this.setState({ editModalPaymentId: paymentId });
  }

  closeModals = () => {
    this.setState({ editModalPaymentId: null, addingNewPayment: false });
  }

  handleProofOfPaymentUpload = (e: ChangeEvent<HTMLInputElement>, paymentId: string) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      uploadToS3Bucket(files[0], "enrolment-payments/" + paymentId).then(() => {
        this.updatePaymentProof(paymentId);
      });
    }
  }

  updatePaymentProof(paymentId) {
    const index = this.state.payments.findIndex(p => p.id === paymentId);
    if (index !== -1) {
      const updatedPayments = [...this.state.payments];
      updatedPayments[index].paymentproof = getS3URL("enrolment-payments/" + paymentId);
      this.setState({ payments: updatedPayments });
      this.props.onPaymentsUpdate(updatedPayments, this.props.enrolment.id);
    }
  }

  handleOpenNewPayment = () => {
    const newPayment = {
      id: uuidv4(), // Ensure this is unique
      amount: 0,
      status: 'Pending',
      type: '',
      discount: '',
      paymentproof: '',
      createdAt: new Date()
    };
    this.setState({
      currentPaymentId: newPayment.id,
      isNewPayment: true,
      payments: [...this.state.payments, newPayment]
    });
  }

  handleOpenEditPayment = (paymentId) => {
    this.setState({
      currentPaymentId: paymentId,
      isNewPayment: false
    });
  }

  handleCloseModal = () => {
    if (this.state.isNewPayment) {
      this.setState(prevState => ({
        payments: prevState.payments.filter(p => p.id !== prevState.currentPaymentId),
        currentPaymentId: null,
        isNewPayment: false
      }));
    } else {
      this.setState({
        currentPaymentId: null,
        isNewPayment: false
      });
    }
  }

  handleSavePayment = (updatedPayment) => {
    const updatedPayments = this.state.payments.map(p =>
      p.id === updatedPayment.id ? updatedPayment : p
    );
    this.setState({
      payments: updatedPayments,
      currentPaymentId: null,
      isNewPayment: false
    });
    this.props.onPaymentsUpdate(updatedPayments, this.props.enrolment.id);
  }

  hasUrlAlready = (p: EnrolmentPayment) =>
    !!p.paymentproof && p.paymentproof?.length > 0;
  getButtonText = (p: EnrolmentPayment) =>
    this.hasUrlAlready(p) ? "Update proof of payment" : "Add proof of payment";

  render() {
    const currentPayment = this.state.payments.find(p => p.id === this.state.currentPaymentId) || {};
    return (
      <>
        <Typography variant="h6">Manage Payments</Typography>
        {this.state.payments.map((payment, index) => (
          <Grid key={payment.id}>
            <Typography>Payment {index + 1} - K{payment.amount}</Typography>
            <Typography>Type: {payment.type || "Cash"}</Typography>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => this.handleOpenEditPayment(payment.id)}>Edit</Button>
            <IconButton style={{ color: "red" }} onClick={() => this.removeClick(payment.id)}><DeleteIcon /></IconButton>

            <Grid
              container
              direction="row"
              justifyContent="start"
              alignItems="stretch"
              spacing={2}
            >
              <Grid item xs={4} alignSelf="center">
                {payment?.paymentproof.length > 0 && (
                  <Typography variant="subtitle2" style={{ marginBottom: 12 }}>
                    Proof of payment uploaded{" "}
                    <Emoji symbol="✅" label="checkmark"></Emoji>
                  </Typography>
                )}
              </Grid>
              <Grid item xs={4} alignSelf="center">
                {this.hasUrlAlready(payment) && (
                  <a href={payment.paymentproof}>
                    <PictureAsPdf />
                    View Proof
                  </a>
                )}
              </Grid>
            </Grid>

          </Grid>
        ))}
        <Button
          variant="contained"
          color="success"
          size="small"
          onClick={this.handleOpenNewPayment}><AddCardIcon /> Add New Payment</Button>
        {this.state.currentPaymentId && (
          <EnrolmentPaymentDialog
            open={true}
            onClose={this.handleCloseModal}
            payment={currentPayment}
            onUpdatePayment={this.handleSavePayment}
            onDocumentUpdate={this.handleProofOfPaymentUpload}
          />
        )}
      </>
    );
  }
}

export default EditEnrolmentPayments;




