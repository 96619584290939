import { Alert, Button, ButtonGroup, Grid, IconButton, Snackbar, Typography } from "@mui/material";
import React, { ReactNode } from "react";
import { deleteStudent, getAllStudents, getFees } from "../../util/api-calls";
import { Enrolment, EnrolmentPayment, Fees, Grades, Guardian, Student } from "../../util/data-models";
import SchoolTwoToneIcon from '@mui/icons-material/School';
import CloseIcon from '@mui/icons-material/Close';
import { v4 as uuidv4 } from "uuid";
import { enrolmentStatuses, getFeeAmountByGradeStudentGrade } from "../../util/enrolment-utils";
import { isStudentEligibleForReEnrolment, getNewGradeFromCurrentGrade, getNewEnrolment, createNewStudent, getNumberOfEligibleStudentsForGrade, getNewRegistrationForStudent, Grade, gradesList } from "./enrolmentUtils";
import { generateStudentID, studentSorter } from "../../util/students-util";

//TODO: in enrolments view, make sure that most recent enrolment is shown on top

class ManageEnrolments extends React.Component<{}, { students: Student[]; fees: Fees; snackbarOpen: boolean }>{
    constructor(props: any) {
        super(props);
        this.state = {
            students: [],
            fees: {} as Fees,
            snackbarOpen: false
        };
    }

    componentDidMount() {
        Promise.all([getFees(), getAllStudents()])
            .then(([fees, students]) => {
                this.setState(() => ({
                    fees: fees,
                    students: students
                }))
            });
    }

    deleteTestStudents(): void {
        const idsToDelete = this.state.students.filter((student, index) =>
            student.firstname.includes("Test")
        ).map(student => student.id);

        idsToDelete.forEach(id => {
            deleteStudent(id as string);
        });
    }

    render() {
        const moveEligibleStudentsForGrade = (students: Student[], grade: string, fees: Fees) => {
            let newStudents = [...students];

            let studentsToUpdate = newStudents.filter(student => {
                return student.grade === grade && isStudentEligibleForReEnrolment(student);
            });

            studentsToUpdate.forEach((student, index) => {
                let newStudent = { ...student };
                newStudent.grade = getNewGradeFromCurrentGrade(student.grade as Grade);
                if(student.grade !== Grades["Grade 12"]){
                newStudent.enrolments.push(getNewEnrolment(student, fees));
                }
                studentsToUpdate[index] = newStudent;
            });

            const requestOptions = {
                method: "PATCH",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(studentsToUpdate),
            };

            fetch("https://school.kozosms.com/api/api/students/", requestOptions)
                .then((response) => { console.log(response) });

            this.setState(() => ({
                snackbarOpen: true
            }))
        }

        const createStudentIds = (students: Student[])=>{
            let newStudents = [...students.sort(studentSorter)];
            console.log(newStudents);
            // newStudents.forEach((student, index) => {
            //     let newStudent = { ...student };
            //     newStudent.studentid = generateStudentID(index+1);
            //     newStudents[index] = newStudent;
            // });

            // const chunkSize = 50;
            // for (let i = 0; i < newStudents.length; i += chunkSize) {
            //     const chunk = newStudents.slice(i, i + chunkSize);
            //     // do whatever
            //     const requestOptions = {
            //         method: "PATCH",
            //         headers: { "Content-Type": "application/json" },
            //         body: JSON.stringify(chunk),
            //     };
    
            //     fetch("https://school.kozosms.com/api/api/students/", requestOptions)
            //         .then((response) => { console.log(response) });

            //         setTimeout(() => {  console.log("batch "+i+1); }, 5000);
            // }

            

        }

        const handleCloseSnackbar = (
            event: React.SyntheticEvent | Event,
            reason?: string
        ) => {
            if (reason === "clickaway") {
                return;
            }
            this.setState(() => ({
                snackbarOpen: false
            }));
        };

        const actionSnackbar = (
            <React.Fragment>
                <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={handleCloseSnackbar}
                >
                    <CloseIcon fontSize="small" />
                </IconButton>
            </React.Fragment>
        );

        return <>
            <Typography variant="h4">
                <SchoolTwoToneIcon fontSize="medium" color="primary" />
                Advance students by grade
            </Typography>

            <Grid
                style={{ marginTop: 12 }}
                container
                spacing="6"
                direction="column"
                alignItems="center"
            >
                {
                    gradesList.map((grade, index) => {
                        return getButtonForGrade(this.state?.students,
                            grade,
                            this.state?.fees,
                            moveEligibleStudentsForGrade)
                    })
                }

                {/* {<Grid container
                    style={{ marginTop: 12 }}
                    item
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing="6">
                    <Button
                        onClick={() => createStudentIds(this.state.students)}
                        color="error"
                        variant="contained">
                        CREATE STUDENT IDS
                    </Button>

                </Grid>} */}
                
            </Grid>


            <Snackbar
                open={this.state.snackbarOpen}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
                message="Grade advanced"
                action={actionSnackbar}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
                <Alert
                    onClose={handleCloseSnackbar}
                    severity="success"
                    sx={{ width: "100%" }}
                >
                    Grade advanced
                </Alert>
            </Snackbar>
        </>
    }

}

export default ManageEnrolments;

const listOfStudents = [1, 2, 3, 4, 5];

function createTestStudentsToMigrate() {
    let newStudents = listOfStudents.map((val, index) => {
        return createNewStudent("Test" + val, "Testerson" + val);
    });

    const requestOptions = {
        method: "PATCH",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(newStudents),
    };

    fetch("https://school.kozosms.com/api/api/students/", requestOptions)
        .then((response) => { console.log(response) });
}

function getButtonForGrade(students: Student[], grade: string, fees: Fees, advanceFunction: Function) {
    return <Grid
        container
        item
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing="6">
        <Grid item>
            <Button
                variant="contained"
                onClick={() => { advanceFunction(students, grade, fees) }}>
                Advance {grade}
            </Button>
        </Grid>
        <Grid item>
            <Typography>Students available to advance: {getNumberOfEligibleStudentsForGrade(students, grade, fees)}</Typography>
        </Grid>
    </Grid>;
}