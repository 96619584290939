import {
  Button,
  Dialog,
  DialogActions,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { ChangeEvent } from "react";
import { Registration } from "../../util/data-models";
import { NoteAdd, PictureAsPdf } from "@mui/icons-material";

const modalElementStyle = {
  margin: 12,
  maxWidth: 242,
  maxHeight: 42,
};

class PaymentProofDialog extends React.Component<
  {
    open: boolean;
    onDocumentUpdate: (
      e: ChangeEvent<HTMLInputElement>,
      reg_id: string
    ) => void;
    onTypeUpdate: (reg_id: string) => void;
    onClose: (value: string) => void;
    registration: Registration;
  },
  {
    paymentProofUrl: string | undefined;
    fileName: string | undefined;
    amount: string | undefined;
    registrationObj: Registration;
  }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      paymentProofUrl: this.props.registration?.paymentproof,
      fileName: undefined,
      amount: this.props.registration?.amount_due,
      type: this.props.registration?.type,
      registrationObj: this.props.registration,
    };
  }

  handleDialogClose = () => {
    this.props.onClose("");
  };

  addProofOfPayment(e: ChangeEvent<HTMLInputElement>) {
    const files = e?.target?.files;
    if (files && files?.length > 0) {
      this.setState({
        ...this.state,
        fileName: files[0].name,
      });
      this.props.onDocumentUpdate(e, this.props.registration.id);
    }
  }

  handleTypeChange(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    console.log(e.target.value)
    const updatedRegistration = this.state.registrationObj;
    updatedRegistration.type = e.target.value;
    this.setState(
      (oldState, props) => (
        {
          ...oldState,
          registrationObj: updatedRegistration,
        },
        () => {
          this.props.onDocumentUpdate(e, this.props.registration.id);
        }
      )
    );

    //this.props.onTypeUpdate(e, this.props.registration.id);
  }

  createUI() {
    const hasUrlAlready =
      !!this.state?.paymentProofUrl && this.state.paymentProofUrl?.length > 0;
    const buttonText = hasUrlAlready
      ? "Update payment Proof (optional)"
      : "Update Payment proof (optional)";

    return (
      <React.Fragment>
        <div
          style={{
            paddingTop: 20,
            maxWidth: 380,
            height: 220,
          }}
        >
          <Typography
            style={{
              marginTop: -5,
              fontSize: 14,
              padding: 2,
              minWidth: 135,
              maxWidth: 370,
              flex: 1,
              marginLeft: 12,
              marginBottom: 20,
              textAlign: "left",
            }}
          >
            {"Upload payment type and proof of payment document"}
          </Typography>
          <Typography
            style={{
              marginTop: -5,
              fontSize: 15,
              padding: 2,
              minWidth: 135,
              maxWidth: 135,
              flex: 1,
              marginLeft: 12,
              textAlign: "left",
            }}
          >
            {"Amount: K" + this.state.amount}
          </Typography>

          <TextField
            autoFocus
            margin="dense"
            id="type"
            label="Type"
            type="text"
            size="small"
            style={{
              margin: 12,
              maxWidth: 242,
              minWidth: 242,
              maxHeight: 42,
              marginRight: 100,
            }}
            defaultValue={this.state.registrationObj?.type || "Cash"}
            onChange={this.handleTypeChange.bind(this)}
          />

          <input
            type="file"
            accept="image/*"
            name="file-upload"
            id="file-input"
            onChange={this.addProofOfPayment.bind(this)}
            style={{ display: "none" }}
          />
          {!!this.state.fileName && (
            <span style={{ marginLeft: 12, fontSize: 12 }}>{this.state.fileName}</span>
          )}
          {hasUrlAlready && (
            <a href={this.state.paymentProofUrl} 
            style={{
              marginTop: 20,
              marginLeft: 12,
              minWidth: 242,
              maxHeight: 42,
              fontSize: 12,
              marginRight: 100,
            }}>
              <PictureAsPdf />
              View Uploaded Proof
            </a>
          )}

          <Button
            startIcon={<NoteAdd />}
            style={{
              margin: 12,
              maxWidth: 242,
              maxHeight: 42,
            }}
            variant="contained"
            color="success"
            size="small"
          >
            <label htmlFor="file-input">{buttonText}</label>
          </Button>
        </div>
      </React.Fragment>
    );
  }

  render() {
    return (
      <Dialog open={this.props.open} onClose={this.props.onClose}>
        {this.createUI()}

        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              this.props.onTypeUpdate(this.props.registration.id);
              this.handleDialogClose();
            }}
          >
            Done
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default PaymentProofDialog;
