import {
  Button,
  Divider,
  Grid,
  IconButton,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React from "react";
import { EmployeeLeave } from "../../util/data-models";
import DeleteIcon from "@mui/icons-material/Delete";
import AddReactionIcon from "@mui/icons-material/AddReaction";
import { v4 as uuidv4 } from "uuid";
import AddIcon from "@mui/icons-material/Add";

class EditLeave extends React.Component<
  { onLeaveUpdate: (leaves: EmployeeLeave[]) => void; leaves: EmployeeLeave[] },
  { leaves: EmployeeLeave[] }
> {
  constructor(props: any) {
    super(props);
    this.state = { leaves: this.props.leaves };
  }

  createUI() {
    return this.state?.leaves?.map((leave: EmployeeLeave, i) => (
      <React.Fragment key={leave.id}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="stretch"
          spacing={2}
        >
          <Grid container item xs={11} spacing={2}>
            <Grid item xs={4}>
              <FormControl
                required
                size="small"
                style={{
                  marginTop: 5,
                  display: "flex",
                  minWidth: 127,
                  flex: 1,
                  marginRight: 9,
                }}
              >
                <InputLabel id="leavetype-label">Leave Type</InputLabel>
                <Select
                  labelId="leavetype-label"
                  id="leavetype"
                  name="leavetype"
                  value={leave.leavetype}
                  onChange={this.handleChange.bind(this, i)}
                  label="Leave Type"
                >
                  <MenuItem value={"Annual Leave"}>Annual Leave</MenuItem>
                  <MenuItem value={"Sick Leave"}>Sick Leave</MenuItem>
                  <MenuItem value={"Maternity Leave"}>Maternity Leave</MenuItem>
                  <MenuItem value={"Patternity Leav"}>
                    Patternity Leave
                  </MenuItem>
                  <MenuItem value={"Other"}>Other</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={4}>
              <TextField
                required
                size="small"
                style={{
                  marginTop: 5,
                  display: "flex",
                  minWidth: 127,
                  flex: 1,
                  marginRight: 9,
                }}
                label="Leave Start Date"
                fullWidth
                type="date"
                name={"leavestart"}
                value={leave.leavestart || ""}
                onChange={this.handleChange.bind(this, i)}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xs={4}>
              <TextField
                required
                size="small"
                style={{
                  marginTop: 5,
                  display: "flex",
                  minWidth: 127,
                  flex: 1,
                  marginRight: 9,
                }}
                label="Leave End Date"
                fullWidth
                type="date"
                name={"leaveend"}
                value={leave.leaveend || ""}
                onChange={this.handleChange.bind(this, i)}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xs={4}>
              <TextField
                required
                size="small"
                style={{
                  marginTop: 5,
                  display: "flex",
                  minWidth: 127,
                  flex: 1,
                  marginRight: 9,
                }}
                label="Days of leave"
                fullWidth
                type="number"
                name={"leavedays"}
                value={leave.leavedays || ""}
                inputProps={{
                  min: 0,
                }}
                onChange={this.handleChange.bind(this, i)}
              />
            </Grid>
            <Grid item xs={4}></Grid>
          </Grid>
          <Grid
            container
            item
            direction="column"
            xs={1}
            justifyContent="center"
          >
            <IconButton
              onClick={this.removeClick.bind(this, i)}
              style={{ display: "flex" }}
              aria-label="delete"
              color="warning"
            >
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Divider style={{ marginTop: 12, marginBottom: 24 }}></Divider>
      </React.Fragment>
    ));
  }

  handleChange(i: number, event: React.ChangeEvent) {
    let leaves = [...this.state.leaves];
    const target = event.target as HTMLInputElement;
    leaves[i][event.target.name] = target.value;

    this.setState({ leaves });

    this.props.onLeaveUpdate(leaves);
  }

  addClick() {
    this.setState((prevState) => ({
      leaves: [
        ...prevState.leaves,
        {
          id: uuidv4(),
          leavetype: "",
          leavestart: "",
          leaveend: "",
          leavedays: "",
        },
      ],
    }));
  }

  removeClick(i: number) {
    let leaves = [...this.state.leaves];
    leaves.splice(i, 1);
    this.setState({ leaves });
    this.props.onLeaveUpdate(leaves);
  }

  render() {
    return (
      <>
        {this.createUI()}
        <Button
          startIcon={<AddIcon />}
          style={{ marginBottom: 12 }}
          variant="contained"
          color="success"
          size="small"
          onClick={this.addClick.bind(this)}
        >
          Record Leave
        </Button>
      </>
    );
  }
}

export default EditLeave;
