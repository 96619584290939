import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Slide,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { Guardian, Registration, Student } from "../../util/data-models";
import { v4 as uuidv4 } from "uuid";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCardIcon from "@mui/icons-material/AddCard";
import AddLinkIcon from "@mui/icons-material/AddLink";
import DescriptionIcon from "@mui/icons-material/Description";
import InvoiceModal from "./invoicemodal";
import StudentListDialog from "./studentlistdialog";
import Emoji from "../ui-elements/emoji";
import PaymentProofDialog from "./paymentproofmodal";
import { getS3URL, uploadToS3Bucket } from "../../util/s3-utils";
import { InvoiceData } from "./pdfgenerator/invoice-data";
import { TransitionProps } from "@mui/material/transitions";

class NewEditRegistration extends React.Component<
  {
    onNewStudentsUpdate: (students: Student[]) => void;
    onRegistrationsUpdate: (registrations: Registration[]) => void;
    guardian: Guardian | any;
    students: Student[];
  },
  {
    registrations: Registration[];
    invoiceModalOpen: boolean;
    studentListModalOpen: boolean;
    paymentProofModalFlags: boolean[];
    registrationFee: string;
    deletePopUp: boolean;
    currentRegistration: number;
  }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      registrations: this.props.guardian?.registration ?? [],
      invoiceModalOpen: false,
      studentListModalOpen: false,
      paymentProofModalFlags: [],
      registrationFee: "0",
      deletePopUp: false,
      currentRegistration: 0,
    };
  }

  componentDidMount() {
    fetch("https://school.kozosms.com/api/api/fees/c892344d-4d67-46ed-8de8-c2c1415bd929")
      .then((resp) => resp.json())
      .then((resp) => {
        this.setState({
          ...this.state,
          registrationFee: resp.registration,
        });
      })
      .then((resp) => {
        console.log(this.state.registrationFee);
      });
  }

  Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  handleCloseDelete() {
    this.setState({
      ...this.state,
      deletePopUp: false,
      currentRegistration: 0,
    });
  }

  handleOpenDelete(i: number) {
    console.log(i);
    this.setState({
      currentRegistration: i,
      deletePopUp: true,
    });
  }

  addClick() {
    if (this.state.registrations) {
      this.setState(
        (prevState) => ({
          registrations: [
            ...prevState.registrations,
            {
              id: uuidv4(),
              status: "Pending",
              discount: 0,
              year: "",
              term: "",
              paymentproof: "",
              student_id: "",
              amount_due: parseInt(this.state.registrationFee),
              amount_paid: 0,
              type: "Cash",
            },
          ],
          paymentProofModalFlags: [...prevState.paymentProofModalFlags, false],
        }),
        () => {
          this.props.onRegistrationsUpdate(this.state.registrations);
        }
      );
      console.log(this.state.registrations);
    } else {
      this.setState(
        () => ({
          registrations: [
            {
              id: uuidv4(),
              status: "Pending",
              discount: 0,
              year: "",
              term: "",
              paymentproof: "",
              student_id: "",
              amount_due: parseInt(this.state.registrationFee),
              amount_paid: 0,
              type: "Cash",
            },
          ],
          paymentProofModalFlags: [false],
        }),
        () => {
          this.props.onRegistrationsUpdate(this.state.registrations);
        }
      );
      console.log(this.state.registrations);
    }

    this.props.onRegistrationsUpdate(this.state.registrations);
  }

  updateRegistration(new_reg: Registration) {
    const newRegistrations = JSON.parse(
      JSON.stringify(this.state.registrations)
    ) as typeof this.state.registrations;
    let indexToBeUpdated = -1;
    newRegistrations.map((reg, index) => {
      if (reg.id === reg.id) {
        if (
          new_reg?.paymentproof?.length > 0 &&
          new_reg?.student_id?.length > 0
        ) {
          new_reg.status = "Complete";
        }
        indexToBeUpdated = index;
      }
    });
    if (indexToBeUpdated >= 0) {
      newRegistrations[indexToBeUpdated] = new_reg;
    }
    this.setState({
      ...this.state,
      registrations: newRegistrations,
    });
    this.props.onRegistrationsUpdate(newRegistrations);
  }

  completeRegistration(new_reg: Registration) {
    const newRegistrations = JSON.parse(
      JSON.stringify(this.state.registrations)
    ) as typeof this.state.registrations;
    let indexToBeUpdated = -1;
    newRegistrations.map((reg, index) => {
      if (reg.id === reg.id) {
        if (
          // new_reg?.paymentproof?.length > 0 &&
          new_reg?.student_id?.length > 0
        ) {
          new_reg.status = "Complete";
        }
        indexToBeUpdated = index;
      }
    });
    if (indexToBeUpdated >= 0) {
      newRegistrations[indexToBeUpdated] = new_reg;
    }
    this.setState({
      ...this.state,
      registrations: newRegistrations,
    });
    this.props.onRegistrationsUpdate(newRegistrations);
  }

  removeClick(i: number) {
    let registrations = [...this.state.registrations];
    registrations.splice(i, 1);
    this.setState({
      ...this.state,
      registrations: registrations,
      deletePopUp: false,
      currentRegistration: 0,
    });
    //this.handleCloseDelete();
    this.props.onRegistrationsUpdate(registrations);
    // this.setState({
    //   ...this.state,
    //     deletePopUp: false,
    //     currentRegistration: 0,
    // });
  }

  getRegisteredStudentName(student_id?: string) {
    if (student_id) {
      const student = this.props.students.find((s) => s.id === student_id);
      return student?.firstname + " " + student?.lastname;
    }
  }

  getNotregisteredStudents(students: Student[]): Student[] {
    return students.filter(
      (s) => !this.state.registrations.some((r) => r?.student_id === s.id)
    );
  }

  getInvoiceData(reg: Registration): InvoiceData {
    const currentDate = new Date().toISOString().slice(0, 10);
    return {
      studentName: this.getRegisteredStudentName(reg?.student_id) || "",
      company:
        this.props.guardian.firstname + " " + this.props.guardian.lastname,
      id: reg?.id,
      invoice_no: currentDate.replace(/-/g, "") + reg?.id.slice(0, 4),
      email: this.props.guardian.email,
      phone: this.props.guardian.phone,
      address: this.props.guardian.address,
      trans_date: currentDate,
      items: [
        {
          item_num: 0,
          desc: "Registration fee",
          qty: 1,
          rate: reg?.amount_due,
        },
      ],
    };
  }

  createUI() {
    const handleProofOfPaymentUpload = (e, reg_id) => {
      const reader = new FileReader();

      reader.onload = () => {
        uploadToS3Bucket(
          e.target.files[0],
          "registration-payments/" + reg_id
        ).then(() => {
          if (reader.readyState === 2) {
            const regToUpdate = this.state.registrations.find(
              (reg) => reg.id === reg_id
            );
            if (!!regToUpdate) {
              regToUpdate.paymentproof = getS3URL(
                "registration-payments/" + reg_id
              );
              this.updateRegistration(regToUpdate);
            }
          }
        });
      };
      reader.readAsDataURL(e.target.files[0]);
    };
    const handleTypeUpdate = (reg_id) => {
      const regToUpdate = this.state.registrations.find(
        (reg) => reg.id === reg_id
      );

      this.completeRegistration(regToUpdate);
    };
    return (
      <>
        <Dialog
          open={this.state.deletePopUp}
          TransitionComponent={this.Transition}
          keepMounted
          onClose={this.handleCloseDelete.bind(this)}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>{"Are you sure you want to delete?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              If you click Yes, the record will be removed. Click No to cancel.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseDelete.bind(this)}>No</Button>
            <Button
              onClick={this.removeClick.bind(
                this,
                this.state.currentRegistration
              )}
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>

        {this.state?.registrations?.map((reg: Registration, i) => (
          <React.Fragment key={reg?.id}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="stretch"
              spacing={2}
              style={{ verticalAlign: "center" }}
            >
              <Grid item xs={9} alignSelf="center">
                <Typography
                  style={{
                    paddingLeft: 0,
                    fontSize: 16,
                  }}
                >
                  Registration #{i + 1}
                </Typography>
              </Grid>

              <Grid item xs={2} alignSelf="center">
                {
                  <Chip
                    label={reg?.status}
                    color={reg?.status === "Complete" ? "success" : "warning"}
                    size="small"
                    style={{ display: "flex" }}
                  />
                }
              </Grid>

              <Grid item xs={1} alignSelf="center">
                <IconButton
                  onClick={() => {
                    this.handleOpenDelete(i);
                  }}
                  style={{ display: "flex" }}
                  aria-label="delete"
                  color="warning"
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="start"
              alignItems="stretch"
              spacing={2}
            >
              <Grid item xs={2} alignSelf="center">
                <Typography
                  style={{ paddingLeft: 0, fontSize: 13 }}
                  variant="body1"
                >
                  Amount:
                </Typography>
              </Grid>

              <Grid item xs={1} alignSelf="center">
                <Typography variant="body2">{reg?.amount_due}</Typography>
              </Grid>

              <Grid item xs={3} alignSelf="center">
                <Button
                  startIcon={<DescriptionIcon />}
                  variant="contained"
                  color="secondary"
                  size="small"
                  onClick={() => {
                    this.setState({
                      ...this.state,
                      invoiceModalOpen: true,
                    });
                  }}
                >
                  View Invoice
                </Button>

                <InvoiceModal
                  open={this.state.invoiceModalOpen}
                  onClose={() => {
                    this.setState({
                      ...this.state,
                      invoiceModalOpen: false,
                    });
                  }}
                  invoiceData={this.getInvoiceData(reg)}
                />
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="start"
              alignItems="stretch"
              spacing={2}
            >
              <Grid item xs={2} alignSelf="center">
                <Typography
                  style={{ paddingLeft: 0, fontSize: 13 }}
                  variant="body1"
                >
                  Type:
                </Typography>
              </Grid>

              <Grid item xs={1} alignSelf="center">
                <Typography variant="body2">{reg?.type || "Cash"}</Typography>
              </Grid>
            </Grid>

            <Grid
              style={{ marginTop: 24 }}
              container
              direction="row"
              justifyContent="start"
              alignItems="stretch"
              spacing={2}
            >
              <Tooltip title="You must link a student before recording payment">
              <Grid item xs={4} alignSelf="center">
                
                  <Button
                    disabled={reg?.student_id === ""}
                    startIcon={<NoteAddIcon />}
                    style={{ marginBottom: 12 }}
                    variant="contained"
                    color="success"
                    size="small"
                    onClick={() => {
                      let flags = this.state.paymentProofModalFlags;
                      flags[i] = true;
                      this.setState({
                        ...this.state,
                        paymentProofModalFlags: flags,
                      });
                    }}
                  >
                    Record Payment
                  </Button>
                <PaymentProofDialog
                  open={this.state.paymentProofModalFlags[i]}
                  onClose={() => {
                    let flags = this.state.paymentProofModalFlags;
                    flags[i] = false;
                    this.setState({
                      ...this.state,
                      paymentProofModalFlags: flags,
                    });
                  }}
                  registration={reg}
                  onDocumentUpdate={handleProofOfPaymentUpload}
                  onTypeUpdate={handleTypeUpdate}
                />
              </Grid>

              </Tooltip>
              <Grid item xs={4} alignSelf="center">
                {reg?.paymentproof.length > 0 && (
                  <Typography variant="subtitle2" style={{ marginBottom: 12 }}>
                    Proof of payment uploaded{" "}
                    <Emoji symbol="✅" label="checkmark"></Emoji>
                  </Typography>
                )}
              </Grid>

              <Grid item xs={3} alignSelf="center">
                <Button
                  startIcon={<AddLinkIcon />}
                  style={{ marginBottom: 12 }}
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => {
                    this.setState({
                      ...this.state,
                      studentListModalOpen: true,
                    });
                  }}
                >
                  Link to Student
                </Button>

                <StudentListDialog
                  open={this.state.studentListModalOpen}
                  onClose={(value?: string) => {
                    if (value && value?.length > 0) {
                      this.addStudentToRegistration(value, reg.id);
                    }
                    this.setState({
                      ...this.state,
                      studentListModalOpen: false,
                    });
                  }}
                  students={this.getNotregisteredStudents(this.props.students)}
                />
              </Grid>
              {!!reg?.student_id && (
                <Grid item xs={4} alignSelf="center">
                  <Typography variant="subtitle2" style={{ marginBottom: 12 }}>
                    Linked to: {this.getRegisteredStudentName(reg.student_id)}
                    <Emoji symbol="👨‍🎓" label="student"></Emoji>
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Divider style={{ marginTop: 12, marginBottom: 24 }}></Divider>
          </React.Fragment>
        ))}
      </>
    );
  }

  addStudentToRegistration(student_id: string, registration_id: string) {
    const studentToBeAdded = this.props.students.find(
      (s) => s.id === student_id
    );
    console.log(
      "adding following student to registration: " +
        studentToBeAdded?.firstname +
        " " +
        studentToBeAdded?.lastname
    );

    const registrationToBeUpdated = this.state?.registrations?.find(
      (r) => r.id === registration_id
    );

    if (registrationToBeUpdated) {
      registrationToBeUpdated.student_id = student_id;
      this.updateRegistration(registrationToBeUpdated);
    }
    let students = this.props.guardian.students;
    console.log(this.props.guardian)
    console.log(studentToBeAdded)
    students.push(studentToBeAdded);
    this.props.onNewStudentsUpdate(students);
  }

  render() {
    //display list of registrations

    //for every individual registration
    //show small title, chip with registration status (default is pending)\
    //show amount due (invoice)
    //show button to add proof of payment and link to student
    //proof of payment uploaded to s3
    //student is also selected (note: information would need to have been entered beforehand
    //& student would need to have been linked to parent)
    //i.e. student_id is stored inside registration
    //once proof of payment is uploaded and student is added to registration,
    //status of registration changes to complete
    return (
      <>
        {this.createUI()}
        <Button
          startIcon={<AddCardIcon />}
          style={{ marginBottom: 12 }}
          variant="contained"
          color="success"
          size="small"
          onClick={this.addClick.bind(this)}
        >
          Add Registration
        </Button>
      </>
    );
  }
}

export default NewEditRegistration;
