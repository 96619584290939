import PropTypes from "prop-types";

// material-ui
import { useTheme, styled } from "@mui/material/styles";
import {
  Avatar,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

import { getAllStudents } from "../../util/api-calls";

import { useEffect, useState } from "react";

// project imports
import MainCard from "./ui-component/cards/MainCard";
import TotalIncomeCard from "./ui-component/cards/Skeleton/TotalIncomeCard";

// assets
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

// styles
const CardWrapper = styled(MainCard)(({ theme }) => ({
  overflow: "hidden",
  position: "relative",
  marginLeft: "10px",
  marginTop: "10px",
  borderRadius: "10px",
  "&:after": {
    content: '""',
    position: "absolute",
    width: 210,
    height: 210,
    background: `#FFF1C8`,
    borderRadius: "50%",
    top: -30,
    right: -180,
  },
  "&:before": {
    content: '""',
    position: "absolute",
    width: 210,
    height: 210,
    background: `#FFEDB9`,
    borderRadius: "50%",
    top: -160,
    right: -130,
  },
}));

// ==============================|| DASHBOARD - TOTAL INCOME LIGHT CARD ||============================== //

function EnrolmentPaid(updateDashboard, students) {
  function getCurrentSchoolYear() {
    const date = new Date();
    let year = date.getFullYear();

    if (date.getMonth() === 11) {
      // Month in JavaScript is 0-indexed, so 11 is December
      year += 1;
    }
    return year.toString();
  }

  const generateYearOptions = () => {
    const currentYear = getCurrentSchoolYear();
    let years = [];
    for (let year = currentYear; year >= 2023; year--) {
      years.push(year);
    }
    return years;
  };
  const [numberOfPaidInvoices, setNumberOfPaidInvoices] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const [year, setYear] = useState(getCurrentSchoolYear());

  const ids = [
    "27e5cb06-93cd-41cd-ab4e-ba36a67329e2",
    "fef5f264-480f-4c9c-b278-4b096d4e543f",
    "ecbb20aa-f9cc-417c-90d4-02bdd3ca5281",
    "e24a349b-e8c5-43ef-b18e-9256783f9539",
    "7d6ce629-9b7e-4840-a68d-8d1b9d0bed40",
    "10c69c04-ddf2-43a9-9885-a0b004441c21",
    "2825f8b8-945b-4b99-84cb-731690ad63e0",
    "ec5b8050-2db6-4659-b361-fd6eac2d4fb7",
    "6d9664f6-b4e3-42d7-9ff4-3a4b31ab3b79",
    "d231667d-a2c2-4d2d-a1b5-8c86d245d673",
    "24fd26d0-34c9-49f1-aea7-17ad2777c609",
    "adc9e5fc-83f1-465c-8137-b9bf2874e3c8",
    "61e4b68d-5e5e-4f88-8d00-7e6cc033d1be",
    "d3bdfa6b-8ebc-416b-87a2-72b33543165a",
    "e41c44ab-bfa3-4abe-ade1-5cf347677493",
    "417b3d2c-c646-4ad9-a0be-51eb361ee867",
    "451793b7-24e7-4ec0-a2ed-0814d0075cde",
    "60917780-2b2e-4771-b003-567833beb98f",
    "2ee57675-5fb1-4096-8fb3-a036e4cab373",
    "deba7ba9-755c-4ca3-b690-e26d79eb72d8",
    "e4b38075-8d31-4aa2-bb6a-0a2759a0aca8",
    "5dc44c5b-8c00-47ad-8184-092c4f3b7af4",
    "44244d11-5782-4b28-b571-c623912395c3",
    "5595556a-b586-4dcb-8c1f-22289ea197ed",
    "e93770bc-a3db-498a-850f-06fefe214462",
    "db705394-de7e-4b71-9fc1-cc2bb129e1f5",
    "4e5d5492-894f-47b6-bd4c-8af0176bdb3f",
    "a9b747d4-fd9e-41df-9def-850101fb33c5",
    "6070ea69-c1fc-4f9e-9c6a-aac1db91575a",
    "7598e885-29e9-4ee3-93b6-edc6a8b52fc7",
    "d48e1953-4638-4744-9643-fa971d2ee4ef",
    "b17db087-323c-4f79-96b8-2962b99a0a29",
    "e3f8f1b2-2140-48e0-abbf-9164e59baba0",
    "3b87d577-2ab8-4de0-bdd3-59252405d7f2",
    "ff8a876e-b278-473d-9e8f-a4441a00e5d2",
    "0b4d5f91-886d-4cff-97cd-ed0a49310d38",
    "fc908b88-90c7-4265-bd2d-976a2af6d99e",
    "68faf2bb-3d28-41c2-8992-26b2598f1072",
    "bda14f7a-139d-4f30-ab0c-f77917e13e7f",
    "05c653d7-d5bb-4cf0-8b05-832b84a726d0",
    "b2e93fb2-436b-498b-ac21-7b4cbad9976e",
    "5109e6db-f3d9-4a7a-acdb-613d9cc1dc80",
    "7a6e6303-2096-446d-b83f-0a2f1762b2bf",
    "90792a2c-ca4b-45d2-b72e-17d92af3a92d",
    "8adc6e0d-71df-4523-a73d-f000f294c20f",
    "859c6f02-ca1e-4641-8851-c7201c3f8b5a",
    "51266998-e1e7-4b85-8b13-1cada79a02ed",
    "7168e8ff-b814-425b-8214-b8da61591bec",
    "a436ffa3-bc11-4224-8510-bb581d9528cd",
    "02208ebb-b29a-4ccf-816a-5b4212d6d389",
    "a4c07d81-23ce-428f-a17c-8ba94656732b",
    "9fc8225d-d227-4074-b313-b87f356958ee",
    "2fed0927-9ea1-4571-9633-a24ddd1a3bf0",
    "a34248f8-7fa6-4fff-83bf-3b6431a090e2",
    "e8e6d73e-bad8-4ee0-8ca4-58da61de03b1",
    "aef1ac45-41f6-41d0-8fd5-fe91398391bb",
    "c4f4e748-ddee-4f2b-a107-5df0c252df3a",
    "5b762cd2-78be-43db-8824-5d16e16e6157",
    "19f33bbe-8394-4ad3-a832-d78d20abe912",
    "e58614bd-f14f-4811-a09f-ef6849bec7dd",
    "7b7e0cb7-cc17-403d-bd6b-af726938a0e1",
    "1b3509e9-a234-419e-9670-d5763f037ea5",
    "40474b1b-9479-446a-8767-06f27bf776b5",
    "87813f84-b569-47f8-b9b5-d738d2a55a53",
    "a53762f4-b09f-410d-949f-5f5c6457733c",
    "690836c5-1985-4f21-95a1-c65de74430ea",
    "6dc9db1e-befe-41f1-ade8-59c0aa0ef449",
    "b5be0dac-da6e-46b0-bb56-4184f79f690e",
    "f693413a-cd87-41c6-a44e-b9fffae379f3",
    "c5742943-8c5e-48db-904f-46366db659aa",
    "ad23260c-be73-4c4a-ada7-c04a0ccfaae5",
    "a055f43e-2779-4106-b5a2-63b8fcff6fd2",
    "65385fd6-9030-4c47-af8c-293142a169e1",
    "6659154f-2614-4eb6-9296-45983aa318fc",
    "4048507b-0303-4550-846a-6cca3590fb80",
    "90ac373d-a50d-4536-b88e-e08ff465be2d",
    "c3a37854-d682-4cfa-b1cf-365b24078b2d",
    "8639aef7-8af6-44f9-8892-213e8bccd3c7",
  ];

  let missingIds = [];

  useEffect(() => {
    setLoading(true);
    // getAllStudents().then((students) => {
    if (students.length > 0) {
      let count = 0;
      let totalAmount = 0;
      let temptotal = 0;

      let tempcount = 0;

      ids.forEach((id) => {
        let found = false;

        // Iterate through the students array
        for (let i = 0; i < students.length; i++) {
          // Iterate through the enrolments for each student
          for (let j = 0; j < students[i].enrolments.length; j++) {
            // Iterate through the payments for each enrolment
            for (
              let k = 0;
              k < students[i].enrolments[j].payments.length;
              k++
            ) {
              if (students[i].enrolments[j].payments[k].id === id) {
                found = true;
                break;
              }
            }

            if (found) break;
          }

          if (found) break;
        }

        // If the ID is not found, add it to the missingIds array
        if (!found) {
          missingIds.push(id);
        }
      });

      //console.log("IDs not found in students payments:", missingIds);

      for (let i = 0; i < students.length; i++) {
        if (students[i].enrolments.length > 0) {
          for (let j = 0; j < students[i].enrolments.length; j++) {
            const createdAtDate = new Date(students[i].enrolments[j].createdAt);
            const createdAtYear = createdAtDate.getFullYear();
            const createdAtMonth = createdAtDate.getMonth(); // January is 0, December is 11

            // if ((createdAtYear === 2023 && createdAtMonth === 11) || createdAtYear === 2024) {
            //     if (students[i].enrolments[j].year === "2024") {
            //         console.log(students[i].enrolments[j].createdAt, students[i].enrolments[j].year);
            //         tempcount++;
            //     }
            // }

            if (students[i].enrolments[j].year === year) {
              if (students[i].enrolments[j].status == "Year paid") {
                count++;
              }
              for (
                let k = 0;
                k < students[i].enrolments[j].payments.length;
                k++
              ) {
                //console.log(students[i].enrolments[j].payments[k].id);

                temptotal += parseInt(
                  students[i].enrolments[j].payments[k].amount
                );

                totalAmount += parseInt(
                  students[i].enrolments[j].payments[k].amount
                );
              }
            }
          }
        }
      }
      // console.log(tempcount);
      // console.log(temptotal);
      setNumberOfPaidInvoices(count);
      setTotalAmount(totalAmount);
      setLoading(false);
      // });
    }
  }, [updateDashboard, students, year]);

  const yearOptions = generateYearOptions();
  return (
    <>
      {loading ? (
        <TotalIncomeCard />
      ) : (
        <CardWrapper border={false} content={false}>
          <Box sx={{ p: 1.525 }}>
            <Grid item sx={{ mb: 0.5 }}>
              <Grid container alignItems="center">
                <Grid item xs={6}>
                  <List sx={{ py: 0 }}>
                    <ListItem alignItems="center" disableGutters sx={{ py: 0 }}>
                      {/* <div className="col"> */}
                      <ListItemAvatar>
                        <Avatar
                          variant="rounded"
                          sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.largeAvatar,
                            backgroundColor: `#FFEDB9`,
                            color: "#FFC107",
                          }}
                        >
                          <AccountBalanceIcon fontSize="inherit" />
                        </Avatar>
                        <FormControl
                          size="small"
                          style={{
                            marginTop: 10,
                            display: "flex",
                            minWidth: 80,
                            flex: 1,
                            marginRight: 9,
                            maxWidth: 80,
                            padding: 1,
                            height: 30,
                            fontSize: 10,
                          }}
                        >
                          <InputLabel
                            style={{
                              fontSize: 14,
                            }}
                            shrink={true}
                            id="gender-label"
                          >
                            Year
                          </InputLabel>
                          <Select
                            sx={{
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#FFBF00", // Set border color
                              },
                              "&:hover .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#FFBF00", // Set border color on hover
                              },
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#FFBF00", // Set border color when focused
                                },
                            }}
                            name="grade"
                            labelId="grade-label"
                            id="grade"
                            defaultValue={"2024"}
                            onChange={(year) => {
                              setYear(year.target.value);
                            }}
                            label="Grade"
                          >
                            {yearOptions.map((yearOption) => (
                              <MenuItem
                                style={{
                                  fonstSize: 10,
                                  padding: 1,
                                }}
                                key={yearOption}
                                value={String(yearOption)}
                              >
                                {yearOption}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </ListItemAvatar>
                      <div
                        className="col"
                        style={{
                          marginLeft: 5,
                        }}
                      >
                        <ListItemText
                          sx={{
                            py: 0,
                            mt: 0.45,
                            mb: 0.45,
                          }}
                          primary={
                            <Typography variant="h4">
                              {numberOfPaidInvoices}
                            </Typography>
                          }
                          secondary={
                            <Typography
                              variant="subtitle2"
                              sx={{ mt: 0.25, minWidth: 200 }}
                            >
                              Enrolments Paid
                            </Typography>
                          }
                        />
                      </div>
                    </ListItem>
                  </List>
                </Grid>
                <Grid item xs={6}>
                  <List sx={{ py: 0 }}>
                    <ListItem alignItems="center" disableGutters sx={{ py: 0 }}>
                      <ListItemAvatar></ListItemAvatar>
                      <ListItemText
                        sx={{
                          py: 0,
                          mt: 1,
                          mb: 0.45,
                          ml: -3,
                        }}
                        primary={
                          <Typography variant="h5">
                            K
                            {totalAmount
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
                          </Typography>
                        }
                        secondary={
                          <Typography
                            variant="subtitle2"
                            sx={{ mt: 0, paddingTop: 0.9 }}
                          >
                            Total Paid
                          </Typography>
                        }
                      />
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </CardWrapper>
      )}
    </>
  );
}

export default EnrolmentPaid;
