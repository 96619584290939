import { Enrolment, Fees, Student } from "./data-models";

export const enrolmentStatuses = {
    pending: "Pending",
    partial: "Partially paid",
    term: "Term paid",
    year: "Year paid"
};

export const statusColors = {
    pending: "error",
    partial: "warning",
    term: "primary",
    year: "success"
};

export const statusToColor = {
    [enrolmentStatuses.partial]: statusColors.partial,
    [enrolmentStatuses.term]: statusColors.term,
    [enrolmentStatuses.year]: statusColors.year,
    [enrolmentStatuses.pending]: statusColors.pending,
}

export const gradeToFee = {
    ["Grade 1"]: "grade1",
    ["Grade 2"]: "grade2",
    ["Grade 3"]: "grade3",
    ["Grade 4"]: "grade4",
    ["Grade 5"]: "grade5",
    ["Grade 6"]: "grade6",
    ["Grade 7"]: "grade7",
    ["Grade 8"]: "grade8",
    ["Grade 9"]: "grade9",
}

export const getEnrolmentStatus = (paidSum: number, fullSum: number) => {
    if (paidSum <= 0) {
        return enrolmentStatuses.pending;
    } else if (paidSum <= fullSum / 2.0) {
        return enrolmentStatuses.partial;
    } else if (paidSum < fullSum) {
        return enrolmentStatuses.term;
    } else {
        return enrolmentStatuses.year;
    }
}

export const getAmountOwing = (): number => {
    return 12222;
}

export const getFeeAmountByGradeStudentGrade = (student: Student, fees: Fees): number => {
    let result = fees.grade1;

    if (student?.grade?.length > 0) {
        const grade = student.grade;
        switch (grade) {
            case "Baby Class":
                result = fees.babyclass;
                break;
            case "Reception":
                result = fees.reception;
                break;
            case "Grade 1":
                result = fees.grade1;
                break;
            case "Grade 2":
                result = fees.grade2;
                break;
            case "Grade 3":
                result = fees.grade3;
                break;
            case "Grade 4":
                result = fees.grade4;
                break;
            case "Grade 5":
                result = fees.grade5;
                break;
            case "Grade 6":
                result = fees.grade6;
                break;
            case "Grade 7":
                result = fees.grade7;
                break;
            case "Grade 8":
                result = fees.grade8;
                break;
            case "Grade 9":
                result = fees.grade8;
                break;
            case "Grade 10":
                result = fees.grade10;
                break;
            case "Grade 11":
                result = fees.grade11;
                break;
            case "Grade 12":
                result = fees.grade12;
                break;
            default:
                result = fees.grade1;
                break;
        }
    }

    return result;
}

export const updateEnrolmentFees = (enrolments: Enrolment[], student: Student, fees: Fees): Enrolment[] => {
    let newEnrolments = [...enrolments];
    newEnrolments.forEach(e => e.amountOwing = getFeeAmountByGradeStudentGrade(student, fees));
    return newEnrolments;
}

export type colorType = 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';