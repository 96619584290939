import {
  Button,
  Dialog,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@mui/material";
import React from "react";
import DownloadIcon from "@mui/icons-material/Download";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import RegistrationInvoice from "./pdfgenerator/registration-invoice";
import Receipt from "./pdfgenerator/gen-receipt";
import { InvoiceData, ReceiptData } from "./pdfgenerator/invoice-data";

class ReceiptModal extends React.Component<
  { open: boolean; onClose: () => void; receiptData: ReceiptData },
  { receiptData: ReceiptData }
> {
  constructor(props: any) {
    super(props);
    this.state = { receiptData: this.props.receiptData };
  }

  render() {

    console.log("DATA", this.props.receiptData)
    console.log("DATA", this.state.receiptData)
    return (
      <Dialog open={this.props.open} onClose={this.props.onClose}>
        <>
          <PDFViewer style={{ height: 600, width: 425 }}>
            <Receipt invoice={this.props.receiptData} />
          </PDFViewer>
          <PDFDownloadLink
            document={<Receipt invoice={this.props.receiptData} />}
            fileName="registration-invoice.pdf"
            style={{ maxWidth: 172, display: "flex", alignSelf: "center" }}
          >
            {({ blob, url, loading, error }) =>
              loading ? (
                "Loading document..."
              ) : (
                <Button
                  startIcon={<DownloadIcon />}
                  variant="contained"
                  color="success"
                  size="small"
                > 
                  Download Receipt
                </Button>
              )
            }
          </PDFDownloadLink>
        </>
      </Dialog>
    );
  }
}

export default ReceiptModal;
