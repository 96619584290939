import React, { useEffect, useState } from "react";
import DashboardIcon from "@mui/icons-material/Dashboard";
import BadgeIcon from "@mui/icons-material/Badge";
import SchoolIcon from "@mui/icons-material/School";
import SettingsIcon from "@mui/icons-material/Settings";
import FamilyRestroomIcon from "@mui/icons-material/FamilyRestroom";
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import { useNavigate } from "react-router";
import PermissionsGate from "../../util/perminssion-gate";
import { ENHANCEMENTS, PERMISSIONS, ROLES, SCOPES } from "../../util/permission-map";

import { Auth } from "aws-amplify";
function Sidebar(userName) {
  const [permissions, setPermissions] = useState([]);
  useEffect(() => {

    getRole().then(({ baseRole, enhancements }) => {
      const basePermissions = PERMISSIONS[baseRole] || [];
      const enhancementPermissions = enhancements.length > 0 ? enhancements : [];
      setPermissions([...new Set([...basePermissions, ...enhancementPermissions])]);
    });


  }, [userName]);

  async function getRole() {
    let baseRole = ROLES.NoAuth;
    let enhancements = [];

    try {
      const user = await Auth.currentAuthenticatedUser();
      const groups = user.signInUserSession.idToken.payload["cognito:groups"] || [];
      // Determine base role
      if (groups.includes("Admin")) {
        baseRole = ROLES.admin;
      } else if (groups.includes("SchoolAdmin")) {
        baseRole = ROLES.schoolAdmin;
      } // Continue for other base roles

      // Check for enhancements
      enhancements = groups
        .filter(group => Object.keys(ENHANCEMENTS).includes(group))
        .map(group => ENHANCEMENTS[group])
        .flat();

    } catch (err) {
      console.error(err);
      // Handle error or set defaults
    }

    return { baseRole, enhancements };
  }

  const navigate = useNavigate();
  return (
    <React.Fragment>
      <div className="sidebar ">
        <ul className="sidebarlist">
          {/* <PermissionsGate scopes={[SCOPES.canEdit]} from={"dede"}> */}
          {permissions.includes("can-edit") && (
            <li
              key={"Dashboard"}
              id={
                window.location.pathname === "/dashboard" ? "active" : ""
              }
              className="row"
              onClick={() => {
                navigate("/dashboard");
              }}
            >
              <div id="icon">
                <DashboardIcon fontSize="small" />
              </div>
              <div id="title">Dashboard</div>
            </li>
          )}

          {/* </PermissionsGate> */}
          {/* <PermissionsGate scopes={[SCOPES.canEdit]}> */}
          {permissions.includes("can-edit") && (
            <li
              key={"Employees"}
              id={window.location.pathname === "/employees" ? "active" : ""}
              className="row"
              onClick={() => {
                navigate("/employees");
              }}
            >
              <div id="icon">
                <BadgeIcon fontSize="small" />
              </div>
              <div id="title">Employees</div>
            </li>
          )}
          {/* </PermissionsGate>
           <PermissionsGate scopes={[SCOPES.canView]}> */}
          {permissions.includes("can-view") && (
            <li
              key={"Students"}
              id={window.location.pathname === "/students" ? "active" : ""}
              className="row"
              onClick={() => {
                navigate("/students");
              }}
            >
              <div id="icon">
                <SchoolIcon fontSize="small" />
              </div>
              <div id="title">Students</div>
            </li>
          )}
          {/* </PermissionsGate>
           <PermissionsGate scopes={[SCOPES.canView]}> */}
          {permissions.includes("can-view") && (
            <li
              key={"Parents"}
              id={window.location.pathname === "/parents" ? "active" : ""}
              className="row"
              onClick={() => {
                navigate("/parents");
              }}
            >
              <div id="icon">
                <FamilyRestroomIcon fontSize="small" />
              </div>
              <div id="title">Parents</div>
            </li>
          )}
          {/* </PermissionsGate>
           <PermissionsGate scopes={[SCOPES.canView]}> */}
          {permissions.includes("can-view") && (
            <li
              key={"Payroll"}
              id={window.location.pathname === "/payroll" ? "active" : ""}
              className="row"
              onClick={() => {
                navigate("/payroll");
              }}
            >
              <div id="icon">
                <PointOfSaleIcon fontSize="small" />
              </div>
              <div id="title">Payroll</div>
            </li>
          )}
          {/* </PermissionsGate> */}
          {/* <PermissionsGate scopes={[SCOPES.canChangePassword]}>
            <li
              key={"Confirm Password"}
              id={
                window.location.pathname === "/confirmpassword" ? "active" : ""
              }
              className="row"
              onClick={() => {
                navigate("/confirmpassword");
              }}
            >
              <div id="icon">
                <WarningIcon fontSize="small" />
              </div>
              <div id="title">Confirm Password</div>
            </li>
          </PermissionsGate> */}
          {/* <PermissionsGate scopes={[SCOPES.canDelete]}> */}
          {permissions.includes("can-delete") && (
            <li
              key={"Settings"}
              id={window.location.pathname === "/settings" ? "active" : ""}
              className="row"
              onClick={() => {
                navigate("/settings");
              }}
            >
              <div id="icon">
                <SettingsIcon fontSize="small" />
              </div>
              <div id="title">Settings</div>
            </li>
          )}
          {/* </PermissionsGate> */}
        </ul>
      </div>
    </React.Fragment >
  );
};

export default Sidebar;
