import PropTypes from "prop-types";
import { useEffect, useState } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Button,
  CardActions,
  CardContent,
  Divider,
  Grid,
  Typography,
  styled,
} from "@mui/material";

import Calendar from "react-calendar";
import SkeletonUpdate from "./ui-component/cards/Skeleton/PopularCard";

// project imports
import MainCard from "./ui-component/cards/MainCard";

// assets
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import { getAllStudents } from "../../util/api-calls";

// ==============================|| DASHBOARD DEFAULT - POPULAR CARD ||============================== //

const CardWrapper = styled(MainCard)(({ theme }) => ({
  overflow: "hidden",
  position: "relative",
  marginLeft: "10px",
  marginTop: "10px",
  borderRadius: "10px",
}));

function StudentUpdates(updateDashboard, students) {
  const theme = useTheme();

  const [loading, setLoading] = useState(true);

  const [sortedStudents, setSortedStudents] = useState({});

  useEffect(() => {

    setLoading(true);
    // getAllStudents().then((students) => {
      if(students.length > 0){
      const sortedUpdatedAt = students
        .slice()
        .sort(
          (a, b) =>
          new Date(b.updatedAt) -
          new Date(a.updatedAt)
          //need to find newest createdAt date in enrolments
           // new Date(b.enrolments?.[b.enrolments.length - 1]?.createdAt) -
           // new Date(a.enrolments?.[a.enrolments.length - 1]?.createdAt)
        );
      setSortedStudents(sortedUpdatedAt);
      setLoading(false);
    // });
  }
  }, [students]);

  const parseDate = (employee) => {
    let date = new Date(employee?.updatedAt);
    return date.toLocaleString("fr-CH", {
      timeZone: "Africa/Johannesburg",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
      hour12: false,
    });
    //return (date.getDate() + "/" + ("0" + (date.getMonth() + 1)).slice(-2) + "/" + date.getFullYear() + " " + ("0" + (date.getHours() + 1)).slice(-2) + ":" + ("0" + (date.getMinutes() + 1)).slice(-2));
  };

  return (
    <>
      {loading ? (
        <SkeletonUpdate />
      ) : (
        <CardWrapper border={false} content={false}>
          <CardContent>
            <Grid>
              <Grid item>
                <Grid container justifyContent="space-between">
                  <Grid item xs={0} marginLeft={1}>
                    <Avatar
                      variant="rounded"
                      sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.largeAvatar,
                        backgroundColor: "#1565C0",
                        color: "#fff",
                        height: 30,
                        width: 30,
                      }}
                    >
                      <NewspaperIcon />
                    </Avatar>
                  </Grid>
                  <Grid item xs={9} marginTop={0.1}>
                    {/* <Typography variant="h6">Recent Enrolments</Typography> */}
                    <Typography variant="h6">Student Updates</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Divider sx={{ my: 1.5 }} />
              <Grid item xs={12}>
                {/* <Grid container direction="column">
              <Grid item alignItems="center">
                <Calendar onChange={onChange} value={value} />
              </Grid>
            </Grid> */}
                {/* <Divider sx={{ my: 1.5 }} /> */}
                <Grid container direction="column">
                  <Grid item>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Grid item>
                        <Typography
                          variant="subtitle1"
                          color="inherit"
                          fontSize="16px"
                        >
                          {sortedStudents[0]?.firstname}{" "}
                          {sortedStudents[0]?.lastname}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Grid item>
                            <Typography
                              variant="subtitle1"
                              color="inherit"
                              fontSize="16px"
                            >
                              {parseDate(sortedStudents[0])}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Grid item>
                        <Typography
                          variant="subtitle2"
                          fontSize="13px"
                          sx={{ color: "#1565C0" }}
                        >
                          #{sortedStudents[0]?.studentid}                         
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Grid item>
                            <Typography
                              variant="subtitle1"
                              fontSize="13px"
                              color="inherit"
                            >
                              By {sortedStudents[0]?.updatedBy}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Divider sx={{ my: 1.5 }} />
                <Grid container direction="column">
                  <Grid item>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Grid item>
                        <Typography
                          variant="subtitle1"
                          color="inherit"
                          fontSize="16px"
                        >
                          {sortedStudents[1]?.firstname}{" "}
                          {sortedStudents[1]?.lastname}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Grid item>
                            <Typography
                              variant="subtitle1"
                              color="inherit"
                              fontSize="16px"
                            >
                              {parseDate(sortedStudents[1])}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Grid item>
                        <Typography
                          variant="subtitle2"
                          fontSize="13px"
                          sx={{ color: "#1565C0" }}
                        >
                           #{sortedStudents[1]?.studentid}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Grid item>
                            <Typography
                              variant="subtitle1"
                              fontSize="13px"
                              color="inherit"
                            >
                              By {sortedStudents[1]?.updatedBy}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Divider sx={{ my: 1.5 }} />
                <Grid container direction="column">
                  <Grid item>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Grid item>
                        <Typography
                          variant="subtitle1"
                          color="inherit"
                          fontSize="16px"
                        >
                          {sortedStudents[2]?.firstname}{" "}
                          {sortedStudents[2]?.lastname}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Grid item>
                            <Typography
                              variant="subtitle1"
                              color="inherit"
                              fontSize="16px"
                            >
                              {parseDate(sortedStudents[2])}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Grid item>
                        <Typography
                          variant="subtitle2"
                          fontSize="13px"
                          sx={{ color: "#1565C0" }}
                        >
                          #{sortedStudents[2]?.studentid}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Grid item>
                            <Typography
                              variant="subtitle1"
                              color="inherit"
                              fontSize="13px"
                            >
                          By {sortedStudents[2]?.updatedBy}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {/* <Divider sx={{ my: 1.5 }} /> */}
              </Grid>
            </Grid>
          </CardContent>
          {/* <CardActions sx={{ p: 1.25, pt: 0, justifyContent: "center" }}>
        <Button size="small" disableElevation>
          View All
          <ChevronRightOutlinedIcon />
        </Button>
      </CardActions> */}
        </CardWrapper>
      )}
    </>
  );
}

export default StudentUpdates;
