import React from 'react';
import { Page, Document, Image, StyleSheet } from '@react-pdf/renderer';
import logo from "../../../assets/img/images/ebenezerlogo.png"
import InvoiceTitle from './invoice-title';
import InvoiceNo from './invoice-number';
import BillTo from './bill-to';
import { InvoiceData } from './invoice-data';
import InvoiceItemsTable from './invoice-items-table';
import ReceiptItemsTable from './receipt-items-table';


const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontSize: 11,
        paddingTop: 30,
        paddingLeft:60,
        paddingRight:60,
        lineHeight: 1.5,
        flexDirection: 'column',
    }, 
    logo: {
        width: 74,
        height: 66,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    leftColumn: {
        flexDirection: 'column',
        width: 170,
    }
  });
  
  const Receipt = ({invoice} : {invoice: InvoiceData}) => (
            <Document>
                <Page size="A4" style={styles.page}>
                    <Image style={styles.logo} src={logo} />
                    <InvoiceTitle title='Receipt'/>
                    <InvoiceNo invoice={invoice}/>
                    <BillTo invoice={invoice}/>
                    <InvoiceItemsTable invoice={invoice} />
                    <ReceiptItemsTable payments={invoice} />
                </Page>
            </Document>
        );
  
  export default Receipt