import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';
import { InvoiceItem } from '../invoice-data';

const borderColor = '#90e5fc'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: '#bff0fd',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        fontSize: 12,
        fontStyle: 'bold',
    },
    description: {
        width: '65%',
        textAlign: 'right',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingRight: 8,
    },
    total: {
        width: '35%',
        textAlign: 'right',
        paddingRight: 8,
    },
  });
  
const InvoiceTableFooter = ({items}:{items: InvoiceItem[]}) => {

    // const oo = items.map(item => {
    //     console.log(item)
    // })

    const total = items?.map(item => (
        (item.desc.includes("Registration")? item.rate:(item.qty * item.rate) - (item.discount? ((item.qty * item.rate) * (parseInt(item.discount)) / 100): 0) )))
        .reduce((accumulator: number, currentValue: number) => accumulator + currentValue , 0)
    return(    
        <View style={styles.row}>
            <Text style={styles.description}>TOTAL</Text>
            <Text style={styles.total}>K{ Number.parseFloat(total?.toString()).toFixed(2)}</Text>
        </View>
    )
};
  
  export default InvoiceTableFooter