import React from 'react';
import {View, StyleSheet } from '@react-pdf/renderer';
import InvoiceTableHeader from './invoice-table-components/invoice-table-header';
import InvoiceTableRow from './invoice-table-components/invoice-table-row';
import { InvoiceData } from './invoice-data';
import InvoiceTableBlankSpace from './invoice-table-components/invoice-table-blank-space';
import InvoiceTableFooter from './invoice-table-components/invoice-table-footer';
import ReceiptTableHeader from './invoice-table-components/receipt-table-header';
import ReceiptTableRow from './invoice-table-components/receipt-table-row';
import ReceiptTableFooter from './invoice-table-components/receipt-table-footer';

const tableRowsCount = 4;

const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 24,
        borderWidth: 1,
        borderColor: '#bff0fd',
    },
});

  function ReceiptItemsTable({payments} : any){
    console.log(payments?.payments)
    return (
        <View style={styles.tableContainer}>
             <ReceiptTableHeader />
             <ReceiptTableRow payments={payments?.payments} />
             <ReceiptTableFooter payments={payments} />
        </View>
    )
  }
    
    // <View style={styles.tableContainer}>
        // <ReceiptTableHeader />
        {/* <InvoiceTableRow items={invoice.items} />
        <InvoiceTableBlankSpace rowsCount={ tableRowsCount - invoice.items.length} />
        <InvoiceTableFooter items={invoice.items} /> */}
    {/* </View> */}
//   );
  
  export default ReceiptItemsTable