import { Student } from "./data-models";

export const SERVER_API_URL= "https://school.kozosms.com/api/api" // 


export function getAllEmployees(){
    return fetch(SERVER_API_URL+"/employees")
      .then(data => data.json())
  }

  export function deleteEmployee(id: number|string){
    return fetch(SERVER_API_URL+"/employees/"+ id, { method: 'DELETE' }).then(data=>data.json());
  }

  export function getAllStudents(){
    return fetch(SERVER_API_URL+"/students")
      .then(data => data.json())
  }

  export function getAllParentAccessLogs(){
    return fetch(SERVER_API_URL+"/parentAccesslog")
      .then(data => data.json())
  }

  export function deleteStudent(id: number|string){
    return fetch(SERVER_API_URL+"/students/"+ id, { method: 'DELETE' }).then(data=>data.json());
  }

  export function getAllGuardians(){
    return fetch(SERVER_API_URL+"/guardians")
      .then(data => data.json())
  }

  export function deleteGuardian(id: number|string){
    return fetch(SERVER_API_URL+"/guardians/"+ id, { method: 'DELETE' }).then(data=>data.json());
  }

  export function getFees(){
    return fetch(SERVER_API_URL+"/fees/c892344d-4d67-46ed-8de8-c2c1415bd929")
      .then(data => data.json())
  }

  export function getSchools(){
    return fetch(SERVER_API_URL+"/clients")
      .then(data => data.json())
  }

  export function getServiceProviders(){
    return fetch(SERVER_API_URL+"/serviceprovider")
      .then(data => data.json())
  }

  export function getDeductionTypes(){
    return fetch(SERVER_API_URL+"/deductiontype")
      .then(data => data.json())
  }

  export function getAllowanceTypes(){
    return fetch(SERVER_API_URL+"/allowancetype")
      .then(data => data.json())
  }
