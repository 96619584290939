import React, { useEffect, useState } from "react";
// material-ui
import { Grid, Typography } from "@mui/material";

import TotalPaidRegistrationCard from "./TotalPaidRegistrationCard";
import TotalRegistrationCard from "./TotalRegistrationCard";
import StudentUpdates from "./StudentUpdates";
import EmployeeCard from "./EmployeeCard";
import StudentCard from "./StudentCard";
import EnrolmentPaid from "./EnrolmentsPaid";
import Enrolments from "./Enrolments";
import EmployeeUpdate from "./EmployeeUpdate";
import WorkAnniversary from "./WorkAnniversary";
import UpcomingBirthdays from "./UpcomingBirthdays";
import PermissionsGate from "../../util/perminssion-gate";
import { SCOPES } from "../../util/permission-map";
import { Guardian, Student } from "../../util/data-models";
import {
  getAllEmployees,
  getAllGuardians,
  getAllStudents,
} from "../../util/api-calls";
import { Employee } from "../contactlist/newcontactlist";

function Dashboard(userName: string, studentsList, employeesList, parentsList) {
  const [updateDashboard, setUpdateDashboard] = useState("");
  const [students, setStudents] = useState({} as Student);
  const [employees, setEmployeess] = useState({} as Employee);
  const [parents, setParents] = useState({} as Guardian);

  // useEffect(() => {
  //   console.log(studentsList)
  //   // getAllStudents().then((students) => {
  //      setStudents(studentsList)
  //   // });
  //   // getAllEmployees().then((employees) => {
  //      setEmployeess(employeesList)
  //   // });
  //   // getAllGuardians().then((parents) => {
  //      setParents(parentsList)
  //   // });
  // }, [userName, studentsList, employeesList, parentsList]);

  useEffect(() => {
    setStudents(studentsList);
  }, [studentsList]);

  useEffect(() => {
    setEmployeess(employeesList);
  }, [employeesList]);

  useEffect(() => {
    setParents(parentsList);
  }, [parentsList]);

  return (
    <PermissionsGate scopes={[SCOPES.canEdit]}>
      <Grid>
        <section className="left contact-search">
          <div className="bodybackground">
            <Grid container>
              <Grid item xs={12}>
                <Grid
                  item
                  sx={{
                    marginLeft: "10px",
                    marginTop: "5px",
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "#32373C",
                      fontSize: "37px",
                    }}
                  ></Typography>
                </Grid>
                <Grid container>
                  <Grid item lg={4} md={6} sm={6} xs={12}>
                    {EmployeeCard(updateDashboard, employees)}
                  </Grid>
                  <Grid item lg={4} md={6} sm={6} xs={12}>
                    {StudentCard(updateDashboard, students)}
                  </Grid>
                  <PermissionsGate scopes={[SCOPES.canViewRevenue]}>
                    <Grid item lg={4} md={12} sm={12} xs={12}>
                      <Grid container>
                        <Grid item sm={6} xs={12} md={6} lg={12}>
                          {Enrolments(updateDashboard, students)}
                        </Grid>
                        <Grid item sm={6} xs={12} md={6} lg={12}>
                          {EnrolmentPaid(updateDashboard, students)}
                        </Grid>
                      </Grid>
                    </Grid>
                  </PermissionsGate>
                </Grid>

                <Grid container>
                  <Grid item lg={4} md={6} sm={6} xs={12}>
                    {EmployeeUpdate(updateDashboard, employees)}
                  </Grid>
                  <Grid item lg={4} md={6} sm={6} xs={12}>
                    {StudentUpdates(updateDashboard, students)}
                  </Grid>
                  <PermissionsGate scopes={[SCOPES.canViewRevenue]}>
                    <Grid item lg={4} md={12} sm={12} xs={12}>
                      <Grid container>
                        <Grid item sm={6} xs={12} md={6} lg={12}>
                          {TotalRegistrationCard(updateDashboard, parents)}
                        </Grid>
                        <Grid item sm={6} xs={12} md={6} lg={12}>
                          {TotalPaidRegistrationCard(updateDashboard, parents)}
                        </Grid>
                      </Grid>
                    </Grid>
                  </PermissionsGate>
                </Grid>

                <Grid container>
                  <Grid item lg={4} md={6} sm={6} xs={12}>
                    {WorkAnniversary(updateDashboard, employees)}
                  </Grid>
                  <Grid item lg={4} md={6} sm={6} xs={12}>
                    {UpcomingBirthdays(updateDashboard, students)}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </section>
      </Grid>
    </PermissionsGate>
  );
}

export default Dashboard;
