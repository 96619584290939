import React from "react"

export default function Table({ total, description, quantity, price, amount, discount }) {
  return (
    <>
      <table width="100%" className="mb-10">
        <thead>
          <tr className="bg-gray-100 p-1">
            <td className="font-bold">Description</td>
            <td className="font-bold">Quantity</td>
            <td className="font-bold">Price</td>
            <td className="font-bold">Discount</td>
            <td className="font-bold">Amount</td>

          </tr>
        </thead>
        
          <React.Fragment >
            <tbody>
              <tr className="h-10">
                <td>{description}</td>
                <td>{quantity}</td>
                <td>{price}</td>
                <td>{discount}</td>
                <td>{amount}</td>
              </tr>
            </tbody>
          </React.Fragment>
        
      </table>

      <div>
        <h2 className="flex items-end justify-end text-gray-800 text-4xl font-bold">
          K {total.toLocaleString()}
        </h2>
      </div>
    </>
  )
}
