import PropTypes from "prop-types";

// material-ui
import { useTheme, styled } from "@mui/material/styles";
import { Avatar, Box, Grid, Typography } from "@mui/material";

import { useEffect, useState } from "react";

// third-party
import Chart from "react-apexcharts";

// project imports
import MainCard from "./ui-component/cards/MainCard";
import SkeletonTotalOrderCard from "./ui-component/cards/Skeleton/EarningCard";

import { getAllStudents } from "../../util/api-calls";

import SchoolIcon from "@mui/icons-material/School";

// assets
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

const CardWrapper = styled(MainCard)(({ theme }) => ({
  backgroundColor: "#1E88E5",
  color: "#fff",
  overflow: "hidden",
  position: "relative",
  borderRadius: "10px",
  marginLeft: "10px",
  marginTop: "10px",
  "&>div": {
    position: "relative",
    zIndex: 5,
  },
  "&:after": {
    content: '""',
    position: "absolute",
    width: 210,
    height: 210,
    backgroundColor: "#1565C0",
    borderRadius: "50%",
    zIndex: 1,
    top: -85,
    right: -95,
    [theme.breakpoints.down("sm")]: {
      top: -105,
      right: -140,
    },
  },
  "&:before": {
    content: '""',
    position: "absolute",
    zIndex: 1,
    width: 210,
    height: 210,
    backgroundColor: "#1569BB",
    borderRadius: "50%",
    top: -125,
    right: -15,
    opacity: 0.5,
    [theme.breakpoints.down("sm")]: {
      top: -155,
      right: -70,
    },
  },
}));

// ==============================|| DASHBOARD - TOTAL ORDER LINE CHART CARD ||============================== //

function StudentCard(updateDashboard, students) {
  const [studentNumber, setStudentNumbers] = useState(0);
  const [chart, setChartData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    // getAllStudents().then((students) => {
      if(students.length > 0){
      let studentCount = 0;
     

      let date0 = addMonths(new Date(), -11);
      let date1 = addMonths(new Date(), -10);
      let date2 = addMonths(new Date(), -9);
      let date3 = addMonths(new Date(), -8);
      let date4 = addMonths(new Date(), -7);
      let date5 = addMonths(new Date(), -6);
      let date6 = addMonths(new Date(), -5);
      let date7 = addMonths(new Date(), -4);
      let date8 = addMonths(new Date(), -3);
      let date9 = addMonths(new Date(), -2);
      let date10 = addMonths(new Date(), -1);
      let date11 = addMonths(new Date(), 0);

      let chart = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

      for (var i = 0; i < students.length; i++) {
        if (students[i].grade != "Alumni" && students[i].transferredout != "Yes" && students[i].droppedout != "Yes") {
          studentCount++;

          var createdStudentDate = students[i].createdAt.slice(0, 10);
          if (dateFormat(date0) > createdStudentDate) {
            chart[0] += 1;
            chart[1] += 1;
            chart[2] += 1;
            chart[3] += 1;
            chart[4] += 1;
            chart[5] += 1;
            chart[6] += 1;
            chart[7] += 1;
            chart[8] += 1;
            chart[9] += 1;
            chart[10] += 1;
          } else if (dateFormat(date1) > createdStudentDate) {
            chart[1] += 1;
            chart[2] += 1;
            chart[3] += 1;
            chart[4] += 1;
            chart[5] += 1;
            chart[6] += 1;
            chart[7] += 1;
            chart[8] += 1;
            chart[9] += 1;
            chart[10] += 1;
          } else if (dateFormat(date2) > createdStudentDate) {
            chart[2] += 1;
            chart[3] += 1;
            chart[4] += 1;
            chart[5] += 1;
            chart[6] += 1;
            chart[7] += 1;
            chart[8] += 1;
            chart[9] += 1;
            chart[10] += 1;
          } else if (dateFormat(date3) > createdStudentDate) {
            chart[3] += 1;
            chart[4] += 1;
            chart[5] += 1;
            chart[6] += 1;
            chart[7] += 1;
            chart[8] += 1;
            chart[9] += 1;
            chart[10] += 1;
          } else if (dateFormat(date4) > createdStudentDate) {
            chart[4] += 1;
            chart[5] += 1;
            chart[6] += 1;
            chart[7] += 1;
            chart[8] += 1;
            chart[9] += 1;
            chart[10] += 1;
          } else if (dateFormat(date5) > createdStudentDate) {
            chart[5] += 1;
            chart[6] += 1;
            chart[7] += 1;
            chart[8] += 1;
            chart[9] += 1;
            chart[10] += 1;
          } else if (dateFormat(date6) > createdStudentDate) {
            chart[6] += 1;
            chart[7] += 1;
            chart[8] += 1;
            chart[9] += 1;
            chart[10] += 1;
          } else if (dateFormat(date7) > createdStudentDate) {
            chart[7] += 1;
            chart[8] += 1;
            chart[9] += 1;
            chart[10] += 1;
          } else if (dateFormat(date8) > createdStudentDate) {
            chart[8] += 1;
            chart[9] += 1;
            chart[10] += 1;
          } else if (dateFormat(date9) > createdStudentDate) {
            chart[9] += 1;
            chart[10] += 1;
          } else if (dateFormat(date10) > createdStudentDate) {
            chart[10] += 1;
          }
        }
      }
      setStudentNumbers(studentCount);
      chart[11] = studentCount;
      setChartData(chart);
      setLoading(false);
    // });
      }
  }, [updateDashboard, students]);

  const getDaysInMonth = (year, month) => new Date(year, month, 0).getDate();

  const addMonths = (input, months) => {
    const date = new Date(input);
    date.setDate(1);
    date.setMonth(date.getMonth() + months);
    date.setDate(
      Math.min(
        input.getDate(),
        getDaysInMonth(date.getFullYear(), date.getMonth() + 1)
      )
    );
    return date;
  };

  const dateFormat = (date) => {
    return (
      date.getFullYear() +
      "-" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + (date.getDate() + 1)).slice(-2)
    );
  };

  const theme = useTheme();

  const [timeValue, setTimeValue] = useState(false);
  const handleChangeTime = (event, newValue) => {
    setTimeValue(newValue);
  };

  const chartData = {
    type: "line",
    height: 90,
    options: {
      chart: {
        sparkline: {
          enabled: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#fff"],
      fill: {
        type: "solid",
        opacity: 1,
      },
      stroke: {
        curve: "smooth",
        width: 3,
      },
      yaxis: {
        min: 0,
        max: studentNumber * 1.1,
      },
      tooltip: {
        theme: "dark",
        fixed: {
          enabled: false,
        },
        x: {
          show: false,
        },
        y: {
          title: "Total Order",
        },
        marker: {
          show: false,
        },
      },
    },
    series: [
      {
        name: "",
        data: chart,
      },
    ],
  };

  const cardClick = () => {
    window.location.href = "/students";
  };

  return (
    <>
      {loading ? (
        <SkeletonTotalOrderCard />
      ) : (
        <CardWrapper border={false} content={false}>
          <Box sx={{ p: 2.25 }}>
            <Grid container direction="column">
              <Grid item>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Avatar
                      variant="rounded"
                      sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.largeAvatar,
                        backgroundColor: "#1565C0",
                        color: "#fff",
                        mt: 1,
                      }}
                    >
                      <SchoolIcon />
                    </Avatar>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item sx={{ mb: 6 }}>
                <Grid container alignItems="center">
                  <Grid item xs={5}>
                    <Grid container alignItems="center">
                      <Grid item>
                        <Typography
                          sx={{
                            fontSize: "2.125rem",
                            fontWeight: 500,
                            mr: 1,
                            mt: 1.75,
                            mb: 0.75,
                          }}
                        >
                          {studentNumber}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <span onClick={cardClick}>
                          <Avatar
                            sx={{
                              ...theme.typography.smallAvatar,
                              cursor: "pointer",
                              backgroundColor: "#90CAF9",
                              color: theme.palette.primary.dark,
                            }}
                          >
                            <ArrowUpwardIcon
                              fontSize="inherit"
                              sx={{ transform: "rotate3d(1, 1, 1, 45deg)" }}
                            />
                          </Avatar>
                        </span>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          sx={{
                            fontSize: "1rem",
                            fontWeight: 500,
                            color: theme.palette.primary[200],
                          }}
                        >
                          Students
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Chart {...chartData} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </CardWrapper>
      )}
    </>
  );
}

export default StudentCard;
