import PropTypes from "prop-types";
import { useEffect, useState } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Button,
  CardActions,
  CardContent,
  Divider,
  Grid,
  Typography,
  styled,
  List,
} from "@mui/material";

import SkeletonUpdate from "./ui-component/cards/Skeleton/PopularCard";
import CelebrationIcon from "@mui/icons-material/Celebration";
import CakeIcon from "@mui/icons-material/Cake";

import Calendar from "react-calendar";

// project imports
import MainCard from "./ui-component/cards/MainCard";

// assets
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import { getAllEmployees } from "../../util/api-calls";
import { getAllStudents } from "../../util/api-calls";

// ==============================|| DASHBOARD DEFAULT - POPULAR CARD ||============================== //
const CardWrapper = styled(MainCard)(({ theme }) => ({
  overflow: "hidden",
  position: "relative",
  marginLeft: "10px",
  marginTop: "10px",
  borderRadius: "10px",
}));

function UpcomingBirthdays(updateDashboard, students) {
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const [sortedStudents, setSortedStudentss] = useState({});

  useEffect(() => {
    let filteredStudents = [];
    setLoading(true);
    // getAllStudents().then((students) => {
    if (students.length > 0) {
      var d = new Date();
      var t = new Date(d);
      var w = new Date(d);
      t.setDate(t.getDate() - (t.getDay() - 3));
      w.setDate(w.getDate() - (w.getDay() - 3) + 7);
      for (let i = 0; i < students.length; i++) {
        var temp = new Date(students[i].dob);
        var studentBDay = new Date(
          d.getFullYear(),
          temp.getMonth(),
          temp.getDate()
        );
        if (studentBDay >= t && w >= studentBDay) {
          filteredStudents.push(students[i]);
        }
      }
      const sortedUpdatedAt = filteredStudents
        .slice()
        .sort(
          (a, b) =>
            new Date(
              d.getFullYear(),
              new Date(a.dob).getMonth(),
              new Date(a.dob).getDate()
            ) -
            new Date(
              d.getFullYear(),
              new Date(b.dob).getMonth(),
              new Date(b.dob).getDate()
            )
        );
      setSortedStudentss(sortedUpdatedAt);
      setLoading(false);
      // });
    }
  }, [updateDashboard, students]);

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const parseDate = (student) => {
    let date = new Date(student?.dob);
    date = date.toLocaleString("fr-CH", {
      timeZone: "Africa/Johannesburg",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour12: false,
    });
    return date;
    //return (date.getDate() + "/" + ("0" + (date.getMonth() + 1)).slice(-2) + "/" + date.getFullYear() + " " + ("0" + (date.getHours() + 1)).slice(-2) + ":" + ("0" + (date.getMinutes() + 1)).slice(-2));
  };

  return (
    <>
      {loading ? (
        <SkeletonUpdate />
      ) : (
        <CardWrapper border={false} content={false}>
          <CardContent>
            <Grid>
              <Grid item>
                <Grid container justifyContent="space-between">
                  <Grid item xs={0} marginLeft={1}>
                    <Avatar
                      variant="rounded"
                      sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.largeAvatar,
                        backgroundColor: "#7E57C2",
                        color: "#fff",
                        height: 30,
                        width: 30,
                      }}
                    >
                      <CakeIcon />
                    </Avatar>
                  </Grid>
                  <Grid item xs={10} marginTop={0.1}>
                    <Typography variant="h6">Upcoming Birthdays</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Divider sx={{ my: 1.5 }} />
              <Grid item xs={12}>
                <List style={{ maxHeight: "215px", height: "215px", overflow: "auto" }}>
                  {sortedStudents.map((student, index) => (
                    <Grid key={index}>
                      <Grid container direction="column">
                        <Grid item>
                          <Grid
                            container
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Grid item>
                              <Typography
                                variant="subtitle1"
                                color="inherit"
                                fontSize="16px"
                              >
                                {student?.firstname} {student?.lastname}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Grid
                                container
                                alignItems="center"
                                justifyContent="space-between"
                              >
                                <Grid item>
                                  <Typography
                                    variant="subtitle1"
                                    color="inherit"
                                    fontSize="16px"
                                  >
                                    {parseDate(student)}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Divider sx={{ my: 1 }} />
                    </Grid>
                  ))}
                </List>
              </Grid>
            </Grid>
          </CardContent>
        </CardWrapper>
      )}
    </>
  );
}

export default UpcomingBirthdays;
