// material-ui
import { Card, CardContent, Grid } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import MainCard from "../../../ui-component/cards/MainCard";
import { styled, useTheme } from "@mui/material/styles";

// ==============================|| SKELETON - EARNING CARD ||============================== //
const CardWrapper = styled(MainCard)(({ theme }) => ({
    color: "#fff",
    overflow: "hidden",
    position: "relative",
    backgroundColor: "#5E35B1",
    borderRadius: "10px",
    marginLeft: "10px",
    marginTop: "8px",
    "&>div": {
      position: "relative",
      zIndex: 5,
    },
    "&:after": {
      content: '""',
      position: "absolute",
      width: 210,
      height: 210,
      backgroundColor: "#D3D3D3",
      borderRadius: "50%",
      top: -85,
      right: -95,
      [theme.breakpoints.down("sm")]: {
        top: -105,
        right: -140,
      },
    },
    "&:before": {
      content: '""',
      position: "absolute",
      width: 210,
      height: 210,
      backgroundColor: "#D3D3D3",
      borderRadius: "50%",
      top: -125,
      right: -15,
      opacity: 0.5,
      [theme.breakpoints.down("sm")]: {
        top: -155,
        right: -70,
      },
    },
  }));

const EarningCard = () => (
  <CardWrapper border={false} content={false}>
    <Card>
      <CardContent>
        <Grid container direction="column">
          <Grid item>
            <Grid container justifyContent="space-between">
            <Grid item>
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  width={44}
                  height={50}
                />
              </Grid>
              <Grid item>
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  width={44}
                  height={50}
                />
              </Grid>
              <Grid item>
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  width={44}
                  height={50}
                />
              </Grid>
              <Grid item>
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  width={34}
                  height={34}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Skeleton
              animation="wave"
              variant="rectangular"
              sx={{ my: 2 }}
              height={77}
            />
          </Grid>
          <Grid item>
            <Skeleton animation="wave" variant="rectangular" height={30} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  </CardWrapper>
);

export default EarningCard;
