import { useState, useEffect, SetStateAction } from "react";
import { Amplify, Auth, Hub } from "aws-amplify";
import "@aws-amplify/ui-react/styles.css";
import awsExports from "../../aws-exports";
import { useNavigate } from "react-router";
import KozoLogo from "../../assets/img/images/Kozo_Logo.png";
import KozoTeacher from "../../assets/img/kozoteacher.png";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {
  Button,
  Divider,
  Grid,
  Typography,
  TextField,
  CircularProgress,
  Dialog,
  DialogContent,
  Tooltip,
} from "@mui/material";
import React from "react";
Amplify.configure(awsExports);

const initialFormState = {
  username: "",
  password: "",
  email: "",
  authCode: "",
  formType: "signIn",
  resetCode: "",
  resetPassword: "",
};

function SignIn(updateUserName: React.Dispatch<SetStateAction<string>>) {
  const navigate = useNavigate();
  const [formState, updateFormState] = useState(initialFormState);
  const [errormsg, updateError] = useState("");
  const [isReseting, setIsReseting] = useState(false);
  const [forgotPass, setForgotPass] = useState(false);
  const [resetConfirmed, setResetConfirmed] = useState(false);

  const [openLoadDialog, setOpenLoadDialog] = React.useState(false);
  const closeLoadDialog = () => {
    setOpenLoadDialog(false);
  };
  const handleOpenLoadDialog = () => {
    setOpenLoadDialog(true);
  };

  useEffect(() => {
    checkUser();
    setAuthListener();
  }, []);

  async function setAuthListener() {
    Hub.listen("auth", (data) => {
      switch (data.payload.event) {
        case "signIn":
          break;
        case "signOut":
          updateFormState(() => ({ ...formState, formType: "signIn" }));
          break;
        default:
      }
    });
  }

  async function checkUser() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      updateUser(user);
      updateFormState(() => ({
        ...formState,
        formType: "signedIn",
      }));
    } catch (err) { }
  }

  function onChange(e) {
    e.persist();
    updateFormState(() => ({ ...formState, [e.target.name]: e.target.value }));
  }

  const { formType } = formState;

  async function signIn() {
    handleOpenLoadDialog();

    const { username, password } = formState;

    try {
      const user = await Auth.signIn(username, password);
      updateFormState(() => ({ ...formState, formType: "signedIn" }));
      getRole();
      closeLoadDialog();
    } catch (error) {
      closeLoadDialog();
      if (error.toString().includes("Temporary password has expired")) {
        navigate("/confirmpassword");
      } else {
        console.log("error signing in", error);
        updateError("Incorrect username or password. Try again");
      }
    }
  }

  async function resetPassword() {
    const { username, resetCode, resetPassword } = formState;
    Auth.forgotPasswordSubmit(username, resetCode, resetPassword)
      .then((data) => {
        console.log(data);
        setResetConfirmed(true);
        updateError("");
      })
      .catch((err) => {
        console.log(err);
        updateError("Incorrect code or password. Try again");
      });
  }

  async function getRole() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      if (user.signInUserSession.idToken.payload["cognito:groups"]) {
        if (user.signInUserSession.idToken.payload["cognito:groups"].includes("Admin")) {
          closeLoadDialog();
          navigate("/dashboard");
        } else if (
          user.signInUserSession.idToken.payload["cognito:groups"].includes("SchoolAdmin")
        ) {
          closeLoadDialog();
          navigate("/dashboard");
        } else if (
          user.signInUserSession.idToken.payload["cognito:groups"].includes("Client")
        ) {
          closeLoadDialog();
          navigate("/dashboard");
        } else if (
          user.signInUserSession.idToken.payload["cognito:groups"][0] ===
          "StudentParent"
        ) {
          closeLoadDialog();
          navigate("/students");
        }
      } else {
        closeLoadDialog();
        navigate("/dashboard");
      }
      if (user.pool.userPoolId === "ca-central-1_TxjoIawIu") {
        updateUserName(user.attributes.email);
      } else {
        updateUserName(user.username);
      }
    } catch (err) {
      closeLoadDialog();
      navigate("/confirmpassword");
      console.log(err);
    }
  }

  function handlBacktoSignin() {
    setForgotPass(false);
    setIsReseting(false);
    setResetConfirmed(false);
    updateError("");
  }

  function handleResetPassword() {
    navigate("/resetpassword")
    // if (!forgotPass) {
    //   const { username, password } = formState;

    //   setForgotPass(true);
    // } else {
    //   setResetConfirmed(false);
    //   setIsReseting(false);
    //   setForgotPass(false);
    // }
    // updateError("");
  }

  function handleSendCode() {
    if (isReseting === false) {
      const { username, password } = formState;
      Auth.forgotPassword(username)
        .then((data) => {
          setIsReseting(true);
          setForgotPass(false);
        })
        .catch((err) => console.log(err));
    } else {
      setResetConfirmed(false);
      setIsReseting(false);
    }
    updateError("");
  }

  return (
    <div className="signinpage">
      <Dialog open={openLoadDialog} keepMounted>
        <DialogContent>
          <CircularProgress></CircularProgress>
        </DialogContent>
      </Dialog>
      {resetConfirmed ? (
        <>
          <div className="signinblock">
            <div className="ms-3 navbar-brand">
              <img src={KozoLogo} height={23}></img>
              <span className="navbartitleschool"> School</span>
              <span className="navbartitle"> Management System</span>
            </div>
            <Grid item marginTop={5}>
              <Typography
                style={{
                  color: "rgb(103, 58, 183)",
                  fontSize: "16px",
                }}
              >
                Your Password was reset!
              </Typography>
            </Grid>
            <Divider sx={{ my: 1.5 }} />
            <Grid
              item
              marginTop={5}
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <CheckCircleOutlineIcon
                sx={{ fontSize: 60, marginLeft: "calc(50% - 30px)" }}
              />
            </Grid>

            <Grid item marginTop={4}>
              <Button
                onClick={handlBacktoSignin}
                size="large"
                type="submit"
                variant="contained"
                style={{
                  marginLeft: -12,
                  backgroundColor: "rgb(103, 58, 183)",
                  width: 340,
                }}
              >
                Back to Signin
              </Button>
            </Grid>
          </div>
        </>
      ) : (
        <>
          {forgotPass ? (
            <>
              <div className="signinblock">
                <div className="ms-3 navbar-brand">
                  <img src={KozoLogo} height={23}></img>
                  <span className="navbartitleschool"> School</span>
                  <span className="navbartitle"> Management System</span>
                </div>
                <Grid item marginTop={5}>
                  <Typography
                    style={{
                      color: "rgb(103, 58, 183)",
                      fontSize: "16px",
                    }}
                  >
                    Enter your Username
                  </Typography>
                </Grid>
                <Divider sx={{ my: 1.5 }} />
                <Grid item marginTop={5}>
                  {/* <Typography variant="h7">
                    Enter your username
                  </Typography> */}
                  <Grid item marginTop={3}>
                    <Typography
                      style={{
                        color: "#cc0000",
                      }}
                      variant="h7"
                    >
                      {errormsg}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item marginTop={3}>
                  <div className="row input-container">
                    <TextField
                      id="outlined-adornment-code-login"
                      type="resetCode"
                      name="resetCode"
                      onChange={onChange}
                      label="User Name"
                      inputProps={{}}
                      className="signinfields regularTextField"
                    />
                  </div>
                </Grid>
                {/* <Grid item marginTop={2}>
                  <div className="row input-container">
                    <TextField
                      id="outlined-adornment-resetPassword-login"
                      type="password"
                      name="resetPassword"
                      onChange={onChange}
                      label="New Password"
                      inputProps={{}}
                      className="signinfields regularTextField"
                    />
                  </div>
                </Grid> */}
                <Grid item marginTop={4}>
                  <Button
                    onClick={handleSendCode}
                    size="large"
                    type="submit"
                    variant="contained"
                    style={{
                      marginLeft: -12,
                      backgroundColor: "rgb(103, 58, 183)",
                      width: 340,
                    }}
                  >
                    Send Code
                  </Button>
                </Grid>
                <Grid item marginTop={4}>
                  <span
                    onClick={handlBacktoSignin}
                    size="large"
                    type="submit"
                    variant="contained"
                    style={{
                      marginLeft: -12,
                      color: "#3366CC",
                      width: 340,
                    }}
                  >
                    Back to Signin
                  </span>
                </Grid>
              </div>
            </>
          ) : (
            <>
              {isReseting ? (
                <>
                  <div className="signinblock">
                    <div className="ms-3 navbar-brand">
                      <img src={KozoLogo} height={23}></img>
                      <span className="navbartitleschool"> School</span>
                      <span className="navbartitle"> Management System</span>
                    </div>
                    <Grid item marginTop={5}>
                      <Typography
                        style={{
                          color: "rgb(103, 58, 183)",
                          fontSize: "16px",
                        }}
                      >
                        A Verification Code was sent to your email
                      </Typography>
                    </Grid>
                    <Divider sx={{ my: 1.5 }} />
                    <Grid item marginTop={5}>
                      <Typography variant="h7">
                        Enter the code and the new password
                      </Typography>
                      <Grid item marginTop={3}>
                        <Typography
                          style={{
                            color: "#cc0000",
                          }}
                          variant="h7"
                        >
                          {errormsg}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item marginTop={3}>
                      <div className="row input-container">
                        <TextField
                          id="outlined-adornment-code-login"
                          type="resetCode"
                          name="resetCode"
                          onChange={onChange}
                          label="Verification Code"
                          inputProps={{}}
                          className="signinfields regularTextField"
                        />
                      </div>
                    </Grid>
                    <Grid item marginTop={2}>
                      <div className="row input-container">
                        <TextField
                          id="outlined-adornment-resetPassword-login"
                          type="password"
                          name="resetPassword"
                          onChange={onChange}
                          label="New Password"
                          inputProps={{}}
                          className="signinfields regularTextField"
                        />
                      </div>
                    </Grid>
                    <Grid item marginTop={4}>
                      <Button
                        onClick={resetPassword}
                        size="large"
                        type="submit"
                        variant="contained"
                        style={{
                          marginLeft: -12,
                          backgroundColor: "rgb(103, 58, 183)",
                          width: 340,
                        }}
                      >
                        Reset Password
                      </Button>
                    </Grid>
                    <Grid item marginTop={4}>
                      <span
                        onClick={handlBacktoSignin}
                        size="large"
                        type="submit"
                        variant="contained"
                        style={{
                          marginLeft: -12,
                          color: "#3366CC",
                          width: 340,
                        }}
                      >
                        Back to Signin
                      </span>
                    </Grid>
                  </div>
                </>
              ) : (
                <>
                  {formType === "signIn" && (
                    <>
                      <Grid item className="col newsigninblock" xs={3}>
                        <Grid item className="row signinblockheader" xs={3}>
                          <Grid item className="col logoheader">
                            <img src={KozoLogo}></img>
                          </Grid>
                          <Grid item className="col smsheader" xs={3}>
                            <Typography className=" smsheader1">
                              School Management System
                            </Typography>
                            <Divider sx={{ my: 1.5 }} />
                            <Typography className=" smsheader2">
                              by Tri8Solutions
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item className="row signinsecondheader" xs={3}>
                          <Typography variant="h5">School Access</Typography>
                          <Typography style={{ fontSize: 11 }}>
                            Access for School Administrators, Owners & Executive
                            Stakeholders
                          </Typography>

                          {errormsg ? (
                            <>
                              <Typography
                                style={{
                                  marginTop: 3,
                                  color: "#cc0000",
                                }}
                                variant="h7"
                              >
                                {errormsg}
                              </Typography>
                            </>
                          ) : (
                            <>
                              <>
                                <Typography
                                  style={{
                                    marginTop: 20,
                                    color: "#cc0000",
                                  }}
                                  variant="h7"
                                ></Typography>
                              </>
                            </>
                          )}

                          <Grid item marginTop={3}>
                            <div className="row input-container signininput">
                              <TextField
                                id="outlined-adornment-username-login"
                                type="username"
                                name="username"
                                onChange={onChange}
                                label="Username"
                                inputProps={{}}
                                value={formState.username}
                                style={{
                                  marginTop: 20,
                                  minWidth: 240,
                                  width: 350,
                                  maxWidth: 350,
                                  flex: 1,
                                }}
                                className="signinfields regularTextField newsigninfields"
                                InputLabelProps={{ shrink: true }}
                              />

                              <TextField
                                id="outlined-adornment-password-login"
                                type="password"
                                name="password"
                                onChange={onChange}
                                label="Password"
                                inputProps={{}}
                                value={formState.password}
                                style={{
                                  marginTop: 20,
                                  minWidth: 240,
                                  width: 350,
                                  maxWidth: 350,
                                  flex: 1,
                                }}
                                className="signinfields regularTextField newsigninfields"
                                InputLabelProps={{ shrink: true }}
                              />
                              <Tooltip title="Login">
                                <Button
                                  onClick={signIn}
                                  size="large"
                                  type="submit"
                                  variant="contained"
                                  style={{
                                    marginTop: 20,
                                    marginLeft: 0,
                                    backgroundColor: "#0048b4",
                                    color: "#fff",
                                    border: 20,
                                    width: 350,
                                  }}
                                >
                                  Segulani
                                </Button>
                              </Tooltip>
                            </div>
                          </Grid>
                        </Grid>
                        <div
                        //   style={{
                        //     position: "relative",
                        //   }}
                        >
                          <div className="bottomSignin">
                            <Grid item marginTop={4}>
                              <span
                                onClick={handleResetPassword}
                                size="large"
                                type="submit"
                                variant="contained"
                                style={{
                                  marginTop: 0,
                                  minWidth: 240,
                                  flex: 1,

                                  width: "100vw",
                                  textAlign: "center",
                                  maxWidth: 350,
                                  color: "#3366CC",
                                  fontSize: 14,
                                  marginBottom: 105,
                                }}
                              >
                                Reset Password
                              </span>
                            </Grid>
                            <Grid item>
                              <span
                                onClick={() => {
                                  navigate("/parent-signin");
                                }}
                                size="large"
                                type="submit"
                                variant="contained"
                                style={{
                                  marginTop: -40,
                                  minWidth: 240,
                                  flex: 1,

                                  width: "100vw",
                                  textAlign: "center",
                                  maxWidth: 350,
                                  color: "#3366CC",
                                  fontSize: 14,
                                }}
                              >
                                Parent Access
                              </span>
                            </Grid>
                            <Grid item>
                              <span
                                onClick={() => {
                                  navigate("/employee-signin");
                                }}
                                size="large"
                                type="submit"
                                variant="contained"
                                style={{
                                  marginTop: 10,
                                  minWidth: 240,
                                  flex: 1,

                                  width: "100vw",
                                  textAlign: "center",
                                  maxWidth: 350,
                                  color: "#3366CC",
                                  fontSize: 14,
                                }}
                              >
                                Employee Access
                              </span>
                            </Grid>
                            <Grid item marginTop={4}>
                              <span
                                //   onClick={() => {
                                //     navigate("/signin");
                                //   }}
                                size="large"
                                type="submit"
                                variant="contained"
                                style={{
                                  color: "#000",
                                  marginTop: 20,
                                  color: "#3366CC",
                                  fontSize: 12,
                                  marginLeft: 0,
                                }}
                              >
                                Privacy Statement
                              </span>
                              {"  |  "}
                              <span
                                //   onClick={() => {
                                //     navigate("/signin");
                                //   }}
                                size="large"
                                type="submit"
                                variant="contained"
                                style={{
                                  marginTop: 20,
                                  color: "#3366CC",
                                  fontSize: 12,
                                }}
                              >
                                Careers
                              </span>
                              {"  |  "}
                              <span
                                //   onClick={() => {
                                //     navigate("/signin");
                                //   }}
                                size="large"
                                type="submit"
                                variant="contained"
                                style={{
                                  marginTop: 20,
                                  color: "#3366CC",
                                  fontSize: 12,
                                }}
                              >
                                Sustainability Pledge
                              </span>
                            </Grid>
                          </div>
                        </div>
                      </Grid>

                      {/* <Grid>
                        <Grid
                          style={{
                            marginLeft: 500,
                          }}
                        >
                          <div
                            style={{
                              alignContent: "right",
                            }}
                          >
                            <div
                              style={{
                                padding: 3,
                                marginTop: 170,
                                marginRight: 10,
                                backgroundColor: "#fff",
                                width: 450,
                                maxWidth: 450,
                                float: "right",
                                marginLeft: 100,
                              }}
                            >
                              <Typography
                                style={{
                                  textAlign: "center",
                                  marginLeft: 10,
                                  marginTop: 10,
                                }}
                                variant="h6"
                              >
                                Hire only Teaching Council of Zambia (TCZ)
                                registered teachers through our direct
                                integration with TCZ
                              </Typography>
                            </div>
                            <div
                              style={{
                                padding: 3,
                                marginTop: 50,
                                marginLeft: 10,
                                backgroundColor: "#fff",
                                width: 450,
                                maxWidth: 450,
                                float: "left",
                                marginRight: 100,
                              }}
                            >
                              <Typography
                                style={{
                                  textAlign: "center",
                                  marginLeft: 10,
                                  marginTop: 10,
                                }}
                                variant="h6"
                              >
                                Ask us about NHIMA, NAPSA and Worker
                                Compensation Direct service
                              </Typography>
                            </div>
                            <div
                              style={{
                                padding: 3,
                                marginTop: 50,
                                marginRight: 10,
                                backgroundColor: "#fff",
                                width: 450,
                                maxWidth: 450,
                                float: "right",
                                marginLeft: 100,
                              }}
                            >
                              <Typography
                                style={{
                                  textAlign: "center",
                                  marginLeft: 10,
                                  marginTop: 10,
                                }}
                                variant="h6"
                              >
                                Kozo Payroll - Launching in January 2024
                              </Typography>
                            </div>
                            <div
                              style={{
                                marginTop: 50,
                                marginRight: 10,
                                backgroundColor: "#fff",
                                width: 500,
                                maxWidth: 500,
                                float: "right",
                                marginLeft: 100,
                              }}
                            >
                              <img src={KozoTeacher} height={350}></img>
                            </div>
                          </div>
                        </Grid>
                      </Grid> */}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}

export default SignIn;
