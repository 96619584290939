import React, { createRef } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  IconButton
} from '@mui/material';
import { NoteAdd, PictureAsPdf } from '@mui/icons-material';

class EnrolmentPaymentDialog extends React.Component {
  fileInputRef = createRef<HTMLInputElement>();

  constructor(props) {
    super(props);
    // Initialize local state with default values or from props if available
    this.state = {
      amount: props.payment ? props.payment.amount : 0,
      status: props.payment ? props.payment.status : 'Pending',
      type: props.payment ? props.payment.type : '',
      discount: props.payment ? props.payment.discount : '',
      paymentproof: props.payment ? props.payment.paymentproof : ''
    };
  }

  componentDidUpdate(prevProps) {
    // Update state if the payment ID changes to handle different payment editing without conflicts
    if (prevProps.payment.id !== this.props.payment.id) {
      this.setState({
        amount: this.props.payment.amount,
        status: this.props.payment.status,
        type: this.props.payment.type,
        discount: this.props.payment.discount,
        paymentproof: this.props.payment.paymentproof
      });
    }
  }

  handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      this.setState({ paymentproof: URL.createObjectURL(file) });
    }
  };

  openFileSelector = () => {
    console.log("Ref:", this.fileInputRef.current); // Should log the input element
    if (this.fileInputRef.current) {
      console.log("Attempting to click file input");
      this.fileInputRef.current.click(); // Try to open file dialog
    } else {
      console.error('File input is not available.');
    }
  };


  handleInputChange = (event) => {
    // Handle changes in the form inputs and update local state
    const { name, value } = event.target;
    this.setState({
      [name]: value
    });
  };

  addProofOfPayment(e: ChangeEvent<HTMLInputElement>) {
    const files = (e?.target as HTMLInputElement)?.files;
    if (files && files?.length > 0) {
      this.setState(
        {
          ...this.state,
          fileName: files[0].name,
        },
        () => {
          this.props.onDocumentUpdate(e, this.props.payment.id);
        }
      );
    }
  }

  handleSave = () => {
    // Create an updated payment object and pass it back to the parent component
    const updatedPayment = {
      ...this.props.payment,
      ...this.state
    };
    this.props.onUpdatePayment(updatedPayment);  // Call the parent method to update the main state
    this.props.onClose();  // Close the modal
  };



  render() {
    const { open, onClose } = this.props;
    const { amount, status, type, discount, paymentproof } = this.state;
    const hasUrlAlready = !!paymentproof;
    const buttonText = hasUrlAlready ? "Update proof of payment" : "Add proof of payment";
    console.log(open)

    return (
      <Dialog open={open} onClose={onClose}>
        <DialogContent>
          <TextField label="Amount" type="number" name="amount" value={amount} onChange={this.handleInputChange} fullWidth margin="normal" />
          <TextField label="Payment Type" name="type" value={type} onChange={this.handleInputChange} fullWidth margin="normal" />

          <Button startIcon={<NoteAdd />} variant="contained" color="success" size="small" component="label" onClick={this.openFileSelector}>
            {buttonText}
            <input
              type="file"
              accept="image/*"
              ref={this.fileInputRef}
              onChange={this.handleFileChange}
              style={{ position: 'absolute', left: '-9999px' }}
            />
          </Button>

          {paymentproof && (
            <IconButton onClick={() => window.open(paymentproof, "_blank")}>
              <PictureAsPdf />
            </IconButton>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={this.handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default EnrolmentPaymentDialog;



// import {
//   Avatar,
//   Button,
//   Dialog,
//   DialogActions,
//   DialogTitle,
//   List,
//   ListItem,
//   ListItemAvatar,
//   ListItemText,
//   TextField,
//   Typography,
// } from "@mui/material";
// import React, { ChangeEvent } from "react";
// import {
//   EnrolmentPayment,
//   Registration,
//   Student,
// } from "../../util/data-models";
// import PersonIcon from "@mui/icons-material/Person";
// import { blue } from "@mui/material/colors";
// import Emoji from "../ui-elements/emoji";
// import { NoteAdd, PictureAsPdf } from "@mui/icons-material";

// const modalElementStyle = {
//   margin: 12,
//   maxWidth: 242,
//   maxHeight: 42,
// };

// class EnrolmentPaymentDialog extends React.Component<
//   {
//     open: boolean;
//     onClose: (payment?: EnrolmentPayment) => void;
//     onDocumentUpdate: (
//       e: ChangeEvent<HTMLInputElement>,
//       payment_id: string
//     ) => void;
//     payment: EnrolmentPayment;
//   },
//   {
//     paymentProofUrl: string | undefined;
//     fileName: string | undefined;
//     paymentObj: EnrolmentPayment;
//   }
// > {
//   constructor(props: any) {
//     super(props);
//     this.state = {
//       paymentProofUrl: this.props.payment?.paymentproof,
//       fileName: undefined,
//       paymentObj: this.props.payment,
//     };
//   }

//   handleDialogClose = (value?: EnrolmentPayment) => {
//     this.props.onClose(value);
//   };

//   addProofOfPayment(e: ChangeEvent<HTMLInputElement>) {
//     const files = (e?.target as HTMLInputElement)?.files;
//     if (files && files?.length > 0) {
//       this.setState(
//         {
//           ...this.state,
//           fileName: files[0].name,
//         },
//         () => {
//           this.props.onDocumentUpdate(e, this.props.payment.id);
//         }
//       );
//     }
//   }

//   handleAmountChange(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
//     const newPayment = this.state.paymentObj;
//     newPayment.amount = Number(e.target.value);
//     this.setState(
//       (oldState, props) => (
//         {
//           ...oldState,
//           paymentObj: newPayment,
//         },
//         () => {
//           this.props.onDocumentUpdate(e, this.props.payment.id);
//         }
//       )
//     );
//   }

//   handleTypeChange(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
//     const newPayment = this.state.paymentObj;
//     newPayment.type = e.target.value;
//     this.setState((oldState, props) => ({
//       ...oldState,
//       paymentObj: newPayment,
//     },
//     () => {
//       this.props.onDocumentUpdate(e, this.props.payment.id);
//     }));
//   }

//   createUI() {
//     console.log(this.state?.paymentObj)
//     const hasUrlAlready =
//       !!this.state?.paymentProofUrl && this.state.paymentProofUrl?.length > 0;
//     const buttonText = hasUrlAlready
//       ? "Update proof of payment"
//       : "Add proof of payment";

//     return (
//       <React.Fragment>
//         <Button
//           startIcon={<NoteAdd />}
//           style={{
//             minWidth: 200,
//           }}
//           variant="contained"
//           color="success"
//           size="small"
//         >
//           <label htmlFor="file-input">{buttonText}</label>
//         </Button>

//         <input
//           type="file"
//           accept="image/*"
//           name="file-upload"
//           id="file-input"
//           onChange={this.addProofOfPayment.bind(this)}
//           style={{ display: "none" }}
//         />
//         {!!this.state.fileName && (
//           <span style={{ marginLeft: 12 }}>{this.state.fileName}</span>
//         )}
//         {hasUrlAlready && (
//           <a href={this.state.paymentProofUrl}>
//             <PictureAsPdf />
//             View Proof
//           </a>
//         )}

//         <TextField
//           autoFocus
//           margin="dense"
//           id="amount"
//           label="Amount"
//           type="number"
//           size="small"
//           style={modalElementStyle}
//           defaultValue={this.state?.paymentObj?.amount}
//           onChange={this.handleAmountChange.bind(this)}
//         />

//         <TextField
//           autoFocus
//           margin="dense"
//           id="type"
//           label="Type"
//           type="text"
//           size="small"
//           style={modalElementStyle}
//           defaultValue={this.state?.paymentObj?.type || "Cash"}
//           onChange={this.handleTypeChange.bind(this)}
//         />
//         <></>
//       </React.Fragment>
//     );
//   }

//   render() {
//     return (
//       <Dialog open={this.props.open} onClose={() => this.props.onClose()}>
//         <DialogTitle textAlign={"center"}>Proof of payment</DialogTitle>
//         {this.createUI()}
//         <DialogActions>
//           <Button
//             variant="contained"
//             color="primary"
//             size="small"
//             onClick={() => this.handleDialogClose(this.state.paymentObj)}
//           >
//             OK
//           </Button>
//         </DialogActions>
//       </Dialog>
//     );
//   }
// }

// export default EnrolmentPaymentDialog;
