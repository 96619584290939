import {
  Button,
  ButtonGroup,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import BadgeTwoToneIcon from "@mui/icons-material/BadgeTwoTone";
import {
  DataGrid,
  GridApi,
  GridCallbackDetails,
  GridColDef,
  GridColumnHeaderParams,
  GridEventListener,
  GridRenderCellParams,
  GridRowParams,
  MuiEvent,
} from "@mui/x-data-grid";
import { NavigateFunction, useLocation, useNavigate } from "react-router";
import {
  Fragment,
  MouseEventHandler,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import React, { Component } from "react";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import ReceiptIcon from "@mui/icons-material/Receipt";
import AddBoxIcon from "@mui/icons-material/AddBox";
import SettingsIcon from "@mui/icons-material/Settings";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import SummarizeIcon from "@mui/icons-material/Summarize";

import { Amplify, Auth, Hub } from "aws-amplify";
import PermissionsGate from "../../util/perminssion-gate";
import { SCOPES } from "../../util/permission-map";
import { getAllEmployees, getAllGuardians } from "../../util/api-calls";
import InvoiceModal from "../editcontact/invoicemodal";
import { PDFViewer, PDFDownloadLink, View, Text } from "@react-pdf/renderer";
import DownloadIcon from "@mui/icons-material/Download";
import { Page, Document, Image, StyleSheet } from "@react-pdf/renderer";
import BillTo from "../editcontact/pdfgenerator/bill-to";
import {
  InvoiceData,
  InvoiceItem,
} from "../editcontact/pdfgenerator/invoice-data";
import InvoiceItemsTable from "../editcontact/pdfgenerator/invoice-items-table";
import InvoiceNo from "../editcontact/pdfgenerator/invoice-number";
import InvoiceTitle from "../editcontact/pdfgenerator/invoice-title";
import { Employee } from "../contactlist/newcontactlist";
import { Allowance } from "../../util/data-models";
import { CSVLink, CSVDownload } from "react-csv";

function Invoice(username: string, employeesList) {
  const [openPayroll, setOpenPayrollModal] = useState(false);
  const [employees, setemployees] = useState([] as Employee[]);
  const navigate = useNavigate();


  let defaultMonth = monthsSinceJanuary2024()[0]
  const [selectedMonth, setSelectedMonth] = React.useState(defaultMonth.toString());
  const [openLoadDialog, setOpenLoadDialog] = React.useState(false);
  const closeLoadDialog = () => {
    setOpenLoadDialog(false);
  };
  const handleOpenLoadDialog = () => {
    setOpenLoadDialog(true);
  };

  useEffect(() => {
    handleOpenLoadDialog();
    // getAllEmployees().then((employees) => {
    if (employeesList.length > 0) {
      setemployees(employeesList);
      closeLoadDialog();
    }
    // });
  }, [employeesList]);

  const manageFeeClicked = () => {
    let path = `/settings/managefee`;
    navigate(path);
  };

  const manageEnrolmentsClicked = () => {
    let path = `/settings/manage-enrolments`;
    navigate(path);
  };

  const handleMonthChange = (event: any) => {
    setSelectedMonth(event.target.value);
    console.log(event.target.value)
  };

  function ManageFeeButton(
    manageFeeClicked: MouseEventHandler<HTMLButtonElement>
  ) {
    return (
      <Button
        startIcon={<SettingsSuggestIcon />}
        variant="contained"
        color="success"
        size="large"
        onClick={manageFeeClicked}
      >
        Manage School Fees
      </Button>
    );
  }

  function ManageEnrolmentsButton(
    manageEnrolmentsClicked: MouseEventHandler<HTMLButtonElement>
  ) {
    return (
      <Button
        variant="contained"
        size="large"
        startIcon={<LocalLibraryIcon />}
        onClick={manageEnrolmentsClicked}
      >
        Manage enrolments
      </Button>
    );
  }

  function getTimeStamp() {
    var date = new Date();
    var aaaa = date.getUTCFullYear();
    var gg = date.getUTCDate();
    var mm = date.getUTCMonth() + 1;

    if (gg < 10) gg = "0" + gg;

    if (mm < 10) mm = "0" + mm;

    var cur_day = aaaa + mm + gg;

    var hours = date.getUTCHours();
    var minutes = date.getUTCMinutes();
    var seconds = date.getUTCSeconds();

    if (hours < 10) hours = "0" + hours;

    if (minutes < 10) minutes = "0" + minutes;

    if (seconds < 10) seconds = "0" + seconds;

    return cur_day + "-" + hours + minutes + seconds;
  }

  function GeneratePayrollExtractButton() {
    return (
      <>
        <CSVLink
          data={csvData()}
          filename={"SMSPayrollExtract-" + getTimeStamp()}
        >
          <Button
            variant="contained"
            size="large"
            startIcon={<SummarizeIcon />}
          >
            Payroll extract
          </Button>
        </CSVLink>
      </>
    );
  }

  function GenerateMockPayrollExtractButton() {
    return (
      <>
        <CSVLink
          data={mockCsvData()}
          filename={"SMSPayrollExtract-" + getTimeStamp()}
        >
          <Button
            variant="contained"
            size="large"
            startIcon={<SummarizeIcon />}
          >
            Run Mock Payroll
          </Button>
        </CSVLink>
      </>
    );
  }

  function setopenPayroll() {
    console.log("setopen");
    if (openPayroll === true) {
      setOpenPayrollModal(false);
    } else {
      setOpenPayrollModal(true);
    }
  }

  function today() {
    let date = new Date();
    return (
      date.getFullYear() +
      "-" +
      ("0" + (new Date().getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + (new Date().getDate() + 1)).slice(-2)
    ).toString();
  }

  function calculateGratuity(employee: Employee) {
    let gratuity = parseInt(employee.gratuity) / 100;
    let salary = parseInt(employee.salary) * 12;
    let agAmount = gratuity * salary;
    let mgAmount = agAmount / 12;

    let today = new Date();
    let hireDate = new Date(employee.datehire);
    let months;
    months = (today.getFullYear() - hireDate.getFullYear()) * 12;
    months -= hireDate.getMonth();
    months += today.getMonth();
    months = months <= 0 ? 0 : months;

    let AccuredGratuity = mgAmount * months;

    // console.log(
    //   gratuity +
    //     " " +
    //     salary +
    //     " " +
    //     agAmount +
    //     " " +
    //     mgAmount +
    //     " " +
    //     months +
    //     " " +
    //     AccuredGratuity
    // );
    return AccuredGratuity;
  }


  function csvData() {
    //handleOpenLoadDialog()
    // let count = employees.length || -1;

    let headers = [
      { label: "First Name", key: "firstname" },
      { label: "Last Name", key: "lastname" },
      { label: "Email", key: "email" },
    ];

    let data = [
      ["Date: ", `${today()}`],
      ["School: ", "Ebenezer Schools"],
      ["EXECUTED BY: ", `${username}`],
      [
        "`ER NAPSA ACC. #",
        "Run Date",
        "SSN #",
        "NRC #",
        "BANK ACC. #",
        "BANK NAME",
        "BANK BRANCH",
        "SURNAME",
        "FIRST NAME",
        "DATE OF BIRTH",
        "TPIN",
        "CAMPUS",
        "BASIC PAY",
        "ALLOWANCES",
        "DEDUCTIONS",
        "ACCRUED GRATUITY",
      ],
    ];

    for (let i = 0; i < employees.length; i++) {
      let rowData = [];
      if (
        employees[i]?.status[employees[i].status.length - 1]?.status ===
          "Active" ||
        employees[i]?.status[employees[i].status.length - 1]?.status ===
          "Probation"
      ) {
        rowData.push(employees[i].napsa);
        rowData.push(today());
        rowData.push(employees[i].pensionno);
        rowData.push(employees[i].nrc);
        rowData.push(employees[i].bankaccount);
        rowData.push(employees[i].bankname);
        rowData.push(employees[i].bankbranch);
        rowData.push(employees[i].lastname);
        rowData.push(employees[i].firstname);
        rowData.push(employees[i].dob);
        rowData.push(employees[i].zratpin);
        rowData.push(employees[i].campus);
        rowData.push(employees[i].salary);

        if (employees[i].allowances.length > 0) {
          let allowanceSum = 0;
          for (let j = 0; j < employees[i].allowances.length; j++) {
            allowanceSum += parseInt(
              employees[i].allowances[j].allowanceamount
            );
          }
          rowData.push(allowanceSum.toString());
        } else {
          rowData.push("");
        }

        rowData.push("");
        rowData.push(calculateGratuity(employees[i]).toString());
        // count --;
        data.push(rowData);
      } else {
        // count --;
      }
      // console.log(count)
      // console.log(employees.length - 1)
    }
    // if(count === 0) {
    //   console.log("whatup")
    //closeLoadDialog();
    // }
    return data;
  }

  function monthsSinceJanuary2024() {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const startDate = new Date(2024, 0, 1); // JavaScript months are 0-indexed: 0 is January
    const today = new Date();

    let months = [];
    for (
      let year = startDate.getFullYear();
      year <= today.getFullYear();
      year++
    ) {
      let startMonth = 0;
      if (year > startDate.getFullYear()) {
        startMonth = 0; // Start from January for the years after 2024
      } else {
        startMonth = startDate.getMonth(); // Start from the initial month in 2024
      }
      let endMonth = 11; // December, for years before the current year
      if (year === today.getFullYear()) {
        endMonth = today.getMonth(); // Up to the current month in the current year
      }
      for (let month = startMonth; month <= endMonth; month++) {
        if (year > 2023 || (year === 2024 && month >= 0)) {
          // Ensure starting from January 2024
          months.push({
            year,
            month: month + 1, // Adding 1 to make months 1-indexed for readability
            monthName: monthNames[month], // Add the month name
          });
        }
      }
    }

    return months;
  }

  function mockCsvData() {
    console.log(monthsSinceJanuary2024());
    // start from date
    // check which month it is
    // check if there is past information in the database
    //

    let headers = [
      { label: "First Name", key: "firstname" },
      { label: "Last Name", key: "lastname" },
      { label: "Email", key: "email" },
    ];

    let data = [
      ["Date: ", `${today()}`],
      ["School: ", "Ebenezer Schools"],
      ["EXECUTED BY: ", `${username}`],
      [
        "`ER NAPSA ACC. #",
        "Run Date",
        "SSN #",
        "NRC #",
        "BANK ACC. #",
        "BANK NAME",
        "BANK BRANCH",
        "SURNAME",
        "FIRST NAME",
        "DATE OF BIRTH",
        "TPIN",
        "CAMPUS",
        "BASIC PAY",
        "ALLOWANCES",
        "DEDUCTIONS",
        "ACCRUED GRATUITY",
      ],
    ];

    for (let i = 0; i < employees.length; i++) {
      let rowData = [];
      if (
        employees[i]?.status[employees[i].status.length - 1]?.status ===
          "Active" ||
        employees[i]?.status[employees[i].status.length - 1]?.status ===
          "Probation"
      ) {
        rowData.push(employees[i].napsa);
        rowData.push(today());
        rowData.push(employees[i].pensionno);
        rowData.push(employees[i].nrc);
        rowData.push(employees[i].bankaccount);
        rowData.push(employees[i].bankname);
        rowData.push(employees[i].bankbranch);
        rowData.push(employees[i].lastname);
        rowData.push(employees[i].firstname);
        rowData.push(employees[i].dob);
        rowData.push(employees[i].zratpin);
        rowData.push(employees[i].campus);
        rowData.push(employees[i].salary);

        if (employees[i].allowances.length > 0) {
          let allowanceSum = 0;
          for (let j = 0; j < employees[i].allowances.length; j++) {
            allowanceSum += parseInt(
              employees[i].allowances[j].allowanceamount
            );
          }
          rowData.push(allowanceSum.toString());
        } else {
          rowData.push("");
        }

        rowData.push("");
        rowData.push(calculateGratuity(employees[i]).toString());
        // count --;
        data.push(rowData);
      } else {
        // count --;
      }
      // console.log(count)
      // console.log(employees.length - 1)
    }
    // if(count === 0) {
    //   console.log("whatup")
    //closeLoadDialog();
    // }
    return data;
  }

  // function InvoiceDoc() {
  //   return (
  //     <Document>
  //       <Page size="A1" orientation="landscape" style={styles.page}>
  //         <View>
  //           <Text style={styles.billTo}>Date: {today()}</Text>
  //           <Text style={styles.billTo}>School: Ebenezer Schools</Text>
  //           <Text style={styles.billTo}>Run By: {username}</Text>
  //         </View>
  //         <View style={styles.row}>
  //           <Text style={styles.description}>Employee Napsa Account</Text>
  //           <Text style={styles.description}>Date (Today)</Text>
  //           <Text style={styles.description}>Social Security Number</Text>
  //           <Text style={styles.description}>NRC Number</Text>
  //           <Text style={styles.description}>Account Number</Text>
  //           <Text style={styles.description}>Bank Name</Text>
  //           <Text style={styles.description}>Bank Branch</Text>
  //           <Text style={styles.description}>Surname</Text>
  //           <Text style={styles.description}>First Name</Text>
  //           <Text style={styles.description}>Date Of Birth</Text>
  //           <Text style={styles.description}>TPIN</Text>
  //           <Text style={styles.description}>Campus</Text>
  //           <Text style={styles.description}>Basic Pay</Text>
  //           <Text style={styles.description}>Special Allowance</Text>
  //           <Text style={styles.description}>Arrears</Text>
  //           <Text style={styles.description}>Gratuity</Text>
  //         </View>
  //         {employees.map((employee: Employee) => (
  //           <>
  //             {employee?.status[employee.status.length - 1]?.status ===
  //               "Active" ||
  //             employee?.status[employee.status.length - 1]?.status ===
  //               "Probation" ? (
  //               <>
  //                 {employee.allowances.length > 3 ? (
  //                   <>
  //                     <View style={styles.row4}>
  //                       <Text style={styles.description4}>
  //                         {employee.napsa}
  //                       </Text>
  //                       <Text style={styles.description4}>{today()}</Text>
  //                       <Text style={styles.description4}>
  //                         {employee.pensionno}
  //                       </Text>
  //                       <Text style={styles.description4}>{employee.nrc}</Text>
  //                       <Text style={styles.description4}>
  //                         {employee.bankaccount}
  //                       </Text>
  //                       <Text style={styles.description4}>
  //                         {employee.bankname}
  //                       </Text>
  //                       <Text style={styles.description4}>
  //                         {employee.bankbranch}
  //                       </Text>
  //                       <Text style={styles.description4}>
  //                         {employee.lastname}
  //                       </Text>
  //                       <Text style={styles.description4}>
  //                         {employee.firstname}
  //                       </Text>
  //                       <Text style={styles.description4}>{employee.dob}</Text>
  //                       <Text style={styles.description4}>
  //                         {employee.zratpin}
  //                       </Text>
  //                       <Text style={styles.description4}>
  //                         {employee.campus}
  //                       </Text>
  //                       <Text style={styles.description4}>
  //                         K{employee.salary}
  //                       </Text>
  //                       <Text style={styles.description4}>
  //                         {displayAllowances(employee)}
  //                       </Text>
  //                       <Text style={styles.description4}></Text>
  //                       <Text style={styles.description4}>
  //                         {calculateGratuity(employee)}%
  //                       </Text>
  //                     </View>
  //                   </>
  //                 ) : (
  //                   <>
  //                     {employee.allowances.length > 2 ? (
  //                       <>
  //                         <View style={styles.row3}>
  //                           <Text style={styles.description3}>
  //                             {employee.napsa}
  //                           </Text>
  //                           <Text style={styles.description3}>{today()}</Text>
  //                           <Text style={styles.description3}>
  //                             {employee.pensionno}
  //                           </Text>
  //                           <Text style={styles.description3}>
  //                             {employee.nrc}
  //                           </Text>
  //                           <Text style={styles.description3}>
  //                             {employee.bankaccount}
  //                           </Text>
  //                           <Text style={styles.description3}>
  //                             {employee.bankname}
  //                           </Text>
  //                           <Text style={styles.description3}>
  //                             {employee.bankbranch}
  //                           </Text>
  //                           <Text style={styles.description3}>
  //                             {employee.lastname}
  //                           </Text>
  //                           <Text style={styles.description3}>
  //                             {employee.firstname}
  //                           </Text>
  //                           <Text style={styles.description3}>
  //                             {employee.dob}
  //                           </Text>
  //                           <Text style={styles.description3}>
  //                             {employee.zratpin}
  //                           </Text>
  //                           <Text style={styles.description3}>
  //                             {employee.campus}
  //                           </Text>
  //                           <Text style={styles.description3}>
  //                             K{employee.salary}
  //                           </Text>
  //                           <Text style={styles.description3}>
  //                             {displayAllowances(employee)}
  //                           </Text>
  //                           <Text style={styles.description3}></Text>
  //                           <Text style={styles.description3}>
  //                             {calculateGratuity(employee)}%
  //                           </Text>
  //                         </View>
  //                       </>
  //                     ) : (
  //                       <>
  //                         {employee.allowances.length > 1 ? (
  //                           <>
  //                             <View style={styles.row2}>
  //                               <Text style={styles.description2}>
  //                                 {employee.napsa}
  //                               </Text>
  //                               <Text style={styles.description2}>
  //                                 {today()}
  //                               </Text>
  //                               <Text style={styles.description2}>
  //                                 {employee.pensionno}
  //                               </Text>
  //                               <Text style={styles.description2}>
  //                                 {employee.nrc}
  //                               </Text>
  //                               <Text style={styles.description2}>
  //                                 {employee.bankaccount}
  //                               </Text>
  //                               <Text style={styles.description2}>
  //                                 {employee.bankname}
  //                               </Text>
  //                               <Text style={styles.description2}>
  //                                 {employee.bankbranch}
  //                               </Text>
  //                               <Text style={styles.description2}>
  //                                 {employee.lastname}
  //                               </Text>
  //                               <Text style={styles.description2}>
  //                                 {employee.firstname}
  //                               </Text>
  //                               <Text style={styles.description2}>
  //                                 {employee.dob}
  //                               </Text>
  //                               <Text style={styles.description2}>
  //                                 {employee.zratpin}
  //                               </Text>
  //                               <Text style={styles.description2}>
  //                                 {employee.campus}
  //                               </Text>
  //                               <Text style={styles.description2}>
  //                                 K{employee.salary}
  //                               </Text>
  //                               <Text style={styles.description2}>
  //                                 {displayAllowances(employee)}
  //                               </Text>
  //                               <Text style={styles.description2}></Text>
  //                               <Text style={styles.description2}>
  //                                 {calculateGratuity(employee)}%
  //                               </Text>
  //                             </View>
  //                           </>
  //                         ) : (
  //                           <>
  //                             <View style={styles.row}>
  //                               <Text style={styles.description}>
  //                                 {employee.napsa}
  //                               </Text>
  //                               <Text style={styles.description}>
  //                                 {today()}
  //                               </Text>
  //                               <Text style={styles.description}>
  //                                 {employee.pensionno}
  //                               </Text>
  //                               <Text style={styles.description}>
  //                                 {employee.nrc}
  //                               </Text>
  //                               <Text style={styles.description}>
  //                                 {employee.bankaccount}
  //                               </Text>
  //                               <Text style={styles.description}>
  //                                 {employee.bankname}
  //                               </Text>
  //                               <Text style={styles.description}>
  //                                 {employee.bankbranch}
  //                               </Text>
  //                               <Text style={styles.description}>
  //                                 {employee.lastname}
  //                               </Text>
  //                               <Text style={styles.description}>
  //                                 {employee.firstname}
  //                               </Text>
  //                               <Text style={styles.description}>
  //                                 {employee.dob}
  //                               </Text>
  //                               <Text style={styles.description}>
  //                                 {employee.zratpin}
  //                               </Text>
  //                               <Text style={styles.description}>
  //                                 {employee.campus}
  //                               </Text>
  //                               <Text style={styles.description}>
  //                                 K{employee.salary}
  //                               </Text>
  //                               <Text style={styles.description}>
  //                                 {displayAllowances(employee)}
  //                               </Text>
  //                               <Text style={styles.description}></Text>
  //                               <Text style={styles.description}>
  //                                 {calculateGratuity(employee)}%
  //                               </Text>
  //                             </View>
  //                           </>
  //                         )}
  //                       </>
  //                     )}
  //                   </>
  //                 )}
  //               </>
  //             ) : (
  //               <></>
  //             )}
  //           </>
  //         ))}
  //       </Page>
  //     </Document>
  //   );
  // }

  const borderColor = "#000000";

  const styles = StyleSheet.create({
    page: {
      fontFamily: "Helvetica",
      fontSize: 11,
      paddingTop: 30,
      paddingLeft: 60,
      paddingRight: 60,
      lineHeight: 1.5,
      flexDirection: "column",
    },
    row: {
      flexDirection: "row",
      borderBottomColor: "#000000",
      borderBottomWidth: 1,
      borderTopColor: "#000000",
      borderTopWidth: 1,
      alignItems: "center",
      height: 24,
      fontStyle: "bold",
    },
    row2: {
      flexDirection: "row",
      borderBottomColor: "#000000",
      borderBottomWidth: 1,
      borderTopColor: "#000000",
      borderTopWidth: 1,
      alignItems: "center",
      height: 50,
      fontStyle: "bold",
    },
    row3: {
      flexDirection: "row",
      borderBottomColor: "#000000",
      borderBottomWidth: 1,
      borderTopColor: "#000000",
      borderTopWidth: 1,
      alignItems: "center",
      height: 60,
      fontStyle: "bold",
    },
    row4: {
      flexDirection: "row",
      borderBottomColor: "#000000",
      borderBottomWidth: 1,
      borderTopColor: "#000000",
      borderTopWidth: 1,
      alignItems: "center",
      height: 75,
      fontStyle: "bold",
    },
    container: {
      flexDirection: "row",
      borderBottomColor: "#000000",
      borderBottomWidth: 1,
      alignItems: "center",
      height: 24,
      textAlign: "center",
      fontStyle: "bold",
      flexGrow: 1,
    },
    description: {
      width: "6.25%",
      borderRightColor: borderColor,
      borderLeftColor: borderColor,
      borderRightWidth: 1,
      borderLeftWidth: 1,
      height: 24,
      paddingLeft: 2,
      paddingTop: 5,
    },
    description2: {
      width: "6.25%",
      borderRightColor: borderColor,
      borderLeftColor: borderColor,
      borderRightWidth: 1,
      borderLeftWidth: 1,
      height: 50,
      paddingLeft: 2,
      paddingTop: 5,
    },
    description3: {
      width: "6.25%",
      borderRightColor: borderColor,
      borderLeftColor: borderColor,
      borderRightWidth: 1,
      borderLeftWidth: 1,
      height: 60,
      paddingLeft: 2,
      paddingTop: 5,
    },
    description4: {
      width: "6.25%",
      borderRightColor: borderColor,
      borderLeftColor: borderColor,
      borderRightWidth: 1,
      borderLeftWidth: 1,
      height: 75,
      paddingLeft: 2,
      paddingTop: 5,
    },
    allowance: {
      width: "6.25%",
      borderRightColor: borderColor,
      borderLeftColor: borderColor,
      borderRightWidth: 1,
      borderLeftWidth: 1,
      height: 24,
      paddingLeft: 2,
      paddingTop: 5,
    },
    billTo: {
      paddingBottom: 5,
      fontFamily: "Helvetica-Oblique",
    },
  });

  const InvoiceTableHeader = () => (
    <View style={styles.container}>
      <Text style={styles.description}>Item Description</Text>
      <Text style={styles.amount}>Amount</Text>
    </View>
  );

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  return (
    <PermissionsGate scopes={[SCOPES.canDelete]}>
      <Dialog open={openLoadDialog} keepMounted>
        <DialogContent>
          <CircularProgress></CircularProgress>
        </DialogContent>
      </Dialog>
      <React.Fragment>
        <section className="bodybackground ">
          <div>
            {/* <Dialog open={openPayroll} onClose={setopenPayroll}>
              <>
                <PDFViewer style={{ height: 600, width: 425 }}>
                  <InvoiceDoc />
                </PDFViewer>
                <PDFDownloadLink
                  document={<InvoiceDoc />}
                  fileName="registration-invoice.pdf"
                  style={{
                    maxWidth: 172,
                    display: "flex",
                    alignSelf: "center",
                  }}
                >
                  {({ blob, url, loading, error }) =>
                    loading ? (
                      "Loading document..."
                    ) : (
                      <Button
                        startIcon={<DownloadIcon />}
                        variant="contained"
                        color="success"
                        size="small"
                      >
                        Download Report
                      </Button>
                    )
                  }
                </PDFDownloadLink>
              </>
            </Dialog> */}
            <Grid
              item
              container
              className="listbackground"
              direction="column"
              justifyContent="space-between"
              alignItems="stretch"
              rowSpacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid container item xs={6}>
                <SettingsIcon fontSize="large" color="primary" />
                <Typography variant="h4">Settings</Typography>
              </Grid>

              <Grid item xs={6} alignItems="start">
                {ManageFeeButton(manageFeeClicked)}
              </Grid>
              <Grid item xs={6} alignItems="start">
                {ManageEnrolmentsButton(manageEnrolmentsClicked)}
              </Grid>
            </Grid>
          </div>
        </section>
      </React.Fragment>
    </PermissionsGate>
  );
}

export default Invoice;
