import { useEffect, useState } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Button,
  CardActions,
  CardContent,
  Divider,
  Grid,
  Typography,
  styled,
} from "@mui/material";

import SkeletonUpdate from "./ui-component/cards/Skeleton/PopularCard";

// project imports
import MainCard from "./ui-component/cards/MainCard";

// assets
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import { getAllEmployees } from "../../util/api-calls";

// ==============================|| DASHBOARD DEFAULT - POPULAR CARD ||============================== //
const CardWrapper = styled(MainCard)(({ theme }) => ({
  overflow: "hidden",
  position: "relative",
  marginLeft: "10px",
  marginTop: "10px",
  borderRadius: "10px",
}));

function EmployeeUpdate(updateDashboard, employees) {
  const theme = useTheme();
  const [loading, setLoading] = useState(true);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };



  const [value, onChange] = useState(new Date());
  const [sortedEmployees, setSortedEmployees] = useState({});

  useEffect(() => {
    setLoading(true);
    // getAllEmployees().then((employees) => {
      if(employees.length > 0){
      const sortedUpdatedAt = employees
        .slice()
        .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
      setSortedEmployees(sortedUpdatedAt);
      setLoading(false);
    // });
      }
  }, [updateDashboard, employees]);

  const parseDate = (employee) => {
    let date = new Date(employee?.updatedAt);
    date =  date.toLocaleString("fr-CH", {
      timeZone: "Africa/Johannesburg",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
      hour12: false,
    });
    return date
    //return (date.getDate() + "/" + ("0" + (date.getMonth() + 1)).slice(-2) + "/" + date.getFullYear() + " " + ("0" + (date.getHours() + 1)).slice(-2) + ":" + ("0" + (date.getMinutes() + 1)).slice(-2));
  };

  return (
    <>
      {loading ? (
        <SkeletonUpdate />
      ) : (
        <CardWrapper border={false} content={false}>
          <CardContent>
            <Grid>
              <Grid item>
                <Grid container justifyContent="space-between">
                  <Grid item xs={0} marginLeft={1}>
                    <Avatar
                      variant="rounded"
                      sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.largeAvatar,
                        backgroundColor: "#7E57C2",
                        color: "#fff",
                        height: 30,
                        width: 30,
                      }}
                    >
                      <NewspaperIcon />
                    </Avatar>
                  </Grid>
                  <Grid item xs={9} marginTop={0.1}>
                    <Typography variant="h6">Employee Updates</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Divider sx={{ my: 1.5 }} />
              <Grid item xs={12}>
                <Grid container direction="column">
                  <Grid item>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Grid item>
                        <Typography
                          variant="subtitle1"
                          color="inherit"
                          fontSize="16px"
                        >
                          {sortedEmployees[0]?.firstname}{" "}
                          {sortedEmployees[0]?.lastname}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Grid item>
                            <Typography
                              variant="subtitle1"
                              color="inherit"
                              fontSize="16px"
                            >
                              {parseDate(sortedEmployees[0])}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Grid item>
                        <Typography
                          variant="subtitle2"
                          fontSize="13px"
                          sx={{ color: "#1565C0" }}
                        >
                          {sortedEmployees[0]?.number}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Grid item>
                            <Typography
                              variant="subtitle1"
                              fontSize="13px"
                              color="inherit"
                            >
                              By {sortedEmployees[0]?.updatedBy}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Divider sx={{ my: 1.5 }} />
                <Grid container direction="column">
                  <Grid item>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Grid item>
                        <Typography
                          variant="subtitle1"
                          color="inherit"
                          fontSize="16px"
                        >
                          {sortedEmployees[1]?.firstname}{" "}
                          {sortedEmployees[1]?.lastname}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Grid item>
                            <Typography
                              variant="subtitle1"
                              color="inherit"
                              fontSize="16px"
                            >
                              {parseDate(sortedEmployees[1])}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Grid item>
                        <Typography
                          variant="subtitle2"
                          fontSize="13px"
                          sx={{ color: "#1565C0" }}
                        >
                          {sortedEmployees[1]?.number}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Grid item>
                            <Typography
                              variant="subtitle1"
                              fontSize="13px"
                              color="inherit"
                            >
                              By {sortedEmployees[1]?.updatedBy}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Divider sx={{ my: 1.5 }} />
                <Grid container direction="column">
                  <Grid item>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Grid item>
                        <Typography
                          variant="subtitle1"
                          color="inherit"
                          fontSize="16px"
                        >
                          {sortedEmployees[2]?.firstname}{" "}
                          {sortedEmployees[2]?.lastname}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Grid item>
                            <Typography
                              variant="subtitle1"
                              color="inherit"
                              fontSize="16px"
                            >
                              {parseDate(sortedEmployees[2])}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Grid item>
                        <Typography
                          variant="subtitle2"
                          fontSize="13px"
                          sx={{ color: "#1565C0" }}
                        >
                          {sortedEmployees[2]?.number}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Grid item>
                            <Typography
                              variant="subtitle1"
                              color="inherit"
                              fontSize="13px"
                            >
                              By {sortedEmployees[2]?.updatedBy}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </CardWrapper>
      )}
    </>
  );
};

export default EmployeeUpdate;
