import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { InvoiceData } from "./invoice-data";

const styles = StyleSheet.create({
  headerContainer: {
    marginTop: 0,
  },
  billTo: {
    marginTop: 20,
    paddingBottom: 3,
    fontFamily: "Helvetica-Oblique",
  },
});

function studentName(invoice: InvoiceData) {
  console.log(invoice);
}

const BillTo = ({ invoice }: { invoice: InvoiceData }) => (
  <View style={styles.headerContainer}>
    <Text style={styles.billTo}>Bill To: {invoice?.company}</Text>
    <Text>
      Student:{" "}
      {((invoice?.student?.firstname? invoice?.student?.firstname:invoice?.studentName) +
        " " +
        (invoice?.student?.lastname? invoice?.student?.lastname:""))}
    </Text>
    {/* <Text>{invoice?.address}</Text>
        <Text>{invoice?.phone}</Text>
        <Text>{invoice?.email}</Text> */}
  </View>
);

export default BillTo;
