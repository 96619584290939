import React from 'react';
import {View, StyleSheet } from '@react-pdf/renderer';
import InvoiceTableHeader from './invoice-table-components/invoice-table-header';
import InvoiceTableRow from './invoice-table-components/invoice-table-row';
import { InvoiceData } from './invoice-data';
import InvoiceTableBlankSpace from './invoice-table-components/invoice-table-blank-space';
import InvoiceTableFooter from './invoice-table-components/invoice-table-footer';

const tableRowsCount = 4;

const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 24,
        borderWidth: 1,
        borderColor: '#bff0fd',
    },
});

  const InvoiceItemsTable = ({invoice} : {invoice: InvoiceData}) => (
    <View style={styles.tableContainer}>
        <InvoiceTableHeader />
        <InvoiceTableRow items={invoice?.items} />
        <InvoiceTableFooter items={invoice?.items} />
    </View>
  );
  
  export default InvoiceItemsTable