import { useState, useEffect } from "react";
import { Amplify, Auth, Hub } from "aws-amplify";
import "@aws-amplify/ui-react/styles.css";
import awsExports from "../../aws-exports";
import { useNavigate } from "react-router";
import KozoLogo from "../../assets/img/images/Kozo_Logo.png";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {
  Button,
  Divider,
  Grid,
  Typography,
  TextField,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React from "react";
import DescriptionIcon from "@mui/icons-material/Description";
import NumberFormat from "react-number-format";
import ReactCountryFlag from "react-country-flag";
import { getAllStudents } from "../../util/api-calls";
import { Student } from "../customer/customerlist";
import InvoiceModal from "../editcontact/invoicemodal";
import { Enrolment } from "../../util/data-models";
import {
  InvoiceData,
  ReceiptData,
} from "../editcontact/pdfgenerator/invoice-data";
import ReceiptModal from "../editcontact/receiptmodal";
import ReceiptTableFooter from "../editcontact/pdfgenerator/invoice-table-components/receipt-table-footer";
Amplify.configure(awsExports);

function ParentAccess(student: Student | any) {
  const navigate = useNavigate();
  const [errormsg, updateError] = useState("");
  const [invoiceFlag, updateinvoiceFlag] = useState(false);
  const [receiptFlag, updateReceiptFlag] = useState(false);

  const [isEnroled, setIsEnroled] = useState(false);

  const [openLoadDialog, setOpenLoadDialog] = React.useState(false);
  const [countDown, setCountDown] = React.useState(0);
  const [runTimer, setRunTimer] = React.useState(false);
  const closeLoadDialog = () => {
    setOpenLoadDialog(false);
  };
  const handleOpenLoadDialog = () => {
    setOpenLoadDialog(true);
  };

  React.useEffect(() => {
    let timerId: any;
    if (runTimer) {
      setCountDown(60 * 5);
      timerId = setInterval(() => {
        setCountDown((countDown) => countDown - 1);
      }, 1000);
    } else {
      clearInterval(timerId);
    }

    return () => clearInterval(timerId);
  }, [runTimer]);

  React.useEffect(() => {
    if (countDown < 0 && runTimer) {
      setRunTimer(false);
      setCountDown(0);
    }
  }, [countDown, runTimer]);

  const togglerTimer = () => setRunTimer((t) => !t);

  const seconds = String(countDown % 60).padStart(2, 0);
  const minutes = String(Math.floor(countDown / 60)).padStart(2, 0);

  useEffect(() => {
    if (student?.enrolments[student?.enrolments.length - 1]) {
      setIsEnroled(true);
    }
    if (window.location.pathname === "/parent-access") {
      setRunTimer(true);
      setTimeout(() => {
        if (window.location.pathname === "/parent-access") {
          navigate("/parent-signin");
        }
      }, 300000);
    }
  }, [student]);

  function today() {
    let date = new Date();
    return (
      date.getFullYear() +
      "-" +
      ("0" + (new Date().getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + (new Date().getDate() + 1)).slice(-2)
    ).toString();
  }

  async function signIn() {}

  function getCurrentSchoolYear() {
    const date = new Date();
    let year = date.getFullYear();

    if (date.getMonth() === 11) {
      // Month in JavaScript is 0-indexed, so 11 is December
      year += 1;
    }
    return year.toString();
  }

  function getInvoiceData(): InvoiceData {
    const currentDate = new Date().toISOString().slice(0, 10);
    let createdDate = currentDate;
    console.log(student?.enrolments);
    if (student?.enrolments[student?.enrolments.length - 1]?.createdAt) {
      createdDate = new Date(
        student?.enrolments[student?.enrolments.length - 1]?.createdAt
      )
        ?.toISOString()
        ?.slice(0, 10);
    }

    return {
      studentName: student?.firstname + " " + student?.lastname,
      student: student,
      id: student?.enrolments[student?.enrolments.length - 1]?.id,
      invoice_no:
        currentDate.replace(/-/g, "") +
        student?.enrolments[student?.enrolments.length - 1]?.id.slice(0, 4),
      company:
        student?.guardians[0]?.firstname +
        " " +
        student?.guardians[0]?.lastname,
      email: student?.guardians[0]?.email,
      phone: student?.guardians[0]?.phone,
      address: student?.guardians[0]?.address,
      trans_date: createdDate,
      items: [
        {
          item_num: 0,
          desc:
            "Enrolment fee for " +
            student?.enrolments[student?.enrolments.length - 1]?.grade,
          qty: 1,
          rate: student?.enrolments[student?.enrolments.length - 1]
            ?.totalAmount,
          discount:
            student?.enrolments[student?.enrolments.length - 1]?.discount,
        },
      ],
    };
  }

  function getReceiptData(): ReceiptData {
    const currentDate = new Date().toISOString().slice(0, 10);
    let createdDate = currentDate;
    if (student?.enrolments[student?.enrolments.length - 1]?.createdAt) {
      createdDate = new Date(
        student?.enrolments[student?.enrolments.length - 1]?.createdAt
      )
        ?.toISOString()
        ?.slice(0, 10);
    }
    return {
      student: student,
      id: student?.enrolments[student?.enrolments.length - 1]?.id,
      invoice_no:
        currentDate.replace(/-/g, "") +
        student?.enrolments[student?.enrolments.length - 1]?.id.slice(0, 4),
      company:
        student?.guardians[0]?.firstname +
        " " +
        student?.guardians[0]?.lastname,
      email: student?.guardians[0]?.email,
      phone: student?.guardians[0]?.phone,
      address: student?.guardians[0]?.address,
      trans_date: createdDate,
      items: [
        {
          item_num: 0,
          desc:
            "Enrolment fee for " +
            student?.enrolments[student?.enrolments.length - 1]?.grade,
          qty: 1,
          rate: student?.enrolments[student?.enrolments.length - 1]
            ?.totalAmount,
          discount:
            student?.enrolments[student?.enrolments.length - 1]?.discount,
        },
      ],
      payments: student?.enrolments[student?.enrolments.length - 1]?.payments,
    };
  }

  return (
    <div className="parentaccesspage">
      <Dialog open={openLoadDialog} keepMounted>
        <DialogContent>
          <CircularProgress></CircularProgress>
        </DialogContent>
      </Dialog>
      <Grid
        item
        className="row "
        style={{
          float: "right",
        }}
        xs={3}
      >
        <Typography
          style={{
            float: "right",
            textAlign: "left",
            marginTop: 80,
            fontSize: 16,
            marginRight: 50,
          }}
        >
          This session will expire in: {minutes}:{seconds}
        </Typography>
      </Grid>
      <Grid item className="row parentaccessheader" xs={3}>
        <Typography
          style={{
            marginTop: 100,
          }}
          variant="h4"
        >
          Welcome to KOZO
        </Typography>
        <Typography variant="h5" style={{}}>
          Parent Access
        </Typography>
      </Grid>

      <Divider
        style={{ marginLeft: 40, marginRight: 40, color: "#1565C0" }}
        sx={{ borderBottomWidth: 3, my: 1.5 }}
      />

      <div className="row">
        <div className="col" style={{ width: 400, maxWidth: 400 }}>
          <Grid item className=" parentaccessheader" xs={3}>
            <Typography
              style={{
                fontSize: 16,
              }}
            >
              Access invoices, receipts and school report cards for your child
              attending a KOZO serviced school
            </Typography>
          </Grid>
        </div>
        <div className="col" style={{ width: 350, maxWidth: 350 }}>
          <Grid item className="col" xs={3}>
            <Typography
              style={{
                textAlign: "left",
                marginLeft: 10,
                marginTop: 10,
              }}
              variant="h6"
            >
              Invoices & receipts
            </Typography>
            <Typography
              style={{
                textAlign: "left",
                marginLeft: 10,
                marginTop: 0,
              }}
              variant="h6"
            >
              for {student?.firstname + " " + student?.lastname}
            </Typography>
            <div
              style={{
                border: "1px solid black",
                borderRadius: 15,
                paddingBottom: 150,
                paddingTop: 3,
                paddingRight: 10,
                marginTop: 3,
              }}
            >
              <Typography
                style={{
                  textAlign: "left",
                  marginLeft: 10,
                  marginTop: 10,
                }}
                variant="h6"
              >
                SY '{getCurrentSchoolYear()}
              </Typography>
              <div
                style={{
                  alignContent: "right",
                }}
              >
                {isEnroled ? (
                  <>
                    <Button
                      startIcon={<DescriptionIcon />}
                      variant="contained"
                      color="secondary"
                      size="small"
                      style={{
                        marginLeft: 50,
                        float: "right",
                      }}
                      onClick={() => updateinvoiceFlag(true)}
                    >
                      View Invoice
                    </Button>
                  </>
                ) : (
                  <>No Invoices available</>
                )}

                <InvoiceModal
                  open={invoiceFlag}
                  onClose={() => updateinvoiceFlag(false)}
                  invoiceData={getInvoiceData()}
                />
                {isEnroled ? (
                  <>
                    <Button
                      style={{
                        marginTop: 10,
                        float: "right",
                      }}
                      startIcon={<DescriptionIcon />}
                      variant="contained"
                      color="secondary"
                      size="small"
                      onClick={() => updateReceiptFlag(true)}
                    >
                      View Payment Receipt
                    </Button>
                  </>
                ) : (
                  <></>
                )}

                <ReceiptModal
                  open={receiptFlag}
                  onClose={() => updateReceiptFlag(false)}
                  receiptData={getReceiptData()}
                />
              </div>
            </div>
          </Grid>
        </div>
        <div className="col" style={{ width: 350, maxWidth: 350 }}>
          <Grid item className="col" xs={3}>
            <Typography
              style={{
                textAlign: "left",
                marginLeft: 10,
                marginTop: 10,
              }}
              variant="h6"
            >
              Results & Report Cards
            </Typography>
            <Typography
              style={{
                textAlign: "left",
                marginLeft: 10,
                marginTop: 0,
              }}
              variant="h6"
            >
              for {student?.firstname + " " + student?.lastname}
            </Typography>
            <div
              style={{
                border: "1px solid black",
                borderRadius: 15,
                paddingBottom: 150,
                paddingTop: 3,
                paddingRight: 10,
                marginTop: 3,
                backgroundColor: "#eeeeee",
              }}
            >
              <Typography
                style={{
                  textAlign: "left",
                  marginLeft: 10,
                  marginTop: 10,
                }}
                variant="h6"
              >
                TBD
              </Typography>
            </div>
          </Grid>
        </div>
      </div>
    </div>
  );
}

export default ParentAccess;
