import { useState, useRef, useEffect } from "react";

import { useNavigate } from "react-router-dom";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  ClickAwayListener,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  InputAdornment,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  OutlinedInput,
  Paper,
  Popper,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

// third-party
import PerfectScrollbar from "react-perfect-scrollbar";

import MainCard from "../dashboard/ui-component/cards/MainCard";
import Transitions from "../dashboard/ui-component/extended/Transitions";
import SyncLockIcon from "@mui/icons-material/SyncLock";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

// project imports

// assets
import { IconLogout, IconSearch, IconSettings, IconUser } from "@tabler/icons";
import { Auth } from "aws-amplify";
import React from "react";
import { calculateNewValue } from "@testing-library/user-event/dist/utils";

// ==============================|| PROFILE MENU ||============================== //

function ProfileSection() {
  const [username, setusername] = useState("");

  useEffect(() => {
    getName().then((name) => {
      setusername(name);
    });
  });

  async function getName() {
    let result = "";
    try {
      const user = await Auth.currentAuthenticatedUser();
      if(user.pool.userPoolId === "ca-central-1_TxjoIawIu"){
        result = user.attributes.email;
      } else {
        
        result = user.username;
      }
    } catch (err) {
      console.log(err);
    }
    return result;
  }
  const theme = useTheme();
  const navigate = useNavigate();

  const [sdm, setSdm] = useState(true);
  const [value, setValue] = useState("");
  const [notification, setNotification] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [open, setOpen] = useState(false);
  const [CancelEditPopUp, setCancelEditPopUp] = React.useState(false);

  const [password, setPassword] = React.useState("");
  const [newpassword, setNewPassword] = React.useState("");
  const [confirmnewpassword, setConfirmNewPassword] = React.useState("");
  const [errormsg, setErrorMsg] = React.useState("");
  const [isNewPasswordConfirmed, setIsNewPasswordConfirmed] =
    React.useState(false);
  /**
   * anchorRef is used on different componets and specifying one type leads to other components throwing an error
   * */
  const anchorRef = useRef(null);
  const handleLogout = async () => {
    console.log("Logout");
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleListItemClick = (event, index, route = "") => {
    setSelectedIndex(index);
    handleClose(event);

    if (route && route !== "") {
      navigate(route);
    }
  };
  const handleToggle = () => {
    setOpen(true);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  });

  const handleCancelEdit = () => {
    setPassword("");
    setNewPassword("");
    setConfirmNewPassword("");
    setErrorMsg("");
    setIsNewPasswordConfirmed(false);

    setCancelEditPopUp(false);
  };

  const handleOpenEdit = () => {
    setPassword("");
    setNewPassword("");
    setConfirmNewPassword("");
    setErrorMsg("");
    setCancelEditPopUp(true);
  };

  async function changePassword() {

    setErrorMsg("");

    if(confirmnewpassword === "") {
      setErrorMsg("Confirmation password is empty");
    } else if(confirmnewpassword != newpassword){
      setErrorMsg("Confirmation password and new password do not match");
    } else if(newpassword === password) {
      setErrorMsg("New password must be different than old password");
    } else {
      Auth.currentAuthenticatedUser()
      .then((user) => {
        return Auth.changePassword(user, password, newpassword);
      })
      .then((data) => {
        // at this time the user is logged in if no MFA required
        console.log(data);
        setIsNewPasswordConfirmed(true);
      })
      .catch((e) => {
        console.log(e);
        const [first, ...rest] = e.toString().split(":");
        const remainder = rest.join(":");
        if(e.toString().includes("Incorrect username or password")){
          setErrorMsg("Error: Incorrect password.");
        } else if(e.toString().includes("Member must satisfy regular expression pattern")){
          setErrorMsg("Error: Password must contain a character such as: !, @, #, $, ?.");
        } else {
          setErrorMsg(remainder);
        }
      });
    }

    
  }

  function updatePassword(e) {
    e.persist();
    setPassword(e.target.value);
  }

  function updateNewPassword(e) {
    e.persist();
    setNewPassword(e.target.value);
  }

  function updateConfirmNewPassword(e) {
    e.persist();
    setConfirmNewPassword(e.target.value);
  }

  return (
    <>
      <Dialog
        open={CancelEditPopUp}
        keepMounted
        onClose={handleCancelEdit}
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="changePasswordblock">
          {isNewPasswordConfirmed ? (
            <>
              <Grid item marginTop={5}>
                <Typography
                  style={{
                    color: "rgb(103, 58, 183)",
                  }}
                  variant="h5"
                >
                  Your password was updated!
                </Typography>
              </Grid>
              <Grid
                item
                marginTop={5}
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <CheckCircleOutlineIcon sx={{ fontSize: 60,
                marginLeft: "calc(50% - 30px)" }} />
              </Grid>
              {errormsg && (
                <Grid item marginTop={5}>
                  <Typography
                    style={{
                      color: "#FF0000",
                    }}
                    variant="h7"
                  >
                    {errormsg}
                  </Typography>
                </Grid>
              )}
              <Grid item marginTop={4}>
                <Button
                  onClick={handleCancelEdit}
                  size="large"
                  type="submit"
                  variant="contained"
                  style={{
                    marginLeft: -12,
                    // backgroundColor: "rgb(103, 58, 183)",
                    width: 340,
                  }}
                >
                  Ok
                </Button>
              </Grid>
            </>
          ) : (
            <>
              <Grid item marginTop={5}>
                <Typography
                  style={{
                    color: "rgb(103, 58, 183)",
                  }}
                  variant="h5"
                >
                  Change your password
                </Typography>
              </Grid>
              {errormsg && (
                <Grid item marginTop={5}>
                  <Typography
                    style={{
                      color: "#FF0000",
                    }}
                    variant="h7"
                  >
                    {errormsg}
                  </Typography>
                </Grid>
              )}
              <Divider sx={{ my: 1.5 }} />
              <Grid item marginTop={5}>
                <Typography variant="h7">
                  Enter your current password followed by the new password
                </Typography>
              </Grid>
              <Grid item marginTop={2}>
                <div className="row input-container">
                  <TextField
                    id="outlined-adornment-password-login"
                    type="password"
                    name="password"
                    onChange={updatePassword}
                    defaultValue={password}
                    label="Password"
                    inputProps={{}}
                    className="signinfields regularTextField"
                  ></TextField>
                </div>
                <div className="row input-container">
                  <TextField
                    style={{
                      marginTop: 5,
                    }}
                    id="outlined-adornment-newpassword-login"
                    type="password"
                    name="newpassword"
                    onChange={updateNewPassword}
                    defaultValue={newpassword}
                    label="New Password"
                    inputProps={{}}
                    className="signinfields regularTextField"
                  ></TextField>
                </div>
                <div className="row input-container">
                  <TextField
                    style={{
                      marginTop: 5,
                    }}
                    id="outlined-adornment-confirmnewpassword-login"
                    type="password"
                    name="confirmnewpassword"
                    onChange={updateConfirmNewPassword}
                    defaultValue={confirmnewpassword}
                    label="Confirm New Password"
                    inputProps={{}}
                    className="signinfields regularTextField"
                  ></TextField>
                </div>
              </Grid>
              <Grid item marginTop={4}>
                <Button
                  onClick={changePassword}
                  size="large"
                  type="submit"
                  variant="contained"
                  style={{
                    marginLeft: -12,
                    backgroundColor: "rgb(103, 58, 183)",
                    width: 340,
                  }}
                >
                  Confirm Password
                </Button>
              </Grid>
              <Grid item marginTop={4}>
                <Button
                  onClick={handleCancelEdit}
                  size="large"
                  type="submit"
                  variant="contained"
                  style={{
                    marginLeft: -12,
                    // backgroundColor: "rgb(103, 58, 183)",
                    width: 340,
                  }}
                >
                  Cancel
                </Button>
              </Grid>
            </>
          )}
        </div>
      </Dialog>

      <Chip
        sx={{
          overflow: "visible",
          marginRight: "10px",
          height: "30px",
          alignItems: "center",
          borderRadius: "27px",
          transition: "all .2s ease-in-out",
          borderColor: "#E8E8E8",
          backgroundColor: "#E8E8E8",
          color: "#000000",
          '&[aria-controls="menu-list-grow"], &:hover': {
            background: `${theme.palette.primary.main}!important`,
            color: theme.palette.primary.light,
            "& svg": {
              stroke: theme.palette.primary.light,
            },
          },
          "& .MuiChip-label": {
            lineHeight: 0,
          },
        }}
        label={username}
        variant="outlined"
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        color="primary"
      />
      <Popper
        sx={{
          borderRadius: "27px",
        }}
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 14],
              },
            },
          ],
        }}
      >
        <Transitions
          in={open}
          sx={{
            position: "absolute",
            top: "52px",
            right: "calc(27px - 100vw)",
          }}
        >
          <ClickAwayListener onClickAway={handleClose}>
            <MainCard
              border={false}
              elevation={0}
              content={false}
              boxShadow
              shadow={theme.shadows[16]}
              sx={{
                borderRadius: "20px",
                height: "96px",
                paddingTop: "0px",
                marginTop: "0px",
                backgroundColor: "rgba(0,0,0,0.0)",
              }}
            >
              <Box>
                <List
                  component="nav"
                  sx={{
                    width: "180px",
                    maxWidth: "180px",
                    minWidth: "180px",
                    backgroundColor: "rgba(0,0,0,0.0)",
                    borderRadius: "0px",
                  }}
                >
                  <ListItemButton
                    sx={{
                      borderRadius: "27px",
                      marginTop: "-8px",
                      backgroundColor: "#ffffff",
                    }}
                    selected={selectedIndex === 4}
                    onClick={() => {
                      handleOpenEdit();
                    }}
                  >
                    <SyncLockIcon>
                      <IconLogout stroke={1.5} size="1.3rem" />
                    </SyncLockIcon>
                    <ListItemText
                      primary={
                        <Typography variant="body2">Change Password</Typography>
                      }
                    />
                  </ListItemButton>
                  <ListItemButton
                    sx={{
                      borderRadius: "27px",

                      marginTop: "4px",
                      backgroundColor: "#ffffff",
                    }}
                    selected={selectedIndex === 4}
                    onClick={() => {
                      Auth.signOut();
                      setOpen(false);
                      navigate("/signin");
                    }}
                  >
                    <ListItemIcon>
                      <IconLogout stroke={1.5} size="1.3rem" />
                    </ListItemIcon>
                    <ListItemText
                      primary={<Typography variant="body2">Logout</Typography>}
                    />
                  </ListItemButton>
                </List>
              </Box>
            </MainCard>
          </ClickAwayListener>
        </Transitions>
      </Popper>
    </>
  );
}

export default ProfileSection;
