import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import React, { SetStateAction } from "react";
import { Student } from "../../util/data-models";
import Emoji from "../ui-elements/emoji";
import { validateYupSchema } from "formik";
import SearchIcon from "@mui/icons-material/Search";

class StudentListDialog extends React.Component<
  { open: boolean; onClose: (value: string) => void; students: Student[] },
  { students: Student[]; search: ""; originalList: Student[] }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      students: this.props.students,
      search: "",
      originalList: this.props.students,
    };
  }

  handleListItemClick = (value: string) => {
    this.props.onClose(value);
  };

  handleDialogClose = () => {
    this.props.onClose("");
  };

  filterStudents(students: Student[], searchTerm: string) {
    let result = [];
    result = students.filter((student: Student) => {
      let fullName = (
        student.firstname +
        " " +
        student.middlename +
        " " +
        student.lastname +
        student.studentid
      ).toLowerCase();

      return fullName.includes(searchTerm?.toLowerCase() ?? "");
    });
    this.setState({
      students: result,
    });
    this.forceUpdate();
  }

  setSearch(value: any) {
    this.setState({
      search: value,
    });
  }

  SearchBar(setSearch: React.Dispatch<SetStateAction<string>>) {
    return (
      <form>
        <div style={{ fontSize: 3, height: 50 }}>
          <TextField
            className="searchbarbackground regularTextField"
            id="outlined-basic"
            variant="outlined"
            fullWidth
            label="Search"
            onChange={(e) =>
              this.filterStudents(this.state.originalList, e.target.value)
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
      </form>
    );
  }

  stringToColor(string: string) {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    return color;
  }

  stringAvatar(name: string) {
    return {
      sx: {
        bgcolor: this.stringToColor(name),
        width: 45,
        height: 45,
        marginRight: 0,
        fontSize: 10,
      },
      children: `${name.split(" ")[0][0] || ""}${name.split(" ")[1][0] || ""}`,
    };
  }

  

  render() {
    const hasStudents = this.props?.students?.length > 0;
    return (
      <Dialog
        sx={{
          width: 900,
          minWidth: 900,

        }}
        open={this.props.open}
        onClose={this.props.onClose}
      >
        <>
          {hasStudents && <DialogTitle>Select student</DialogTitle>}
          <Grid item>{this.SearchBar(this.setSearch)}</Grid>
          <List sx={{
          width: 900,
          minWidth: 900,
          minHeight: "100vh",

        }}>
            {this.state?.students?.map((student) => (
              <ListItem
                button
                onClick={() => this.handleListItemClick(student.id)}
                key={student.id}
              >
                <ListItemAvatar>
                  <Avatar
                    {...this.stringAvatar(
                      student.firstname + " " + student.lastname
                    )}
                    style={{
                      float: "left",
                      objectFit: "cover",
                      objectPosition: "100%",
                      width: 40,
                      height: 40,
                      marginLeft: 0,
                      marginTop: 0,
                      marginBottom: 0,
                      boxShadow: "2px 4px 4px 2px rgba(0, 0, 0, 0.2)",
                      zIndex: 15,
                    }}
                    // sx={{ bgcolor: blue[100], color: blue[600] }}
                  >
                    {/* <PersonIcon /> */}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={student.firstname + " " + student.lastname}
                />
              </ListItem>
            ))}
          </List>
          {!hasStudents && (
            <>
              <Typography
                variant="subtitle1"
                style={{ maxWidth: 172, margin: 12 }}
              >
                <Emoji symbol="⚠️" label="warning" /> Please ensure there is a
                student linked to this parent so they can be registered.
              </Typography>
              <DialogActions>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => this.handleDialogClose()}
                >
                  OK
                </Button>
              </DialogActions>
            </>
          )}
        </>
      </Dialog>
    );
  }
}

export default StudentListDialog;
