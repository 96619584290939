import {
  Button,
  Divider,
  Grid,
  IconButton,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  InputAdornment,
} from "@mui/material";
import React from "react";
import { Allowance, EmployeeLeave } from "../../util/data-models";
import DeleteIcon from "@mui/icons-material/Delete";
import AddReactionIcon from "@mui/icons-material/AddReaction";
import { v4 as uuidv4 } from "uuid";
import AddIcon from "@mui/icons-material/Add";

class EditAllowance extends React.Component<
  {
    onAllowanceUpdate: (allowances: Allowance[]) => void;
    allowances: Allowance[];
  },
  { allowances: Allowance[] }
> {
  constructor(props: any) {
    super(props);
    this.state = { allowances: this.props.allowances };
  }

  createUI() {
    return this.state?.allowances?.map((allowance: Allowance, i) => (
      <React.Fragment key={allowance.id}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="stretch"
          spacing={2}
        >
          <Grid container item xs={11} spacing={2}>
            <Grid item xs={3}>
              <FormControl
                size="small"
                style={{
                  marginTop: 5,
                  display: "flex",
                  minWidth: 127,
                  flex: 1,
                  marginRight: 9,
                }}
              >
                <InputLabel id="allowancetype-label">Allowance Type</InputLabel>
                <Select
                  labelId="allowancetype-label"
                  id="allowancetype"
                  name="allowancetype"
                  value={allowance.allowancetype}
                  onChange={this.handleChange.bind(this, i)}
                  label="Allowance Type"
                >
                  <MenuItem value={"Lunch"}>Lunch</MenuItem>
                  <MenuItem value={"Housing"}>Housing</MenuItem>
                  <MenuItem value={"Transport"}>Transport</MenuItem>
                  <MenuItem value={"Tuition"}>Tuition</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={3}>
              <TextField
                size="small"
                style={{
                  marginTop: 5,
                  display: "flex",
                  minWidth: 127,
                  flex: 1,
                  marginRight: 9,
                }}
                label="Amount"
                fullWidth
                type="text"
                name={"allowanceamount"}
                value={allowance.allowanceamount || ""}
                onChange={this.handleChange.bind(this, i)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">K</InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={3}>
              <FormControl
                size="small"
                style={{
                  marginTop: 5,
                  display: "flex",
                  minWidth: 127,
                  flex: 1,
                  marginRight: 9,
                }}
              >
                <InputLabel id="allowancetime-label">Frequency</InputLabel>
                <Select
                  labelId="allowancetime-label"
                  id="allowancetime"
                  name="allowancetime"
                  value={allowance.allowancetime}
                  onChange={this.handleChange.bind(this, i)}
                  label="Allowance Frequency"
                >
                  <MenuItem value={"One Time"}>One Time</MenuItem>
                  <MenuItem value={"Monthly"}>Monthly</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={3}>
              <TextField
                size="small"
                style={{
                  marginTop: 5,
                  display: "flex",
                  minWidth: 127,
                  flex: 1,
                  marginRight: 9,
                }}
                label="Repayment Details"
                fullWidth
                type="text"
                name={"allowancerepayment"}
                value={allowance.allowancerepayment || ""}
                onChange={this.handleChange.bind(this, i)}
              />
            </Grid>
            <Grid item xs={4}></Grid>
          </Grid>
          <Grid
            container
            item
            direction="column"
            xs={1}
            justifyContent="center"
          >
            <IconButton
              onClick={this.removeClick.bind(this, i)}
              style={{ display: "flex" }}
              aria-label="delete"
              color="warning"
            >
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Divider style={{ marginTop: 12, marginBottom: 24 }}></Divider>
      </React.Fragment>
    ));
  }

  handleChange(i: number, event: React.ChangeEvent) {
    let allowances = [...this.state.allowances];
    const target = event.target as HTMLInputElement;
    allowances[i][event.target.name] = target.value;

    this.setState({ allowances });

    this.props.onAllowanceUpdate(allowances);
  }

  addClick() {
    this.setState((prevState) => ({
      allowances: [
        ...prevState.allowances,
        {
          id: uuidv4(),
          allowancetype: "",
          allowanceamount: "",
          allowancetime: "",
          allowancerepayment: "",
        },
      ],
    }));
  }

  removeClick(i: number) {
    let allowances = [...this.state.allowances];
    allowances.splice(i, 1);
    this.setState({ allowances });
    this.props.onAllowanceUpdate(allowances);
  }

  render() {
    return (
      <>
        {this.createUI()}
        <Button
          startIcon={<AddIcon />}
          style={{ marginBottom: 12 }}
          variant="contained"
          color="success"
          size="small"
          onClick={this.addClick.bind(this)}
        >
          Add Allowance
        </Button>
      </>
    );
  }
}

export default EditAllowance;
