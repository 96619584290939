import { PictureAsPdf } from "@mui/icons-material";
import { Divider, Grid, Typography } from "@mui/material";
import React from "react";
import { Enrolment, EnrolmentPayment } from "../../util/data-models";
import Emoji from "../ui-elements/emoji";

class ViewEnrolmentPayments extends React.Component<
  {
    enrolment: Enrolment;
  },
  {
    payments: EnrolmentPayment[];
    paymentProofModalFlags: boolean[];
  }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      payments: this.props.enrolment?.payments ?? [],
      paymentProofModalFlags: [],
    };
  }

  updateModalFlag(i: number, value: boolean) {
    let flags = this.state.paymentProofModalFlags;
    flags[i] = value;
    this.setState({
      ...this.state,
      paymentProofModalFlags: flags,
    });
  }

  createUI() {
    const hasUrlAlready = (p: EnrolmentPayment) =>
      !!p.paymentproof && p.paymentproof?.length > 0;

    return this.state?.payments?.map((e: EnrolmentPayment, i: number) => (
      <React.Fragment key={e.id}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="stretch"
          spacing={2}
          style={{ verticalAlign: "center" }}
        >
          <Grid item xs={7} alignSelf="center">
            <Typography
              style={{
                paddingLeft: 0,
                fontSize: 13,
                marginTop: 8,
              }}
              variant="body2"
            >
              Payment #{i + 1}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="start"
          alignItems="stretch"
          spacing={2}
        >
          <Grid item xs={3} alignSelf="center">
              <Typography
                style={{
                  paddingLeft: 0,
                  fontSize: 13,

                }}
                variant="body1"
              >
                Amount paid:
              </Typography>
          </Grid>

          <Grid item xs={1} alignSelf="center">
              <Typography variant="body2">K{e.amount}</Typography>
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="start"
          alignItems="stretch"
          spacing={2}
        >
          {hasUrlAlready(e) && (
            <>
              <Grid item xs={4} alignSelf="center">
                <Typography variant="subtitle2" style={{ marginBottom: 12 }}>
                  Proof of payment uploaded{" "}
                  <Emoji symbol="✅" label="checkmark"></Emoji>
                </Typography>
              </Grid>
              <Grid item xs={4} alignSelf="center">
                <a href={e.paymentproof}>
                  <PictureAsPdf />
                  View Proof
                </a>
              </Grid>
            </>
          )}
        </Grid>
        <Divider style={{ marginTop: 12, marginBottom: 12 }}></Divider>
      </React.Fragment>
    ));
  }

  render() {
    return (
      <>
        <Typography
          style={{
            paddingLeft: 0,
            fontSize: 16,
            marginTop: 10,
          }}
        >
          Payments
        </Typography>
        {this.createUI()}
      </>
    );
  }
}

export default ViewEnrolmentPayments;
