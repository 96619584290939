import { textAlign } from "@mui/system";

export default function Header({ handlePrint, logo, name, address1, address2, phone, email, website}) {
  return (
    <>
      <header className="flex flex-col items-center justify-center mb-5 xl:flex-row xl:justify-between">
      <div className="row">
      <div className="col">
      <img className="headerlogo"  src={logo} />
      </div>
        <div className="col header-right">
                 
        <br/> {name} <br/>
                 {address1}<br/>
                 {address2}<br/>
                 {phone}<br/>
                 {email}<br/>
                 {website}
                </div>
        </div>

        {/* <div>
          <ul className="flex items-center justify-between flex-wrap">
            <li>
              <button
                onClick={handlePrint}
                className="bg-gray-500 text-white font-bold py-2 px-8 rounded shadow border-2 border-gray-500 hover:bg-transparent hover:text-gray-500 transition-all duration-300"
              >
                Print
              </button>
            </li>
            <li className="mx-2">
              <button className="bg-blue-500 text-white font-bold py-2 px-8 rounded shadow border-2 border-blue-500 hover:bg-transparent hover:text-blue-500 transition-all duration-300">
                Download
              </button>
            </li>
            <li>
              <button className="bg-green-500 text-white font-bold py-2 px-8 rounded shadow border-2 border-green-500 hover:bg-transparent hover:text-green-500 transition-all duration-300">
                Send
              </button>
            </li>
          </ul>
        </div> */}
      </header>
    </>
  )
}
