import { stringList } from "aws-sdk/clients/datapipeline";

export interface Employeeprovince {
  id: number;
  firstname: string;
  lastname: string;
  middlename: string;
  maiden: string;
  nrc: string;
  gender: string;
  dob: string;
  address: string;
  town: string;
  province: string;
  number: string;
  email: string;
  nationality: string;
  languages: string;
  firstnamespouse: string;
  lastnamespouse: string;
  addressspouse: string;
  numberspouse: string;
  childrenfirstname: string;
  childrenlastname: string;
  childrengender: string;
  childrendob: string;
  fatherfirstname: string;
  fatherlastname: string;
  motherfirstname: string;
  motherlastname: string;
  familyaddress: string;
  familynumber: string;
  emergencyfirstname: string;
  emergencylastname: string;
  emergencyrelationship: string;
  emergencyaddress: string;
  emergencytown: string;
  emergencynumber: string;
  medicalquestion: string;
  medicalcomment: string;
  title: string;
  area: string;
  salary: string;
  datehire: string;
  contractlength: string;
  gratuity: string;
  bonus: string;
  pension: string;
}

export interface ParentGuardian {

  //id  
  id: number;

  //students of the guardians
  studentids: string;

  fathermother: string;
  parentlegalguardian: string;
  biologicaladoptedfoster: string;
  title: string;

  firstname: string;
  lastname: string;
  middlename: string;

  relationship: string;
  address: string;
  town: string;
  province: string;
  number: string;
  email: string;

  employer: string;
  business: string;
}

export interface Guardian {
  id: string;
  student_ids: string;
  fathermother: string;
  parentlegalguardian: string;
  biologicaladoptedfoster: string;
  title: string;
  firstname: string;
  lastname: string;
  middlename: string;
  relationship: string;
  address: string;
  town: string;
  province: string;
  number: string;
  email: string;
  phone: string;
  employer: string;
  business: string;
  students?: Student[];
  createdAt: Date;
  updatedAt: Date;
  registration?: Registration[];
}

export interface Student {
  id: string;
  firstname: string;
  lastname: string;
  middlename: string;
  gender: string;
  dob: string;
  address: string;
  town: string;
  province: string;
  number: string;
  email: string;
  nationality: string;
  language: string;
  grade: string;
  class: string;
  teacher: string;
  schoolfee: string;
  ptafee: string;
  pastinvoices: string;
  parentsguardians: string;
  lastschool: string;
  lastschoolcity: string;
  lastschoolprovince: string;
  lastschoolnumber: string;
  lastgradecompleted: string;
  emergencyfirstname: string;
  emergencylastname: string;
  emergencyrelationship: string;
  emergencyaddress: string;
  emergencytown: string;
  emergencynumber: string;
  medicalconditions: string;
  medicalallergies: string;
  medicalmedications: string;
  medicalother: string;
  parentsguardiansrelationship: string;
  custody: string;
  studentliveswith: string;
  alumni: string;
  transferredout: string;
  droppedout: string;
  leftstudentcomment: string;
  studentid: string;
  guardians: Guardian[];
  enrolments: Enrolment[];
  createdAt: string;
  updatedAt: string;
}

export interface Registration {
  id: string;
  status: string;
  discount: number;
  year: string;
  term: string;
  paymentproof: string;
  student_id: string;
  amount_due: number;
  amount_paid: number;
  type: string;
}

export interface Enrolment {
  id: string;
  status: string;
  discount: string;
  year: string;
  grade: string;
  amountOwing: number;
  totalAmount: number;
  payments: EnrolmentPayment[];
  createdAt: Date;
}

export interface EnrolmentPayment {
  id: string;
  status: string;
  amount: number;
  discount: string;
  type: string;
  paymentproof: string;
  createdAt: Date;
}

export interface Child {
  id: string;
  childFirstName: string;
  childLastName: string;
  childGender: string;
  childDob: string;
  childStudentNum: string;
}

export interface EmployeeLeave {
  id: string;
  leavetype: string;
  leavestart: string;
  leaveend: string;
  leavedays: string;
}

export interface Allowance {
  id: string;
  allowancetype: string;
  allowanceamount: string;
  allowancetime: string;
  allowancerepayment: string;
}

export interface Status {
  id: string;
  status: string;
  statusdate: string;
}

export interface Fees {
  id: string;
  registration: number;
  babyclass: number;
  reception: number;
  grade1: number;
  grade2: number;
  grade3: number;
  grade4: number;
  grade5: number;
  grade6: number;
  grade7: number;
  grade8: number;
  grade9: number;
  grade10: number;
  grade11: number;
  grade12: number;
}

export const getNewFeesObject = (): Fees => {
  return {
    id: "c892344d-4d67-46ed-8de8-c2c1415bd929",
    registration: 0,
    reception: 0,
    babyclass: 0,
    grade1: 0,
    grade2: 0,
    grade3: 0,
    grade4: 0,
    grade5: 0,
    grade6: 0,
    grade7: 0,
    grade8: 0,
    grade9: 0,
    grade10: 0,
    grade11: 0,
    grade12: 0,
  }
}

export enum JobTitle {
  Teacher = "Teacher",
  Admin = "Admin",
  Other = "Other"
};

export enum Title {
  Dr = "Dr",
  Mr = "Mr",
  Mrs = "Mrs",
  Ms = "Ms"
};

export enum Province {
  "Central" = "Central",
  "Copperbelt" = "Copperbelt",
  "Eastern" = "Eastern",
  "Luapula" = "Luapula",
  "Lusaka" = "Lusaka",
  "Muchinga" = "Muchinga",
  "Northern" = "Northern",
  "North Western" = "North Western",
  "Southern" = "Southern",
  "Western" = "Western",
};

export enum Campus {
  "Main Campus" = "Main Campus",
  "Lubombo" = "Lubombo",
};

export enum Grades {
  "Baby Class" = "Baby Class",
  "Reception" = "Reception",
  "Grade 1" = "Grade 1",
  "Grade 2" = "Grade 2",
  "Grade 3" = "Grade 3",
  "Grade 4" = "Grade 4",
  "Grade 5" = "Grade 5",
  "Grade 6" = "Grade 6",
  "Grade 7" = "Grade 7",
  "Grade 8" = "Grade 8",
  "Grade 9" = "Grade 9",
  "Grade 10" = "Grade 10",
  "Grade 11" = "Grade 11",
  "Grade 12" = "Grade 12",
  "Alumni" = "Alumni",
};

export const languageList = [
  'Lunda - Luvale',
  'Luchazi',
  'Kaonde',
  'Toka - Leya',
  'Tonga',
  'Baila',
  '​Bemba',
  'Bisa',
  'Swaka',
  'Lala',
  'Lozi',
  'Aushi',
  'Lamba',
  'Lungu',
  'Namwanga',
  'Mambwe',
  'Nyika',
  'Bisa',
  'Chewa',
  'Kunda',
  'Ngoni',
  'Tumbuka',
  'Nsenga',
  'Nsenga Luzi',
  'Nyanja',
  'Lenje',
  'Soli',
];

  // 'Afrikaans',
  // 'Albanian - shqip',
  // 'Amharic - አማርኛ',
  // 'Arabic - العربية',
  // 'Aragonese - aragonés',
  // 'Armenian - հայերեն',
  // 'Asturian - asturianu',
  // 'Azerbaijani - azərbaycan dili',
  // 'Basque - euskara',
  // 'Belarusian - беларуская',
  // 'Bengali - বাংলা',
  // 'Bosnian - bosanski',
  // 'Breton - brezhoneg',
  // 'Bulgarian - български',
  // 'Catalan - català',
  // 'Central Kurdish - کوردی (دەستنوسی عەرەبی)',
  // 'Chinese - 中文',
  // 'Chinese (Hong Kong) - 中文（香港）',
  // 'Chinese (Simplified) - 中文（简体）',
  // 'Chinese (Traditional) - 中文（繁體）',
  // 'Corsican',
  // 'Croatian - hrvatski',
  // 'Czech - čeština',
  // 'Danish - dansk',
  // 'Dutch - Nederlands',
  // 'English',
  // 'English (Australia)',
  // 'English (Canada)',
  // 'English (India)',
  // 'English (New Zealand)',
  // 'English (South Africa)',
  // 'English (United Kingdom)',
  // 'English (United States)',
  // 'Esperanto - esperanto',
  // 'Estonian - eesti',
  // 'Faroese - føroyskt',
  // 'Filipino',
  // 'Finnish - suomi',
  // 'French - français',
  // 'French (Canada) - français (Canada)',
  // 'French (France) - français (France)',
  // 'French (Switzerland) - français (Suisse)',
  // 'Galician - galego',
  // 'Georgian - ქართული',
  // 'German - Deutsch',
  // 'German (Austria) - Deutsch (Österreich)',
  // 'German (Germany) - Deutsch (Deutschland)',
  // 'German (Liechtenstein) - Deutsch (Liechtenstein)',
  // 'German (Switzerland) - Deutsch (Schweiz)',
  // 'Greek - Ελληνικά',
  // 'Guarani',
  // 'Gujarati - ગુજરાતી',
  // 'Hausa',
  // 'Hawaiian - ʻŌlelo Hawaiʻi',
  // 'Hebrew - עברית',
  // 'Hindi - हिन्दी',
  // 'Hungarian - magyar',
  // 'Icelandic - íslenska',
  // 'Indonesian - Indonesia',
  // 'Interlingua',
  // 'Irish - Gaeilge',
  // 'Italian - italiano',
  // 'Italian (Italy) - italiano (Italia)',
  // 'Italian (Switzerland) - italiano (Svizzera)',
  // 'Japanese - 日本語',
  // 'Kannada - ಕನ್ನಡ',
  // 'Kazakh - қазақ тілі',
  // 'Khmer - ខ្មែរ',
  // 'Korean - 한국어',
  // 'Kurdish - Kurdî',
  // 'Kyrgyz - кыргызча',
  // 'Lao - ລາວ',
  // 'Latin',
  // 'Latvian - latviešu',
  // 'Lingala - lingála',
  // 'Lithuanian - lietuvių',
  // 'Macedonian - македонски',
  // 'Malay - Bahasa Melayu',
  // 'Malayalam - മലയാളം',
  // 'Maltese - Malti',
  // 'Marathi - मराठी',
  // 'Mongolian - монгол',
  // 'Nepali - नेपाली',
  // 'Norwegian - norsk',
  // 'Norwegian Bokmål - norsk bokmål',
  // 'Norwegian Nynorsk - nynorsk',
  // 'Occitan',
  // 'Oriya - ଓଡ଼ିଆ',
  // 'Oromo - Oromoo',
  // 'Pashto - پښتو',
  // 'Persian - فارسی',
  // 'Polish - polski',
  // 'Portuguese - português',
  // 'Portuguese (Brazil) - português (Brasil)',
  // 'Portuguese (Portugal) - português (Portugal)',
  // 'Punjabi - ਪੰਜਾਬੀ',
  // 'Quechua',
  // 'Romanian - română',
  // 'Romanian (Moldova) - română (Moldova)',
  // 'Romansh - rumantsch',
  // 'Russian - русский',
  // 'Scottish Gaelic',
  // 'Serbian - српски',
  // 'Serbo-Croatian - Srpskohrvatski',
  // 'Shona - chiShona',
  // 'Sindhi',
  // 'Sinhala - සිංහල',
  // 'Slovak - slovenčina',
  // 'Slovenian - slovenščina',
  // 'Somali - Soomaali',
  // 'Southern Sotho',
  // 'Spanish - español',
  // 'Spanish (Argentina) - español (Argentina)',
  // 'Spanish (Latin America) - español (Latinoamérica)',
  // 'Spanish (Mexico) - español (México)',
  // 'Spanish (Spain) - español (España)',
  // 'Spanish (United States) - español (Estados Unidos)',
  // 'Sundanese',
  // 'Swahili - Kiswahili',
  // 'Swedish - svenska',
  // 'Tajik - тоҷикӣ',
  // 'Tamil - தமிழ்',
  // 'Tatar',
  // 'Telugu - తెలుగు',
  // 'Thai - ไทย',
  // 'Tigrinya - ትግርኛ',
  // 'Tongan - lea fakatonga',
  // 'Turkish - Türkçe',
  // 'Turkmen',
  // 'Twi',
  // 'Ukrainian - українська',
  // 'Urdu - اردو',
  // 'Uyghur',
  // 'Uzbek - o‘zbek',
  // 'Vietnamese - Tiếng Việt',
  // 'Walloon - wa',
  // 'Welsh - Cymraeg',
  // 'Western Frisian',
  // 'Xhosa',
  // 'Yiddish',
  // 'Yoruba - Èdè Yorùbá',
  // 'Zulu - isiZulu'