// material-ui
import { Card, CardContent, Grid } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import MainCard from "../../../ui-component/cards/MainCard";
import { styled, useTheme } from "@mui/material/styles";

// ==============================|| SKELETON - POPULAR CARD ||============================== //
const CardWrapper = styled(MainCard)(({ theme }) => ({
  color: "#fff",
  overflow: "hidden",
  position: "relative",
  backgroundColor: "#5E35B1",
  borderRadius: "10px",
  marginLeft: "10px",
  marginTop: "8px",
  "&>div": {
    position: "relative",
    zIndex: 5,
  },
  "&:after": {
    content: '""',
    position: "absolute",
    width: 210,
    height: 210,
    backgroundColor: "#D3D3D3",
    borderRadius: "50%",
    top: -85,
    right: -95,
    [theme.breakpoints.down("sm")]: {
      top: -105,
      right: -140,
    },
  },
  "&:before": {
    content: '""',
    position: "absolute",
    width: 210,
    height: 210,
    backgroundColor: "#D3D3D3",
    borderRadius: "50%",
    top: -125,
    right: -15,
    opacity: 0.5,
    [theme.breakpoints.down("sm")]: {
      top: -155,
      right: -70,
    },
  },
}));

const PopularCard = () => (
  <CardWrapper border={false} content={false}>
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              spacing={2}
            >
              <Grid item xs zeroMinWidth>
                <Skeleton animation="wave" variant="rectangular" height={20} />
              </Grid>
              <Grid item>
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  height={20}
                  width={20}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Skeleton animation="wave" variant="rectangular" height={150} />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Grid
                  container
                  alignItems="center"
                  spacing={2}
                  justifyContent="space-between"
                >
                  <Grid item xs={6}>
                    <Skeleton
                      animation="wave"
                      variant="rectangular"
                      height={20}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Grid
                      container
                      alignItems="center"
                      spacing={2}
                      justifyContent="space-between"
                    >
                      <Grid item xs zeroMinWidth>
                        <Skeleton
                          animation="wave"
                          variant="rectangular"
                          height={20}
                        />
                      </Grid>
                      <Grid item>
                        <Skeleton
                          animation="wave"
                          variant="rectangular"
                          height={16}
                          width={16}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <CardContent
        sx={{ p: 1.25, display: "flex", pt: 0, justifyContent: "center" }}
      >
        <Skeleton
          animation="wave"
          variant="rectangular"
          height={25}
          width={75}
        />
      </CardContent>
    </Card>
  </CardWrapper>
);

export default PopularCard;
