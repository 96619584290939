import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { InvoiceItem } from "../invoice-data";

const borderColor = "#90e5fc";
const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    borderBottomColor: "#bff0fd",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    fontSize: 12,
    fontStyle: "bold",
  },
  description: {
    width: "65%",
    textAlign: "right",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingRight: 8,
  },
  total: {
    width: "35%",
    textAlign: "right",
    paddingRight: 8,
  },
});

const ReceiptTableFooter = ({ payments }: any) => {
  console.log(payments);
  const total = payments?.payments
    .map((payment: any) => payment?.amount)
    .reduce(
      (accumulator: number, currentValue: number) =>
        accumulator + Number(currentValue),
      0
    );

  return (
    <>
      <View style={styles.row}>
        <Text style={styles.description}>Total Amount Owing</Text>
        <Text style={styles.total}>
        K{Number((payments?.items[0].rate - payments?.items[0].rate * ((parseInt(payments?.items[0].discount) || 0) / 100) - total).toFixed(2)) || "0"}
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.description}>TOTAL</Text>
        <Text style={styles.total}>
          K{Number.parseFloat(total?.toString()).toFixed(2)}
        </Text>
      </View>
    </>
  );
};

export default ReceiptTableFooter;
