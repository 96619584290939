import React from "react";
import { cloneElement, useEffect, useState } from "react";

import LogoutIcon from "@mui/icons-material/Logout";
import { signOut } from "../authentication/authenticationfunctions";

import KozoLogo from "../../assets/img/images/Kozo_Logo.png";

import Button from "@mui/material/Button";
import { Avatar, Chip } from "@mui/material";
import { Auth } from "aws-amplify";
import Profile from "./profile";
import PermissionsGate from "../../util/perminssion-gate";
import { SCOPES } from "../../util/permission-map";

let goHome = () => {
  if (window.location.pathname != "/confirmpassword") {
    window.location.href = "/dashboard";
  }
  if (window.location.pathname === "/parent-access") {
    window.location.href = "/parent-signin";
  }
};

function Navbar(userName: any) {
  const [username, setusername] = useState("");
  const [isSignin, setIsSignin] = useState(false);
  const [isParentAccess, setIsParentAccess] = useState(false);

  useEffect(() => {
    if (
      window.location.pathname === "/parent-signin" ||
      window.location.pathname === "/signin" ||
      window.location.pathname === "/resetpassword"
    ) {
      setIsSignin(true);
      setIsParentAccess(false)
    } else {
      if (window.location.pathname === "/parent-access") {
        setIsParentAccess(true)
      }
      setIsSignin(false);
    }
    getName().then((name) => {
      setusername(name);
    });

  });

  async function getName() {
    let result = "";
    try {
      const user = await Auth.currentAuthenticatedUser();
      if (user.pool.userPoolId === "ca-central-1_TxjoIawIu") {
        result = user.attributes.email;
      } else {

        result = user.username;
      }
    } catch (err) { }
    return result;
  }

  return (
    <>
      {isSignin ? (
        <></>
      ) : (
        <>
          <React.Fragment>
            <nav className="navbarstatic navbar navbar-dark navbar-expand-sm navbar navbar-expand-md">
              <PermissionsGate scopes={[SCOPES.canoppp]}>
                <button onClick={goHome}>
                  <div className="ms-4 navbar-brand">
                    <img src={KozoLogo} height={23}></img>
                    <span className="navbartitleschool"> School</span>
                    <span className="navbartitle"> Management System</span>
                  </div>
                </button>

                <div
                  className="navbar-collapse collapse justify-content-between align-items-center w-100"
                  id="collapsingNavbar2"
                >
                  <ul className="navbar-nav mx-auto text-md-center text-left">
                    <li className="nav-item"></li>
                    <li className="nav-item"></li>
                    <li className="nav-item my-auto"></li>
                    <li className="nav-item"></li>
                    <li className="nav-item"></li>
                  </ul>
                  <ul className="nav navbar-nav flex-row justify-content-md-center justify-content-start flex-nowrap">
                    <li className="nav-item">
                      {isParentAccess ? (<><div className="ms-4 navbar-brand">
                        <span className="navbartitle">Ebenezer Schools lll</span>
                      </div></>) : (<></>)}
                      {(username == "" || isParentAccess) ? (
                        <></>
                      ) : (
                        <>
                          <div className="ms-4 navbar-brand">
                            <span className="navbartitle">Ebenezer Schools </span>
                            <span className="navbartitleschool"> | </span>
                            <Profile />
                          </div>
                        </>
                      )}
                    </li>
                    <li className="nav-item"></li>
                  </ul>
                </div>
              </PermissionsGate>
            </nav>
          </React.Fragment>
        </>
      )}
    </>
  );
}

export default Navbar;
