import {
  Button,
  Divider,
  Grid,
  IconButton,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  InputAdornment,
  Typography,
} from "@mui/material";
import React from "react";
import { Status, EmployeeLeave } from "../../util/data-models";
import DeleteIcon from "@mui/icons-material/Delete";
import AddReactionIcon from "@mui/icons-material/AddReaction";
import { v4 as uuidv4 } from "uuid";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";

class EditStatus extends React.Component<
  {
    onStatusUpdate: (status: Status[]) => void;
    status: Status[];
  },
  { status: Status[]; newItemStatus: string; newItemDate: string }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      status: this.props.status,
      newItemStatus: "",
      newItemDate: "",
    };
  }

  newStatus() {
    return (
      <Grid>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="stretch"
          spacing={2}
        >
          <Grid container item xs={11} spacing={2}>
            <Grid item xs={3}>
              <FormControl
                size="small"
                style={{
                  marginTop: 5,
                  display: "flex",
                  minWidth: 127,
                  flex: 1,
                  marginRight: 9,
                }}
              >
                <InputLabel id="jobtitle-label">New Status</InputLabel>
                <Select
                  labelId="status-label"
                  id="status"
                  name="newItemStatus"
                  defaultValue={this.state.newItemStatus || ""}
                  onChange={this.handleChange.bind(this)}
                  label="Status"
                >
                  <MenuItem value={"Active"}>Active</MenuItem>
                  <MenuItem value={"Probation"}>Probation</MenuItem>
                  <MenuItem value={"Terminated (Fired)"}>
                    {"Terminated (Fired)"}
                  </MenuItem>
                  <MenuItem value={"Terminated (Resigned)"}>
                    {"Terminated (Resigned)"}
                  </MenuItem>
                  <MenuItem value={"Retired"}>Retired</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={3}>
              <TextField
                size="small"
                style={{
                  marginTop: 5,
                  display: "flex",
                  minWidth: 127,
                  flex: 1,
                  marginRight: 9,
                }}
                label="Date"
                fullWidth
                type="date"
                InputLabelProps={{ shrink: true }}
                name={"newItemDate"}
                value={this.state.newItemDate || ""}
                onChange={this.handleChange.bind(this)}
              />
            </Grid>
            <Grid item xs={4}>
              <Button
                startIcon={<AddIcon />}
                style={{ marginBottom: 12, marginTop: 5 }}
                variant="contained"
                color="success"
                size="small"
                onClick={this.addClick.bind(this)}
              >
                Add Status
              </Button>
            </Grid>
          </Grid>
          <Divider style={{ marginTop: 5, marginBottom: 15 }}></Divider>
        </Grid>
      </Grid>
    );
  }

  createUI() {
    this.props.onStatusUpdate(this.state.status);

    return this.state?.status
      ?.slice(0)
      .reverse()
      .map((stat: Status, i) => (
        <React.Fragment key={stat.id}>
          <Grid>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="stretch"
              spacing={2}
            >
              <Grid container item xs={11} spacing={2}>
                <Grid item xs={3}>
                  <Typography
                    style={{
                      paddingLeft: 0,
                      fontSize: 9,
                      color: "#878787",
                    }}
                  >
                    Status
                  </Typography>
                  <Typography style={{ paddingLeft: 0, fontSize: 13 }}>
                    {stat.status}
                  </Typography>
                </Grid>

                <Grid item xs={3}>
                  <Typography
                    style={{
                      paddingLeft: 0,
                      fontSize: 9,
                      color: "#878787",
                    }}
                  >
                    Date
                  </Typography>
                  <Typography style={{ paddingLeft: 0, fontSize: 13 }}>
                    {stat.statusdate}
                  </Typography>
                </Grid>
                <Grid item xs={4}></Grid>
              </Grid>
              <Grid
                container
                item
                direction="column"
                xs={1}
                justifyContent="center"
              >
                <IconButton
                  onClick={this.removeClick.bind(
                    this,
                    this.state?.status?.length - 1 - i
                  )}
                  style={{ display: "flex" }}
                  aria-label="delete"
                  color="warning"
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Divider style={{ marginTop: 5, marginBottom: 5 }}></Divider>
          </Grid>
        </React.Fragment>
      ));
  }

  handleChange(event: React.ChangeEvent) {
    let status = [...this.state.status];
    const target = event.target as HTMLInputElement;

    this.setState({
      [event.target.name]: target.value,
    });

    this.props.onStatusUpdate(status);
  }

  addClick() {
    this.setState((prevState) => ({
      status: [
        ...prevState.status,
        {
          id: uuidv4(),
          status: this.state.newItemStatus,
          statusdate: this.state.newItemDate,
        },
      ],
    }));

    this.props.onStatusUpdate(this.state.status);
  }

  removeClick(i: number) {
    let status = [...this.state.status];
    status.splice(i, 1);
    this.setState({ status });
    // this.props.onStatusUpdate(status);
  }

  render() {
    return (
      <>
        {this.newStatus()}
        {this.createUI()}
      </>
    );
  }
}

export default EditStatus;
