// material-ui
import {
  Card,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Skeleton,
} from "@mui/material";
import MainCard from "../../../ui-component/cards/MainCard";
import { styled, useTheme } from "@mui/material/styles";

// ==============================|| SKELETON - TOTAL INCOME DARK/LIGHT CARD ||============================== //
const CardWrapper = styled(MainCard)(({ theme }) => ({
  backgroundColor: "#1E88E5",
  color: theme.palette.primary.light,
  overflow: "hidden",
  position: "relative",
  marginLeft: "10px",
  marginTop: "10px",
  borderRadius: "10px",
  "&:after": {
    content: '""',
    position: "absolute",
    width: 210,
    height: 210,
    backgroundColor: "#D3D3D3",
    borderRadius: "50%",
    top: -30,
    right: -180,
  },
  "&:before": {
    content: '""',
    position: "absolute",
    width: 210,
    height: 210,
    backgroundColor: "#D3D3D3",
    borderRadius: "50%",
    top: -160,
    right: -130,
  },
}));

const TotalIncomeCard = () => (
  <CardWrapper border={false} content={false}>
    <Card sx={{ p: 2 }}>
      <List sx={{ py: 0 }}>
        <ListItem alignItems="center" disableGutters sx={{ py: 0 }}>
          <ListItemAvatar>
            <Skeleton
              animation="wave"
              variant="rectangular"
              width={44}
              height={77}
            />
          </ListItemAvatar>
          <ListItemText
            sx={{ py: 0 }}
            primary={
              <Skeleton animation="wave" variant="rectangular" height={20} />
            }
            secondary={<Skeleton animation="wave" variant="text" />}
          />
        </ListItem>
      </List>
    </Card>
  </CardWrapper>
);

export default TotalIncomeCard;
