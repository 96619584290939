import React from "react";
import {
  Page,
  Document,
  Image,
  StyleSheet,
  View,
  Text,
} from "@react-pdf/renderer";
import logo from "../../../assets/img/images/ebenezerlogo.png";
import InvoiceTitle from "./invoice-title";
import InvoiceNo from "./invoice-number";
import BillTo from "./bill-to";
import { InvoiceData } from "./invoice-data";
import InvoiceItemsTable from "./invoice-items-table";
import { Employee } from "../../contactlist/newcontactlist";

const numWords = require('num-words')

const styles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica",
    fontSize: 11,
    paddingTop: 30,
    paddingLeft: 60,
    paddingRight: 60,
    lineHeight: 1.5,
  },
  logo: {
    width: 74,
    height: 66,
    marginLeft: "auto",
    marginRight: "auto",
  },
});

const styles_1_1 = StyleSheet.create({
  titleContainer: {
    flexDirection: "row",
    marginTop: 24,
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
  },
  reportTitle: {
    fontSize: 11,
    textAlign: "center",
  },
});

const styles_2_1 = StyleSheet.create({
  titleContainer: {
    marginTop: 24,
    textAlign: "left",
  },
  newLine: {
    flexDirection: "row",
    textAlign: "left",
  },
  reportTitle: {
    fontSize: 14,
    textAlign: "left",
  },
  reportPage: {
    fontSize: 14,
    textAlign: "right",
  },
});

const styles_3_1 = StyleSheet.create({
    titleContainer: {
      marginTop: 24,
      textAlign: "left",
    },
    newLine: {
      flexDirection: "row",
      textAlign: "left",
    },
    reportTitle: {
      fontSize: 14,
      textAlign: "left",
    },
    reportPage: {
      fontSize: 14,
      textAlign: "right",
    },
    reporttext: {
        fontSize: 11,
        textAlign: "left",
      },
  });

  function contractEndDate(contract: Employee) {
    var datestring = ""
    if(contract.contractlength){
      let hireDate = new Date(contract.datehire)
      let contractEndDate= new Date(hireDate.setMonth(hireDate.getMonth() + parseInt(contract.contractlength)));
      datestring = contractEndDate.getFullYear() + "-" + ("0"+(contractEndDate.getMonth()+1)).slice(-2) + "-" + ("0" + contractEndDate.getDate()).slice(-2);
    }
    return datestring;
  }

const Contract = ({ contract }: { contract: Employee }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles_1_1.titleContainer}>
        <Text style={styles_1_1.reportTitle}>
          {
            '\n Given by: \n\n Name: Ebenezer Schools \n Mazabuka \n (Herein after referred to as "the employer")'
          }
        </Text>
      </View>
      <View style={styles_1_1.titleContainer}>
        <Text style={styles_1_1.reportTitle}>
          {'to: \n\n Name: '} 
          {(contract.firstname || "") + ' ' + (contract.lastname || "")} 
          {'\n (Herein after referred to as "the employee")' +
            "\n\n\n Identification Number and Type (Passport or NRC #.): " } 
            {(contract.nrc || "")} 
            {'\n\n Social Security Number: '}
            {(contract.pensionno || "")} 
        </Text>
      </View>
    </Page>
    <Page size="A4" style={styles.page}>
      <View style={styles_2_1.titleContainer}>
        <Text style={styles_2_1.reportTitle}>
          {"Contents" +
            "\n1.	POSITION.................................................................................................3" +
            "\n2.	PROBATION PERIOD...............................................................................3" +
            "\n3.	HOURS OF WORK...................................................................................3" +
            "\n4.	SALARY AND ALLOWANCES..................................................................3" +
            "\n5.	PERFORMANCE APPRAISAL.................................................................3" +
            "\n6.	ANNUAL LEAVE.......................................................................................4" +
            "\n7.	SICK LEAVE.............................................................................................4" +
            "\n8.	MATERNITY LEAVE.................................................................................4" +
            "\n9.	PATERNITY LEAVE..................................................................................4" +
            "\n10.	COMPASSIONATE LEAVE.....................................................................4" +
            "\n11.	FAMILY RESPONSIBILITY LEAVE.........................................................4" +
            "\n12.	FUNERAL ASSISTANCE........................................................................5" +
            "\n13.	PROTECTIVE CLOTHING......................................................................5" +
            "\n14.	SEPARATION PACKAGE........................................................................5" +
            "\n15.	CONFIDENTIALITY AGREEMENT.........................................................5" +
            "\n16.	GENERAL...............................................................................................5" +
            "\n17.	TERMINATION........................................................................................6" +
            "\n18.	ENTIRE AGREEMENT............................................................................6"}
        </Text>
      </View>
    </Page>
    <Page size="A4" style={styles.page}>
      <View style={styles_3_1.titleContainer}>
      <Text style={styles_3_1.reportTitle}>
          {
            '1. POSITION'
          }
        </Text>
        <Text style={styles_3_1.reporttext}>
          {
            'You will work as a ' }
            {(contract.title || "")} 
            {' and you will report to the '}
            {((contract.reportsto) || "")} 
            {'.  A description of your duties is attached at Appendix 1. This is a fixed-term position for ' }
            {(contract.contractlength || "")} 
            {' month(s) commencing on '}
            {(contract.datehire || "")} 
            {' (“ the Effective Date”) to the '}
            {(contractEndDate(contract)) || ""}
            {
            '. Your job duties, title, compensation and benefits, as well as the Employer’s personnel policies and procedures, ' +
            'may change from time to time at the discretion of the Employer, subject to applicable law.Detailed below are the ' +
            'terms and conditions of your employment. Please sign this contract as acceptance of these conditions and fill in ' +
            'Appendix 2 which will be retained in your personal file.'
          }
        </Text>
      </View>
      <View style={styles_3_1.titleContainer}>
      <Text style={styles_3_1.reportTitle}>
          {
            '2. PROBATION PERIOD'
          }
        </Text>
        <Text style={styles_3_1.reporttext}>
          {
            'The first three (3) months of this contract shall be a probationary period. Upon satisfactory completion of this period, you will be confirmed in the position/appointment. The Employer may choose to confirm the employee earlier than three months or extend the probation period by up to three months where performance is not to expectation.'
          }
        </Text>
      </View>
      <View style={styles_3_1.titleContainer}>
      <Text style={styles_3_1.reportTitle}>
          {
            '3. HOURS OF WORK'
          }
        </Text>
        <Text style={styles_3_1.reporttext}>
          {
            'Your working hours at Ebenezer Schools are forty (40) hours per week with one meal period of 60 minutes in length each workday. Saturday, Sunday and public holidays are rest days. '
          }
        </Text>
      </View>
      <View style={styles_3_1.titleContainer}>
      <Text style={styles_3_1.reportTitle}>
          {
            '4. SALARY AND ALLOWANCES'
          }
        </Text>
        <Text style={styles_3_1.reporttext}>
          {
            'Base Salary - You will be paid a gross basic pay of K'}
            {((parseInt(contract.salary) * 12) || "")} 
            {' per annum, payable on the Employer’s regular payroll dates, which are monthly, in arrears.  This salary will be paid in twelve (12) equal installments and is subject to deduction for income tax and any other statutory deductions in accordance with this agreement.'
          }
        </Text>
      </View>
      <View style={styles_3_1.titleContainer}>
        <Text style={styles_3_1.reporttext}>
          {
            'Allowances - You will also receive a monthly housing, lunch and transport allowance; all of which are subject to deduction for income tax and any other statutory deductions.  Allowance eligibility brackets are stipulated in the Employee Handbook.'
          }
        </Text>
      </View>
      <View style={styles_3_1.titleContainer}>
        <Text style={styles_3_1.reporttext}>
          {
            'You will be eligible to free tuition for one biological child attending the school each year of your employment.  Eligibility criteria is detailed in the Employee Handbook.'
          }
        </Text>
      </View>
      <View style={styles_3_1.titleContainer}>
        <Text style={styles_3_1.reporttext}>
          {
            'Your total monthly earnings will be subject to deduction for Pay As You Earn (PAYE), National Pension Scheme Authority (NAPSA), National Health Insurance Management Authority (NHIMA) and any other applicable statutory deduction in accordance with this agreement and the Laws of Zambia. In addition, to the extent permitted by applicable law, the Employer may deduct from your total compensation or other payments due to you any money that you owe to the Employer.'
          }
        </Text>
      </View>
      <View style={styles_3_1.titleContainer}>
        <Text style={styles_3_1.reporttext}>
          {
            'A record of payment shall be kept by both parties (employer and employee) indicating the wage, allowances and deductions made for that period.'
          }
        </Text>
      </View>
      <View style={styles_3_1.titleContainer}>
      <Text style={styles_3_1.reportTitle}>
          {
            '5. PERFORMANCE APPRAISAL'
          }
        </Text>
        <Text style={styles_3_1.reporttext}>
          {
            'Your performance will be appraised at regular intervals in line with the job description in Appendix 1 and other performance criteria agreed to at the beginning of every performance period. This may form the basis for any salary adjustments that may be applicable and for renewal of your contract of employment.'
          }
        </Text>
        </View>
        <View style={styles_3_1.titleContainer}>
        <Text style={styles_3_1.reportTitle}>
          {
            '6. ANNUAL LEAVE'
          }
        </Text>
        <Text style={styles_3_1.reporttext}>
          {
            'You will be entitled to two (2) days paid leave per month, that is, 24 days of paid leave per year of continuous service and shall take such at times convenient to the employer based on the agreed annual leave plan.'
          }
        </Text>
        </View>
        <View style={styles_3_1.titleContainer}>
        <Text style={styles_3_1.reportTitle}>
          {
            '7. SICK LEAVE'
          }
        </Text>
        <Text style={styles_3_1.reporttext}>
          {
            'Upon production of a certified medical report, you will be entitled to a maximum of three months paid sick leave and thereafter, half pay for the next three months after which this entitlement shall cease. This condition may be waived should a medical board recommend a discharge.'
          }
        </Text>
        </View>
        <View style={styles_3_1.titleContainer}>
        <Text style={styles_3_1.reportTitle}>
          {
            '8. MATERNITY LEAVE'
          }
        </Text>
        <Text style={styles_3_1.reporttext}>
          {
            'Female employees shall be entitled to fourteen (14) weeks paid maternity leave after two (2) years of continuous employment or two years after the last maternity leave.'
          }
        </Text>
      </View>
      <View style={styles_3_1.titleContainer}>
        <Text style={styles_3_1.reporttext}>
          {
            'Maternity leave taken before two years of service and before two years after the last maternity leave will be unpaid.'
          }
        </Text>
      </View>
      <View style={styles_3_1.titleContainer}>
        <Text style={styles_3_1.reportTitle}>
          {
            '9. PATERNITY LEAVE'
          }
        </Text>
        <Text style={styles_3_1.reporttext}>
          {
            'After 12 months of continuous employment, male employees shall be entitled to five days of paternity leave to be taken within seven days of the birth of your child subject to provision of a birth record indicating that you are the father of the child.'
          }
        </Text>
        </View>
        <View style={styles_3_1.titleContainer}>
        <Text style={styles_3_1.reportTitle}>
          {
            '10. COMPASSIONATE LEAVE'
          }
        </Text>
        <Text style={styles_3_1.reporttext}>
          {
            'You shall be entitled to compassionate leave with full pay for a period of at least twelve days in a calendar year should you—' +
            '\n\n •	Lose a spouse, parent, child or dependent; or' +
            '\n\n •	On any justifiable compassionate ground'
          }
        </Text>
        </View>
        <View style={styles_3_1.titleContainer}>
        <Text style={styles_3_1.reportTitle}>
          {
            '11. FAMILY RESPONSIBILITY LEAVE'
          }
        </Text>
        <Text style={styles_3_1.reporttext}>
          {
            'You shall be entitled to family responsibility leave with full pay for a period of seven calendar days subject to having completed six months of employment to enable you nurse your sick spouse, child or dependent subject to providing a medical certificate as evidence of illness.'
          }
        </Text>
        </View>
        <View style={styles_3_1.titleContainer}>
        <Text style={styles_3_1.reporttext}>
          {
            'Additionally, you will be entitled to three (3) paid leave days per year to cover responsibilities related to the care, health or education for that employee’s child, spouse or dependent.'
          }
        </Text>
        </View>
        <View style={styles_3_1.titleContainer}>
        <Text style={styles_3_1.reportTitle}>
          {
            '12. FUNERAL ASSISTANCE'
          }
        </Text>
        <Text style={styles_3_1.reporttext}>
          {
            'In the event of the death of an employee, spouse or registered biological or legally adopted child the employer shall provide a funeral grant in accordance with its conditions of service.'
          }
        </Text>
        </View>
        <View style={styles_3_1.titleContainer}>
        <Text style={styles_3_1.reportTitle}>
          {
            '13. PROTECTIVE CLOTHING'
          }
        </Text>
        <Text style={styles_3_1.reporttext}>
          {
            '\n•	The necessary protective clothing shall be provided to the employee where appropriate.' +
            '\n\n •	If deemed necessary by the employer, uniforms shall be supplied to the employee free of charge by the employer and shall remain the property of the employer.'
          }
        </Text>
        </View>
        <View style={styles_3_1.titleContainer}>
        <Text style={styles_3_1.reportTitle}>
          {
            '14. SEPARATION PACKAGE '
          }
        </Text>
        <Text style={styles_3_1.reporttext}>
          {
            'Severance pay shall be a gratuity at the rate of '}
            {numWords(contract.gratuity)}
            {' ('}
            {(contract.gratuity || "")}
            {') percent of the employee’s basic pay at the end of each year served.  Gratuity payments will be made at the end of the contract term.'
          }
        </Text>
        </View>
        <View style={styles_3_1.titleContainer}>
        <Text style={styles_3_1.reportTitle}>
          {
            '15. CONFIDENTIALITY AGREEMENT '
          }
        </Text>
        <Text style={styles_3_1.reporttext}>
          {
            'At any time during your employment or thereafter, you shall not divulge any of the affairs of the business or transactions of the Employer without written consent, nor shall you attempt to use any information you may acquire in the course of your employment in any manner which may injure, or cause loss, or be perceived to injure the Employer.'
          }
        </Text>
        </View>
        <View style={styles_3_1.titleContainer}>
        <Text style={styles_3_1.reportTitle}>
          {
            '16. GENERAL '
          }
        </Text>
        <Text style={styles_3_1.reporttext}>
        {
            '\n • Any changes to the written particulars will only be valid if agreed to by both parties i.e., employer/employee).' +
            '\n\n •	This contract shall not apply to workers who are subject to a collective bargaining agreement.' +
            '\n\n •	It shall be the obligation of the employer to register an employee with the relevant  statutory body and remit statutory deductions to the relevant institutions.' +
            '\n\n •	The employee is obligated to periodically check for their statutory contribution balances with relevant institutions.' +
            '\n\n •	Where an employer is registered for statutory contribution, the employer is obligated to contribute as per legal requirement.' +
            '\n\n •	As an employee, you will be expected to adhere to the Employer’s standards of professionalism, loyalty, integrity, honesty, reliability and respect for all.  You will also be expected to comply with the Employer’s policies and procedures.  While you render services to the Employer, you agree that you will not engage in any other employment, consulting or other business activity without the written consent of the Employer.  In addition, while you render services to the Employer, you will not assist any person or entity in competing with the Employer, in preparing to compete with the Employer or in hiring any employees or consultants of the Employer.'
          }
        </Text>
        </View>
        <View style={styles_3_1.titleContainer}>
        <Text style={styles_3_1.reportTitle}>
          {
            '17. TERMINATION '
          }
        </Text>
        <Text style={styles_3_1.reporttext}>
          {
            'Either party can terminate this agreement orally or in writing with one (1) weeks’ notice during the probation period and one (1) month notice after confirmation or payment in lieu of notice.'
          }
        </Text>
        </View>
        <View style={styles_3_1.titleContainer}>
        <Text style={styles_3_1.reporttext}>
          {
            'The Employer may terminate your employment at any time upon giving reasons by giving for such termination in accordance with the Laws of Zambia. All benefits will cease upon termination of employment, subject to applicable law.'
          }
        </Text>
        </View>
        <View style={styles_3_1.titleContainer}>
        <Text style={styles_3_1.reporttext}>
          {
            'This Agreement shall unless earlier terminated as hereinbefore provided automatically terminate following the expiration of the contract term stipulated herein. You are required to give a minimum of thirty days [30 days’] written prior notice if you choose to resign early. The Employer may terminate your employment prior to the expiration of the fixed term by giving reasons thereof.'
          }
        </Text>
        </View>
        <View style={styles_3_1.titleContainer}>
        <Text style={styles_3_1.reporttext}>
          {
            'The Employer may terminate your employment without notice if the Employer has just cause under applicable law; the Employer may also suspend you pending the outcome of a disciplinary investigation and policy.  All benefits will cease upon termination of employment, subject to the Laws of Zambia. This employment contract is agreed between the parties as signed below and made in two originals.'
          }
        </Text>
        </View>
        <View style={styles_3_1.titleContainer}>
        <Text style={styles_3_1.reportTitle}>
          {
            '18. ENTIRE AGREEMENT'
          }
        </Text>
        <Text style={styles_3_1.reporttext}>
          {
            'This letter supersedes and replaces any and all prior understandings or agreements, whether oral, written or implied, between you and the Employer (including its parent companies and affiliates) regarding the matters described in this letter.'
          }
        </Text>
        </View>
    </Page>
    <Page size="A4" style={styles.page}>
      <View style={styles_1_1.titleContainer}>
        <Text style={styles_1_1.reportTitle}>
          {
            'If you wish to accept the terms of this Agreement, please sign and date both the enclosed duplicate original of this contract and return them to the Head Teacher.'
          }
        </Text>
      </View>
      <View style={styles_1_1.titleContainer}>
        <Text style={styles_1_1.reportTitle}>
          {'In ................................ (Place)               On              (Date) ...................................' +
          '\n\nSignature of the Employer                                    Signature of the Employee' +
          '\n\n.........................................                                .........................................' +
          '\n\nName of Witness for Employer                                 Name of Witness for Employee' +
          '\n\n.........................................                                .........................................' +
          '\n\nContact number:                                      Contact number:' +
          '\n\n.........................................                                .........................................' +
          '\n\nEmployer Witness Signature                                   Employee Witness Signature' +
          '\n\n.........................................                                .........................................'}
        </Text>
      </View>
    </Page>
  </Document>
);

export default Contract;
