export default function MainDetails({ name, address, phone }) {
  return (
    <>
      <section  className="flex flex-col items-end justify-end header-right">
        <h2  className="font-bold text-3xl uppercase mb-1">{name}</h2>
        <p>{address}</p>
        <p>{phone}</p>
      </section>
    </>
  )
}
