
import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import { ROLES, SCOPES, ENHANCEMENTS, PERMISSIONS } from './permission-map';

const hasPermission = ({ permissions, scopes }) => {
  const scopesMap = {};
  scopes?.forEach((scope) => {
    scopesMap[scope] = true;
  });

  return permissions?.some((permission) => scopesMap[permission]);
};

export default function PermissionsGate({ children, scopes = [], from }) {
  const [permissions, setPermissions] = useState([]);
// console.log("FROM", from)
  useEffect(() => {
    getRole().then(({ baseRole, enhancements }) => {
      const basePermissions = PERMISSIONS[baseRole] || [];
      const enhancementPermissions = enhancements.length > 0 ? enhancements : [];
      setPermissions([...new Set([...basePermissions, ...enhancementPermissions])]);
    });
  }, []);


  const permissionGranted = hasPermission({ permissions, scopes });

  if (!permissionGranted) return <></>;

  return <>{children}</>;
}

async function getRole() {
  let baseRole = ROLES.NoAuth;
  let enhancements = [];

  try {
    const user = await Auth.currentAuthenticatedUser();
    const groups = user.signInUserSession.idToken.payload["cognito:groups"] || [];
    // Determine base role
    if (groups.includes("Admin")) {
      baseRole = ROLES.admin;
    } else if (groups.includes("SchoolAdmin")) {
      baseRole = ROLES.schoolAdmin;
    } // Continue for other base roles

    // Check for enhancements
    enhancements = groups
      .filter(group => Object.keys(ENHANCEMENTS).includes(group))
      .map(group => ENHANCEMENTS[group])
      .flat();

  } catch (err) {
    console.error(err);
    // Handle error or set defaults
  }

  return { baseRole, enhancements };
}
