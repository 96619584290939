import {Text, View, StyleSheet } from '@react-pdf/renderer';
import React from 'react';
import { Fragment } from 'react';
import { InvoiceData } from './invoice-data';

const styles = StyleSheet.create({
    invoiceNoContainer: {
        flexDirection: 'row',
        marginTop: 20,
        justifyContent: 'flex-end'
    },
    invoiceDateContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    invoiceDate: {
            fontSize: 12,
            fontStyle: 'bold',
    },
    label: {
        width: 60
    },
     ebenezerInfo: {
        fontSize: 10,
        width: 100,
        justifyContent: 'flex-end',
        textAlign: 'right'
    },
    ebenezerInfoContainer: {
        flexDirection: 'row',
        marginTop: 2,
        justifyContent: 'flex-end'
    },
    
  });


  const InvoiceNo = ({invoice}:{invoice: InvoiceData}) => (
        <Fragment>
            <View style={styles.ebenezerInfoContainer}>
                <Text style={styles.ebenezerInfo}>Ebenezer Schools</Text>
            </View >
            <View style={styles.ebenezerInfoContainer}>
                <Text style={styles.ebenezerInfo}>P O Box 670471</Text>
            </View >
            <View style={styles.ebenezerInfoContainer}>
                <Text style={styles.ebenezerInfo}>Mazabuka, Zambia</Text>
            </View >
            <View style={styles.ebenezerInfoContainer}>
                <Text style={styles.ebenezerInfo}>Cell: +0977 826884</Text>
            </View >
            <View style={styles.invoiceNoContainer}>
                <Text style={styles.label}>Invoice No:</Text>
                <Text style={styles.invoiceDate}>{invoice?.invoice_no}</Text>
            </View >
            <View style={styles.invoiceDateContainer}>
                <Text style={styles.label}>Date: </Text>
                <Text >{invoice?.trans_date}</Text>
            </View >
        </Fragment>
  );
  
  export default InvoiceNo