import {
  Button,
  ButtonGroup,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import BadgeTwoToneIcon from "@mui/icons-material/BadgeTwoTone";
import {
  DataGrid,
  GridApi,
  GridCallbackDetails,
  GridColDef,
  GridColumnHeaderParams,
  GridEventListener,
  GridRenderCellParams,
  GridRowParams,
  MuiEvent,
} from "@mui/x-data-grid";
import { NavigateFunction, useLocation, useNavigate } from "react-router";
import { MouseEventHandler, SetStateAction, useEffect, useState } from "react";
import React, { Component } from "react";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import ReceiptIcon from "@mui/icons-material/Receipt";
import AddBoxIcon from "@mui/icons-material/AddBox";
import SettingsIcon from "@mui/icons-material/Settings";

import { Amplify, Auth, Hub } from "aws-amplify";

var username = "";
var password = "";
var newpassword = "";
var confirmNewPassword = "";

// function confirmChangePassword() {
// var username = "";
// var code = "";
// var new_password = "";

// // Collect confirmation code and new password, then
// Auth.forgotPasswordSubmit(username, code, new_password)
//     .then(data => console.log(data))
//     .catch(err => console.log(err));
// }

function updateUsername(e) {
  e.persist();
  username = e.target.value;
}

function updatePassword(e) {
  e.persist();
  password = e.target.value;
}

function updateNewPassword(e) {
  e.persist();
  newpassword = e.target.value;
}

function updateConfirmNewPassword(e) {
  e.persist();
  confirmNewPassword = e.target.value;
}

function ConfirmPassword() {
  const [errormsg, updateError] = useState("");

  const locn = useLocation();
  const navigate = useNavigate();

  const manageFeeClicked = () => {
    let path = `/settings/managefee`;
    navigate(path);
  };

  const newRegistrationClicked = () => {
    let path = `/settings/newinvoice`;
    navigate(path);
  };

  async function changePassword() {
    updateError("");
    if (confirmNewPassword === "") {
      updateError("Confirmation password is empty");
    } else if (confirmNewPassword != newpassword) {
      updateError("Confirmation password and new password do not match");
    } else {
      Auth.signIn(username, password)
        .then((user) => {
          if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
            const { requiredAttributes } = user.challengeParam; // the array of required attributes, e.g ['email', 'phone_number']
            Auth.completeNewPassword(
              user, // the Cognito User Object
              newpassword // the new password
            )
              .then((user) => {
                window.location.href = "/dashboard";
                // at this time the user is logged in if no MFA required
                console.log(user);
              })
              .catch((e) => {
                let msg = e.toString();
                console.log(
                  msg.includes("must satisfy regular expression pattern")
                );
                if (
                  msg
                    .toString()
                    .includes("must satisfy regular expression pattern")
                ) {
                  updateError(
                    "Password does not conform to policy: Password must satisfy regular expression pattern: ^S.*S$"
                  );
                } else if (
                  msg.toString().includes("Password not long enough")
                ) {
                  updateError(
                    "Password does not conform to policy: Password not long enough"
                  );
                } else {
                  updateError(msg.toString());
                }
              });
          } else {
            updateError("Incorrect username or password.");
            // other situations
          }
        })
        .catch((e) => {
          console.log(e);
          updateError("Incorrect username or password.");
        });
    }
  }

  return (
    <React.Fragment>
      <section>
        <div className="signinpage">
          <div className="signinblock">
            <Grid item marginTop={5}>
              <Typography
                style={{
                  color: "rgb(103, 58, 183)",
                }}
                variant="h5"
              >
                Complete new password
              </Typography>
            </Grid>
            <Divider sx={{ my: 1.5 }} />
            <Grid item marginTop={5}>
              <Typography variant="h7">
                Enter your credentials followed by the new password
              </Typography>
            </Grid>
            <Grid item marginTop={5}>
              {/* <Typography variant="h7">
                    Enter your username
                  </Typography> */}
              <Grid item marginTop={3}>
                <Typography
                  style={{
                    color: "#cc0000",
                  }}
                  variant="h7"
                >
                  {errormsg}
                </Typography>
              </Grid>
            </Grid>
            <Grid item marginTop={3}>
              <div className="row input-container">
                <TextField
                  id="outlined-adornment-username-login"
                  type="username"
                  name="username"
                  onChange={updateUsername}
                  label="Username"
                  inputProps={{}}
                  className="signinfields regularTextField"
                ></TextField>
              </div>
            </Grid>
            <Grid item marginTop={2}>
              <div className="row input-container">
                <TextField
                  id="outlined-adornment-password-login"
                  type="password"
                  name="password"
                  onChange={updatePassword}
                  label="Password"
                  inputProps={{}}
                  className="signinfields regularTextField"
                ></TextField>
              </div>
            </Grid>
            <Grid item marginTop={2}>
              <div className="row input-container">
                <TextField
                  id="outlined-adornment-newpassword-login"
                  type="password"
                  name="newpassword"
                  onChange={updateNewPassword}
                  label="New Password"
                  inputProps={{}}
                  className="signinfields regularTextField"
                ></TextField>
              </div>
            </Grid>
            <Grid item marginTop={2}>
              <div className="row input-container">
                <TextField
                  id="outlined-adornment-newpassword-login"
                  type="password"
                  name="confirmnewpassword"
                  onChange={updateConfirmNewPassword}
                  label="Confirm New Password"
                  inputProps={{}}
                  className="signinfields regularTextField"
                ></TextField>
              </div>
            </Grid>
            <Grid item marginTop={4}>
              <Button
                onClick={changePassword}
                size="large"
                type="submit"
                variant="contained"
                style={{
                  marginLeft: -12,
                  backgroundColor: "rgb(103, 58, 183)",
                  width: 340,
                }}
              >
                Change Password
              </Button>
            </Grid>
          </div>
        </div>
        <div>
          <Grid
            item
            container
            className="listbackground"
            direction="column"
            justifyContent="space-between"
            alignItems="stretch"
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid container item xs={6}>
              <SettingsIcon fontSize="large" color="primary" />
              <Typography variant="h4">Settings</Typography>
            </Grid>

            <Grid item xs={6} alignItems="start">
              {ManageFeeButton(manageFeeClicked)}
            </Grid>
            <Grid item xs={6} alignItems="start">
              <TextField
                id="outlined-adornment-username-login"
                type="username"
                name="username"
                onChange={updateUsername}
                label="Username"
                inputProps={{}}
                className="signinfields regularTextField"
              ></TextField>
              <TextField
                id="outlined-adornment-password-login"
                type="password"
                name="password"
                onChange={updatePassword}
                label="Password"
                inputProps={{}}
                className="signinfields regularTextField"
              ></TextField>
              <TextField
                id="outlined-adornment-newpassword-login"
                type="newpassword"
                name="newpassword"
                onChange={updateNewPassword}
                label="New Password"
                inputProps={{}}
                className="signinfields regularTextField"
              ></TextField>
              <Button
                onClick={changePassword}
                size="large"
                type="submit"
                variant="contained"
                style={{
                  marginLeft: -12,
                  backgroundColor: "rgb(103, 58, 183)",
                  width: 340,
                }}
              >
                Change Password
              </Button>
            </Grid>
          </Grid>
        </div>
      </section>
    </React.Fragment>
  );
}

export default ConfirmPassword;

function ManageFeeButton(
  manageFeeClicked: MouseEventHandler<HTMLButtonElement>
) {
  return (
    <Button
      startIcon={<SettingsSuggestIcon />}
      variant="contained"
      color="success"
      size="large"
      onClick={manageFeeClicked}
    >
      Manage School Fees
    </Button>
  );
}

function newRegistrationButton(
  newRegistrationClicked: MouseEventHandler<HTMLButtonElement>
) {
  return (
    <Button
      startIcon={<AddBoxIcon />}
      variant="contained"
      color="success"
      size="large"
      onClick={newRegistrationClicked}
    >
      New Registration
    </Button>
  );
}
